import { createSelector } from '@reduxjs/toolkit';
import { getVendorDocuments } from '../functions/getVendorDocuments';
import { vendorDocumentSort } from '../functions/vendorDocumentSort';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';

export const selectAllDocuments = createSelector(
  getVendorDocuments,
  selectSortSettings(Grid.AllDocuments),
  (vendorDocuments, sortSetting) => {
    return [...vendorDocuments]
      .filter(vendorDoc => !vendorDoc.archived)
      .sort((a, b) => vendorDocumentSort(a, b, sortSetting.columnSort));
  }
);
