/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TwoFactorVerifyOtpPayloadMfa,
  TwoFactorVerifyOtpPayloadMfaFromJSON,
  TwoFactorVerifyOtpPayloadMfaFromJSONTyped,
  TwoFactorVerifyOtpPayloadMfaToJSON,
} from './';

/**
 *
 * @export
 * @interface TwoFactorVerifyOtpPayload
 */
export interface TwoFactorVerifyOtpPayload {
  /**
   *
   * @type {TwoFactorVerifyOtpPayloadMfa}
   * @memberof TwoFactorVerifyOtpPayload
   */
  mfa?: TwoFactorVerifyOtpPayloadMfa;
}

export function TwoFactorVerifyOtpPayloadFromJSON(
  json: any
): TwoFactorVerifyOtpPayload {
  return TwoFactorVerifyOtpPayloadFromJSONTyped(json, false);
}

export function TwoFactorVerifyOtpPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TwoFactorVerifyOtpPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    mfa: !exists(json, 'mfa')
      ? undefined
      : TwoFactorVerifyOtpPayloadMfaFromJSON(json['mfa']),
  };
}

export function TwoFactorVerifyOtpPayloadToJSON(
  value?: TwoFactorVerifyOtpPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    mfa: TwoFactorVerifyOtpPayloadMfaToJSON(value.mfa),
  };
}
