import { useSelector } from 'react-redux';
import { AgreementsMode } from '../../components/Agreements/AgreementsList/AgreementsList';
import { selectAgreements } from '../../selectors/selectAgreements';
import { Grid } from '../../types/grid';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';

export const useAgreementsList = (mode: AgreementsMode) => {
  const placeholders = {
    [AgreementsMode.Draft]: 'Once started, Draft agreements will appear here',
    [AgreementsMode.AwaitingSignature]:
      'Agreements sent for signature will appear here',
    [AgreementsMode.Completed]:
      'Once signed, completed agreements will appear here',
  };

  const currentVendor = useSelector(selectCurrentVendor);

  const headers = {
    [AgreementsMode.Draft]: 'Drafts',
    [AgreementsMode.AwaitingSignature]: `Awaiting ${currentVendor?.name}'s signature`,
    [AgreementsMode.Completed]: 'Completed',
  };

  const grids = {
    [AgreementsMode.Draft]: Grid.DraftAgreements,
    [AgreementsMode.AwaitingSignature]: Grid.AwaitingSignatureAgreements,
    [AgreementsMode.Completed]: Grid.CompletedAgreements,
  };
  const currentGrid = grids[mode];
  const agreements = useSelector(selectAgreements(mode, currentGrid));

  const placeholderText = placeholders[mode];
  const header = headers[mode];
  return { agreements, header, placeholderText, currentGrid };
};
