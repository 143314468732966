/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AgreementCreatePayload,
  AgreementCreatePayloadFromJSON,
  AgreementCreatePayloadToJSON,
  AgreementExtended,
  AgreementExtendedFromJSON,
  AgreementExtendedToJSON,
  AgreementModel,
  AgreementModelFromJSON,
  AgreementModelToJSON,
  AgreementSendPayload,
  AgreementSendPayloadFromJSON,
  AgreementSendPayloadToJSON,
  AgreementSignatoryCreateExternalPayload,
  AgreementSignatoryCreateExternalPayloadFromJSON,
  AgreementSignatoryCreateExternalPayloadToJSON,
  AgreementSignatoryModel,
  AgreementSignatoryModelFromJSON,
  AgreementSignatoryModelToJSON,
  AgreementSignatoryNotifyPayload,
  AgreementSignatoryNotifyPayloadFromJSON,
  AgreementSignatoryNotifyPayloadToJSON,
  AgreementSignatoryRejectPayload,
  AgreementSignatoryRejectPayloadFromJSON,
  AgreementSignatoryRejectPayloadToJSON,
  AgreementSignatoryUpdateInternalPayload,
  AgreementSignatoryUpdateInternalPayloadFromJSON,
  AgreementSignatoryUpdateInternalPayloadToJSON,
  AgreementTemplateCategoryModel,
  AgreementTemplateCategoryModelFromJSON,
  AgreementTemplateCategoryModelToJSON,
  AgreementTemplateModel,
  AgreementTemplateModelFromJSON,
  AgreementTemplateModelToJSON,
  AgreementUpdatePayload,
  AgreementUpdatePayloadFromJSON,
  AgreementUpdatePayloadToJSON,
  AgreementVersionCreatePayload,
  AgreementVersionCreatePayloadFromJSON,
  AgreementVersionCreatePayloadToJSON,
  Answer,
  AnswerFromJSON,
  AnswerToJSON,
  AnswerDeletePayload,
  AnswerDeletePayloadFromJSON,
  AnswerDeletePayloadToJSON,
  AssertionCategoryModel,
  AssertionCategoryModelFromJSON,
  AssertionCategoryModelToJSON,
  AssertionCreatePayload,
  AssertionCreatePayloadFromJSON,
  AssertionCreatePayloadToJSON,
  AssertionExtended,
  AssertionExtendedFromJSON,
  AssertionExtendedToJSON,
  AssertionResultExcludePayload,
  AssertionResultExcludePayloadFromJSON,
  AssertionResultExcludePayloadToJSON,
  AssertionResultExtended,
  AssertionResultExtendedFromJSON,
  AssertionResultExtendedToJSON,
  AssertionRunExtended,
  AssertionRunExtendedFromJSON,
  AssertionRunExtendedToJSON,
  AssertionRunList,
  AssertionRunListFromJSON,
  AssertionRunListToJSON,
  AssertionUpdatePayload,
  AssertionUpdatePayloadFromJSON,
  AssertionUpdatePayloadToJSON,
  Assessment,
  AssessmentFromJSON,
  AssessmentToJSON,
  AssessmentCreatePayload,
  AssessmentCreatePayloadFromJSON,
  AssessmentCreatePayloadToJSON,
  AssessmentDeletePayload,
  AssessmentDeletePayloadFromJSON,
  AssessmentDeletePayloadToJSON,
  AssessmentDownload,
  AssessmentDownloadFromJSON,
  AssessmentDownloadToJSON,
  AssessmentResponse,
  AssessmentResponseFromJSON,
  AssessmentResponseToJSON,
  AssessmentResponseAnswer,
  AssessmentResponseAnswerFromJSON,
  AssessmentResponseAnswerToJSON,
  AssessmentTemplateExtended,
  AssessmentTemplateExtendedFromJSON,
  AssessmentTemplateExtendedToJSON,
  AssessmentTemplateModel,
  AssessmentTemplateModelFromJSON,
  AssessmentTemplateModelToJSON,
  AssessmentUpdatePayload,
  AssessmentUpdatePayloadFromJSON,
  AssessmentUpdatePayloadToJSON,
  AuditCreatePayload,
  AuditCreatePayloadFromJSON,
  AuditCreatePayloadToJSON,
  AuditExtended,
  AuditExtendedFromJSON,
  AuditExtendedToJSON,
  AuditModel,
  AuditModelFromJSON,
  AuditModelToJSON,
  AuditReviewHistoryItem,
  AuditReviewHistoryItemFromJSON,
  AuditReviewHistoryItemToJSON,
  AuditTemplateExtended,
  AuditTemplateExtendedFromJSON,
  AuditTemplateExtendedToJSON,
  AuditTemplateModel,
  AuditTemplateModelFromJSON,
  AuditTemplateModelToJSON,
  AuditUpdatePayload,
  AuditUpdatePayloadFromJSON,
  AuditUpdatePayloadToJSON,
  BusinessFunctionCreatePayload,
  BusinessFunctionCreatePayloadFromJSON,
  BusinessFunctionCreatePayloadToJSON,
  BusinessFunctionModel,
  BusinessFunctionModelFromJSON,
  BusinessFunctionModelToJSON,
  BusinessFunctionUpdatePayload,
  BusinessFunctionUpdatePayloadFromJSON,
  BusinessFunctionUpdatePayloadToJSON,
  CalendarItemList,
  CalendarItemListFromJSON,
  CalendarItemListToJSON,
  Card,
  CardFromJSON,
  CardToJSON,
  CertificateModel,
  CertificateModelFromJSON,
  CertificateModelToJSON,
  ChatWidgetLogin,
  ChatWidgetLoginFromJSON,
  ChatWidgetLoginToJSON,
  ChecklistChangedStatusPayload,
  ChecklistChangedStatusPayloadFromJSON,
  ChecklistChangedStatusPayloadToJSON,
  ChecklistCreatePayload,
  ChecklistCreatePayloadFromJSON,
  ChecklistCreatePayloadToJSON,
  ChecklistExtended,
  ChecklistExtendedFromJSON,
  ChecklistExtendedToJSON,
  ChecklistTemplate,
  ChecklistTemplateFromJSON,
  ChecklistTemplateToJSON,
  ChecklistTemplateCreatePayload,
  ChecklistTemplateCreatePayloadFromJSON,
  ChecklistTemplateCreatePayloadToJSON,
  ChecklistTemplateExtended,
  ChecklistTemplateExtendedFromJSON,
  ChecklistTemplateExtendedToJSON,
  ChecklistTemplateUpdatePayload,
  ChecklistTemplateUpdatePayloadFromJSON,
  ChecklistTemplateUpdatePayloadToJSON,
  ChecklistUpdatePayload,
  ChecklistUpdatePayloadFromJSON,
  ChecklistUpdatePayloadToJSON,
  Company,
  CompanyFromJSON,
  CompanyToJSON,
  CompanyExtended,
  CompanyExtendedFromJSON,
  CompanyExtendedToJSON,
  CompanyIdentifierCreatePayload,
  CompanyIdentifierCreatePayloadFromJSON,
  CompanyIdentifierCreatePayloadToJSON,
  CompanyIdentifierModel,
  CompanyIdentifierModelFromJSON,
  CompanyIdentifierModelToJSON,
  CompanyIdentifierType,
  CompanyIdentifierTypeFromJSON,
  CompanyIdentifierTypeToJSON,
  CompanyIdentifierUpdatePayload,
  CompanyIdentifierUpdatePayloadFromJSON,
  CompanyIdentifierUpdatePayloadToJSON,
  CompanyUpdatePayload,
  CompanyUpdatePayloadFromJSON,
  CompanyUpdatePayloadToJSON,
  ControlCategoryExtended,
  ControlCategoryExtendedFromJSON,
  ControlCategoryExtendedToJSON,
  ControlCreatePayload,
  ControlCreatePayloadFromJSON,
  ControlCreatePayloadToJSON,
  ControlExtended,
  ControlExtendedFromJSON,
  ControlExtendedToJSON,
  ControlModel,
  ControlModelFromJSON,
  ControlModelToJSON,
  ControlUpdatePayload,
  ControlUpdatePayloadFromJSON,
  ControlUpdatePayloadToJSON,
  ControlUpdateStatusPayload,
  ControlUpdateStatusPayloadFromJSON,
  ControlUpdateStatusPayloadToJSON,
  CorrectiveActionExtended,
  CorrectiveActionExtendedFromJSON,
  CorrectiveActionExtendedToJSON,
  DashboardSummary,
  DashboardSummaryFromJSON,
  DashboardSummaryToJSON,
  DataRoomEntityDeletePayload,
  DataRoomEntityDeletePayloadFromJSON,
  DataRoomEntityDeletePayloadToJSON,
  DataRoomEntityFolderCreatePayload,
  DataRoomEntityFolderCreatePayloadFromJSON,
  DataRoomEntityFolderCreatePayloadToJSON,
  DataRoomEntityModel,
  DataRoomEntityModelFromJSON,
  DataRoomEntityModelToJSON,
  DataRoomEntityMovePayload,
  DataRoomEntityMovePayloadFromJSON,
  DataRoomEntityMovePayloadToJSON,
  DataRoomEntityUpdatePayload,
  DataRoomEntityUpdatePayloadFromJSON,
  DataRoomEntityUpdatePayloadToJSON,
  EmailArray,
  EmailArrayFromJSON,
  EmailArrayToJSON,
  EmailText,
  EmailTextFromJSON,
  EmailTextToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  EscrowAccount,
  EscrowAccountFromJSON,
  EscrowAccountToJSON,
  EvidenceModel,
  EvidenceModelFromJSON,
  EvidenceModelToJSON,
  ExternalShare,
  ExternalShareFromJSON,
  ExternalShareToJSON,
  FrameworkCreatePayload,
  FrameworkCreatePayloadFromJSON,
  FrameworkCreatePayloadToJSON,
  FrameworkExportEvidencePayload,
  FrameworkExportEvidencePayloadFromJSON,
  FrameworkExportEvidencePayloadToJSON,
  FrameworkExtended,
  FrameworkExtendedFromJSON,
  FrameworkExtendedToJSON,
  FrameworkGuidanceModel,
  FrameworkGuidanceModelFromJSON,
  FrameworkGuidanceModelToJSON,
  FrameworkModel,
  FrameworkModelFromJSON,
  FrameworkModelToJSON,
  FrameworkRequirementCategoryExtended,
  FrameworkRequirementCategoryExtendedFromJSON,
  FrameworkRequirementCategoryExtendedToJSON,
  FrameworkTemplateExtended,
  FrameworkTemplateExtendedFromJSON,
  FrameworkTemplateExtendedToJSON,
  FrameworkTemplateModel,
  FrameworkTemplateModelFromJSON,
  FrameworkTemplateModelToJSON,
  GlobalSearchItem,
  GlobalSearchItemFromJSON,
  GlobalSearchItemToJSON,
  Image,
  ImageFromJSON,
  ImageToJSON,
  IncidentEventExtended,
  IncidentEventExtendedFromJSON,
  IncidentEventExtendedToJSON,
  InlineObject,
  InlineObjectFromJSON,
  InlineObjectToJSON,
  Invoice,
  InvoiceFromJSON,
  InvoiceToJSON,
  JurisdictionModel,
  JurisdictionModelFromJSON,
  JurisdictionModelToJSON,
  LegalRegisterModel,
  LegalRegisterModelFromJSON,
  LegalRegisterModelToJSON,
  LegalRegisterUpdatePayload,
  LegalRegisterUpdatePayloadFromJSON,
  LegalRegisterUpdatePayloadToJSON,
  LegislationAddPayload,
  LegislationAddPayloadFromJSON,
  LegislationAddPayloadToJSON,
  LegislationClassificationModel,
  LegislationClassificationModelFromJSON,
  LegislationClassificationModelToJSON,
  LegislationCreatePayload,
  LegislationCreatePayloadFromJSON,
  LegislationCreatePayloadToJSON,
  LegislationExtended,
  LegislationExtendedFromJSON,
  LegislationExtendedToJSON,
  LegislationModel,
  LegislationModelFromJSON,
  LegislationModelToJSON,
  LegislationTemplateModel,
  LegislationTemplateModelFromJSON,
  LegislationTemplateModelToJSON,
  LegislationUpdatePayload,
  LegislationUpdatePayloadFromJSON,
  LegislationUpdatePayloadToJSON,
  MessageResponse,
  MessageResponseFromJSON,
  MessageResponseToJSON,
  MultipleShare,
  MultipleShareFromJSON,
  MultipleShareToJSON,
  MultipleShareDelete,
  MultipleShareDeleteFromJSON,
  MultipleShareDeleteToJSON,
  NotificationList,
  NotificationListFromJSON,
  NotificationListToJSON,
  PaymentMethod,
  PaymentMethodFromJSON,
  PaymentMethodToJSON,
  PaymentMethodUpdatePayload,
  PaymentMethodUpdatePayloadFromJSON,
  PaymentMethodUpdatePayloadToJSON,
  PaymentPlan,
  PaymentPlanFromJSON,
  PaymentPlanToJSON,
  PaymentPlanPrice,
  PaymentPlanPriceFromJSON,
  PaymentPlanPriceToJSON,
  PaymentPlanVendorCheckoutCreatePayload,
  PaymentPlanVendorCheckoutCreatePayloadFromJSON,
  PaymentPlanVendorCheckoutCreatePayloadToJSON,
  PaymentPlanVendorCheckoutModel,
  PaymentPlanVendorCheckoutModelFromJSON,
  PaymentPlanVendorCheckoutModelToJSON,
  PestelItemCreatePayload,
  PestelItemCreatePayloadFromJSON,
  PestelItemCreatePayloadToJSON,
  PestelItemModel,
  PestelItemModelFromJSON,
  PestelItemModelToJSON,
  PestelItemUpdatePayload,
  PestelItemUpdatePayloadFromJSON,
  PestelItemUpdatePayloadToJSON,
  PestelRegisterModel,
  PestelRegisterModelFromJSON,
  PestelRegisterModelToJSON,
  PestelRegisterUpdatePayload,
  PestelRegisterUpdatePayloadFromJSON,
  PestelRegisterUpdatePayloadToJSON,
  Policy,
  PolicyFromJSON,
  PolicyToJSON,
  PolicyCategory,
  PolicyCategoryFromJSON,
  PolicyCategoryToJSON,
  RecurringPaymentCheckoutCreatePayload,
  RecurringPaymentCheckoutCreatePayloadFromJSON,
  RecurringPaymentCheckoutCreatePayloadToJSON,
  RecurringPaymentCheckoutModel,
  RecurringPaymentCheckoutModelFromJSON,
  RecurringPaymentCheckoutModelToJSON,
  ReferenceCreatePayload,
  ReferenceCreatePayloadFromJSON,
  ReferenceCreatePayloadToJSON,
  RegisteredCompany,
  RegisteredCompanyFromJSON,
  RegisteredCompanyToJSON,
  ReminderCreatePayload,
  ReminderCreatePayloadFromJSON,
  ReminderCreatePayloadToJSON,
  ReminderList,
  ReminderListFromJSON,
  ReminderListToJSON,
  ReminderModel,
  ReminderModelFromJSON,
  ReminderModelToJSON,
  ReminderUpdatePayload,
  ReminderUpdatePayloadFromJSON,
  ReminderUpdatePayloadToJSON,
  ReportCreatePayload,
  ReportCreatePayloadFromJSON,
  ReportCreatePayloadToJSON,
  ReportModel,
  ReportModelFromJSON,
  ReportModelToJSON,
  ReviewHistoryItemCreatePayload,
  ReviewHistoryItemCreatePayloadFromJSON,
  ReviewHistoryItemCreatePayloadToJSON,
  ReviewHistoryItemModel,
  ReviewHistoryItemModelFromJSON,
  ReviewHistoryItemModelToJSON,
  RiskCategoryModel,
  RiskCategoryModelFromJSON,
  RiskCategoryModelToJSON,
  RiskCreatePayload,
  RiskCreatePayloadFromJSON,
  RiskCreatePayloadToJSON,
  RiskExtended,
  RiskExtendedFromJSON,
  RiskExtendedToJSON,
  RiskModel,
  RiskModelFromJSON,
  RiskModelToJSON,
  RiskReportCreatePayload,
  RiskReportCreatePayloadFromJSON,
  RiskReportCreatePayloadToJSON,
  RiskReportModel,
  RiskReportModelFromJSON,
  RiskReportModelToJSON,
  RiskTemplateModel,
  RiskTemplateModelFromJSON,
  RiskTemplateModelToJSON,
  RiskUpdatePayload,
  RiskUpdatePayloadFromJSON,
  RiskUpdatePayloadToJSON,
  ShareReceiver,
  ShareReceiverFromJSON,
  ShareReceiverToJSON,
  ShareSuggestionList,
  ShareSuggestionListFromJSON,
  ShareSuggestionListToJSON,
  SharedDocumentList,
  SharedDocumentListFromJSON,
  SharedDocumentListToJSON,
  SharedEmploymentStatus,
  SharedEmploymentStatusFromJSON,
  SharedEmploymentStatusToJSON,
  SharedUserType,
  SharedUserTypeFromJSON,
  SharedUserTypeToJSON,
  SharedVendorUserStatus,
  SharedVendorUserStatusFromJSON,
  SharedVendorUserStatusToJSON,
  SharesList,
  SharesListFromJSON,
  SharesListToJSON,
  Subscription,
  SubscriptionFromJSON,
  SubscriptionToJSON,
  SubscriptionUpdatePayload,
  SubscriptionUpdatePayloadFromJSON,
  SubscriptionUpdatePayloadToJSON,
  SurveyClause,
  SurveyClauseFromJSON,
  SurveyClauseToJSON,
  SurveyClauseUpdatePayload,
  SurveyClauseUpdatePayloadFromJSON,
  SurveyClauseUpdatePayloadToJSON,
  Table,
  TableFromJSON,
  TableToJSON,
  TaskAvailableAssertionsPayload,
  TaskAvailableAssertionsPayloadFromJSON,
  TaskAvailableAssertionsPayloadToJSON,
  TaskCreatePayload,
  TaskCreatePayloadFromJSON,
  TaskCreatePayloadToJSON,
  TaskExtended,
  TaskExtendedFromJSON,
  TaskExtendedToJSON,
  TaskList,
  TaskListFromJSON,
  TaskListToJSON,
  TaskUpdatePayload,
  TaskUpdatePayloadFromJSON,
  TaskUpdatePayloadToJSON,
  TwoFactorSmsRequestPayload,
  TwoFactorSmsRequestPayloadFromJSON,
  TwoFactorSmsRequestPayloadToJSON,
  TwoFactorVerifyOtpPayload,
  TwoFactorVerifyOtpPayloadFromJSON,
  TwoFactorVerifyOtpPayloadToJSON,
  TwoFactorVerifyTokenPayload,
  TwoFactorVerifyTokenPayloadFromJSON,
  TwoFactorVerifyTokenPayloadToJSON,
  UserDetails,
  UserDetailsFromJSON,
  UserDetailsToJSON,
  UserForgotPassword,
  UserForgotPasswordFromJSON,
  UserForgotPasswordToJSON,
  UserInvitation,
  UserInvitationFromJSON,
  UserInvitationToJSON,
  UserPassword,
  UserPasswordFromJSON,
  UserPasswordToJSON,
  UserResetPassword,
  UserResetPasswordFromJSON,
  UserResetPasswordToJSON,
  UserSignIn,
  UserSignInFromJSON,
  UserSignInToJSON,
  UserSignUp,
  UserSignUpFromJSON,
  UserSignUpToJSON,
  Vendor,
  VendorFromJSON,
  VendorToJSON,
  VendorActionExtended,
  VendorActionExtendedFromJSON,
  VendorActionExtendedToJSON,
  VendorActionModel,
  VendorActionModelFromJSON,
  VendorActionModelToJSON,
  VendorDetails,
  VendorDetailsFromJSON,
  VendorDetailsToJSON,
  VendorDocument,
  VendorDocumentFromJSON,
  VendorDocumentToJSON,
  VendorDocumentApproval,
  VendorDocumentApprovalFromJSON,
  VendorDocumentApprovalToJSON,
  VendorDocumentApprovalReject,
  VendorDocumentApprovalRejectFromJSON,
  VendorDocumentApprovalRejectToJSON,
  VendorDocumentAttestation,
  VendorDocumentAttestationFromJSON,
  VendorDocumentAttestationToJSON,
  VendorDocumentAttestationsGroupUpdatePayload,
  VendorDocumentAttestationsGroupUpdatePayloadFromJSON,
  VendorDocumentAttestationsGroupUpdatePayloadToJSON,
  VendorDocumentDownload,
  VendorDocumentDownloadFromJSON,
  VendorDocumentDownloadToJSON,
  VendorDocumentExtended,
  VendorDocumentExtendedFromJSON,
  VendorDocumentExtendedToJSON,
  VendorDocumentUpdatePayload,
  VendorDocumentUpdatePayloadFromJSON,
  VendorDocumentUpdatePayloadToJSON,
  VendorEventExtended,
  VendorEventExtendedFromJSON,
  VendorEventExtendedToJSON,
  VendorEventModel,
  VendorEventModelFromJSON,
  VendorEventModelToJSON,
  VendorFrameworkRequirementCreatePayload,
  VendorFrameworkRequirementCreatePayloadFromJSON,
  VendorFrameworkRequirementCreatePayloadToJSON,
  VendorFrameworkRequirementModel,
  VendorFrameworkRequirementModelFromJSON,
  VendorFrameworkRequirementModelToJSON,
  VendorIntegrationCreatePayload,
  VendorIntegrationCreatePayloadFromJSON,
  VendorIntegrationCreatePayloadToJSON,
  VendorIntegrationExtended,
  VendorIntegrationExtendedFromJSON,
  VendorIntegrationExtendedToJSON,
  VendorIntegrationModel,
  VendorIntegrationModelFromJSON,
  VendorIntegrationModelToJSON,
  VendorIntegrationSyncCreatePayload,
  VendorIntegrationSyncCreatePayloadFromJSON,
  VendorIntegrationSyncCreatePayloadToJSON,
  VendorIntegrationSyncExtended,
  VendorIntegrationSyncExtendedFromJSON,
  VendorIntegrationSyncExtendedToJSON,
  VendorIntegrationUpdatePayload,
  VendorIntegrationUpdatePayloadFromJSON,
  VendorIntegrationUpdatePayloadToJSON,
  VendorPolicy,
  VendorPolicyFromJSON,
  VendorPolicyToJSON,
  VendorPolicyClause,
  VendorPolicyClauseFromJSON,
  VendorPolicyClauseToJSON,
  VendorPolicyDetails,
  VendorPolicyDetailsFromJSON,
  VendorPolicyDetailsToJSON,
  VendorPolicyProgress,
  VendorPolicyProgressFromJSON,
  VendorPolicyProgressToJSON,
  VendorPolicyPublish,
  VendorPolicyPublishFromJSON,
  VendorPolicyPublishToJSON,
  VendorPolicyType,
  VendorPolicyTypeFromJSON,
  VendorPolicyTypeToJSON,
  VendorProductIndex,
  VendorProductIndexFromJSON,
  VendorProductIndexToJSON,
  VendorSupplierCategory,
  VendorSupplierCategoryFromJSON,
  VendorSupplierCategoryToJSON,
  VendorSupplierExtended,
  VendorSupplierExtendedFromJSON,
  VendorSupplierExtendedToJSON,
  VendorSupplierModel,
  VendorSupplierModelFromJSON,
  VendorSupplierModelToJSON,
  VendorSupplierPayload,
  VendorSupplierPayloadFromJSON,
  VendorSupplierPayloadToJSON,
  VendorTeamModel,
  VendorTeamModelFromJSON,
  VendorTeamModelToJSON,
  VendorTeamUpsertPayload,
  VendorTeamUpsertPayloadFromJSON,
  VendorTeamUpsertPayloadToJSON,
  VendorTreeNodeModel,
  VendorTreeNodeModelFromJSON,
  VendorTreeNodeModelToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserToJSON,
  VendorUserCreatePayload,
  VendorUserCreatePayloadFromJSON,
  VendorUserCreatePayloadToJSON,
  VendorUserRoles,
  VendorUserRolesFromJSON,
  VendorUserRolesToJSON,
  VendorUserStatus,
  VendorUserStatusFromJSON,
  VendorUserStatusToJSON,
  VendorUserUpdatePayload,
  VendorUserUpdatePayloadFromJSON,
  VendorUserUpdatePayloadToJSON,
  VendorsCreatePayload,
  VendorsCreatePayloadFromJSON,
  VendorsCreatePayloadToJSON,
} from '../models';

export interface AgreementSignatoriesIdDeleteRequest {
  id: string;
}

export interface AgreementSignatoriesIdGetRequest {
  id: string;
}

export interface AgreementSignatoriesIdPatchRequest {
  id: string;
  agreementSignatoryType?: string;
  agreementSignatoryFirstName?: string;
  agreementSignatoryLastName?: string;
  agreementSignatoryStatus?: string;
  agreementSignatoryEmail?: string;
  agreementSignatoryAddress?: string;
  agreementSignatoryCompanyName?: string;
  agreementSignatoryCompanyAddress?: string;
  agreementSignatoryCompanyNumber?: string;
  agreementSignatoryDocument?: Blob;
}

export interface AgreementSignatoriesSignatoryIdNotifyPostRequest {
  signatoryId: string;
  body?: AgreementSignatoryNotifyPayload;
}

export interface AgreementSignatoriesSignatoryIdRejectPatchRequest {
  signatoryId: string;
  body?: AgreementSignatoryRejectPayload;
}

export interface AgreementSignatoriesSignatoryIdSignPatchRequest {
  signatoryId: string;
}

export interface AgreementTemplatesGetRequest {
  vendorId?: string;
}

export interface AgreementTemplatesIdGetRequest {
  id: string;
}

export interface AgreementsAgreementIdAgreementSignatoriesExternalPostRequest {
  agreementId: string;
  body: AgreementSignatoryCreateExternalPayload;
}

export interface AgreementsAgreementIdAgreementSignatoriesGetRequest {
  agreementId: string;
}

export interface AgreementsAgreementIdAgreementSignatoriesInternalPatchRequest {
  agreementId: string;
  body: AgreementSignatoryUpdateInternalPayload;
}

export interface AgreementsIdArchivePostRequest {
  id: string;
}

export interface AgreementsIdDeleteRequest {
  id: string;
}

export interface AgreementsIdGetRequest {
  id: string;
}

export interface AgreementsIdPatchRequest {
  id: string;
  body: AgreementUpdatePayload;
}

export interface AgreementsIdPreviewGetRequest {
  id: string;
}

export interface AgreementsIdSendPostRequest {
  id: string;
  body: AgreementSendPayload;
}

export interface AgreementsIdUnarchivePostRequest {
  id: string;
}

export interface AgreementsIdVersionsPostRequest {
  id: string;
  body: AgreementVersionCreatePayload;
}

export interface AnswersIdPatchRequest {
  id: string;
  answer: Answer;
}

export interface AssertionResultsAssertionResultIdExcludePatchRequest {
  assertionResultId: string;
  body: AssertionResultExcludePayload;
}

export interface AssertionResultsAssertionResultIdGetRequest {
  assertionResultId: string;
}

export interface AssertionResultsAssertionResultIdIncludePatchRequest {
  assertionResultId: string;
}

export interface AssertionRunsAssertionRunIdGetRequest {
  assertionRunId: string;
}

export interface AssertionsAssertionIdCreateReviewPostRequest {
  assertionId: string;
  assertionReviewStatus?: string;
  assertionReviewComment?: string;
  assertionReviewEvidences?: Array<Blob>;
}

export interface AssertionsAssertionIdDeleteRequest {
  assertionId: string;
}

export interface AssertionsAssertionIdGetRequest {
  assertionId: string;
}

export interface AssertionsAssertionIdPatchRequest {
  assertionId: string;
  body: AssertionUpdatePayload;
}

export interface AssertionsAssertionIdRunPostRequest {
  assertionId: string;
}

export interface AssessmentDownloadsIdDeleteRequest {
  id: string;
}

export interface AssessmentDownloadsIdGetRequest {
  id: string;
}

export interface AssessmentResponseAnswerFilesIdDeleteRequest {
  id: string;
}

export interface AssessmentResponseAnswersIdConfirmPatchRequest {
  id: string;
}

export interface AssessmentResponseAnswersIdDeleteRequest {
  id: string;
}

export interface AssessmentResponsesIdAssessmentResponseAnswersHistoryGetRequest {
  id: string;
  questionId?: string;
}

export interface AssessmentResponsesIdDeleteRequest {
  id: string;
}

export interface AssessmentResponsesIdGetRequest {
  id: string;
}

export interface AssessmentResponsesIdPatchRequest {
  id: string;
  body: AssessmentResponse;
}

export interface AssessmentResponsesIdPublishPatchRequest {
  id: string;
}

export interface AssessmentTemplatesIdGetRequest {
  id: string;
}

export interface AssessmentsAssessmentIdAssessmentRecipientsRecipientIdDeleteRequest {
  assessmentId: string;
  recipientId: string;
}

export interface AssessmentsIdAssessmentDownloadsGetRequest {
  id: string;
}

export interface AssessmentsIdAssessmentDownloadsPostRequest {
  id: string;
  assessmentDownload?: AssessmentDownload;
}

export interface AssessmentsIdAssessmentResponsesGetRequest {
  id: string;
}

export interface AssessmentsIdDeleteRequest {
  id: string;
  body: AssessmentDeletePayload;
}

export interface AssessmentsIdGetRequest {
  id: string;
}

export interface AssessmentsIdPatchRequest {
  id: string;
  body: AssessmentUpdatePayload;
}

export interface AssessmentsIdSharePostRequest {
  id: string;
  externalShare: ExternalShare;
}

export interface AuditTemplatesIdGetRequest {
  id: string;
}

export interface AuditsAuditIdReviewHistoryGetRequest {
  auditId: string;
  questionId: string;
}

export interface AuditsIdCompletePostRequest {
  id: string;
}

export interface AuditsIdDeleteRequest {
  id: string;
}

export interface AuditsIdGetRequest {
  id: string;
}

export interface AuditsIdPatchRequest {
  id: string;
  body: AuditUpdatePayload;
}

export interface AuditsIdPreviewGetRequest {
  id: string;
}

export interface BusinessFunctionsIdDeleteRequest {
  id: string;
}

export interface BusinessFunctionsIdGetRequest {
  id: string;
}

export interface BusinessFunctionsIdPatchRequest {
  id: string;
  body: BusinessFunctionUpdatePayload;
}

export interface CertificatesIdDeleteRequest {
  id: string;
}

export interface CertificatesIdDocumentGetRequest {
  id: string;
}

export interface CertificatesIdGetRequest {
  id: string;
}

export interface ChecklistTemplatesIdGetRequest {
  id: string;
}

export interface ChecklistTemplatesIdPatchRequest {
  id: string;
  body: ChecklistTemplateUpdatePayload;
}

export interface ChecklistsIdChangeStatusPatchRequest {
  id: string;
  body: ChecklistChangedStatusPayload;
}

export interface ChecklistsIdGetRequest {
  id: string;
}

export interface ChecklistsIdPatchRequest {
  id: string;
  body: ChecklistUpdatePayload;
}

export interface CompaniesIdCompanyIdentifiersGetRequest {
  id: string;
}

export interface CompaniesIdCompanyIdentifiersPostRequest {
  id: string;
  body: CompanyIdentifierCreatePayload;
}

export interface CompaniesIdGetRequest {
  id: string;
}

export interface CompaniesIdOfficesOfficeIdDeleteRequest {
  id: string;
  officeId: string;
}

export interface CompaniesIdPatchRequest {
  id: string;
  body: CompanyUpdatePayload;
}

export interface CompaniesValidatePostRequest {
  id: string;
  body: Company;
}

export interface CompanyIdentifiersIdDeleteRequest {
  id: string;
}

export interface CompanyIdentifiersIdGetRequest {
  id: string;
}

export interface CompanyIdentifiersIdPatchRequest {
  id: string;
  body: CompanyIdentifierUpdatePayload;
}

export interface ConfigsConfigIdGetRequest {
  configId: ConfigsConfigIdGetConfigIdEnum;
}

export interface ControlsControlIdGetRequest {
  controlId: string;
  vendorId?: string;
}

export interface ControlsControlIdPatchRequest {
  controlId: string;
  body: ControlUpdatePayload;
}

export interface ControlsControlIdReferencesDeleteRequest {
  controlId: string;
  referenceableId: string;
  referenceableType: string;
}

export interface ControlsControlIdReferencesPostRequest {
  controlId: string;
  body: ReferenceCreatePayload;
}

export interface ControlsControlIdStatusPatchRequest {
  controlId: string;
  body: ControlUpdateStatusPayload;
}

export interface CorrectiveActionsCorrectiveActionIdDeleteRequest {
  correctiveActionId: string;
}

export interface CorrectiveActionsCorrectiveActionIdGetRequest {
  correctiveActionId: string;
}

export interface CorrectiveActionsCorrectiveActionIdPatchRequest {
  correctiveActionId: string;
  correctiveActionName?: string;
  correctiveActionDescription?: string;
  correctiveActionIdentifier?: string;
  correctiveActionDateIdentified?: string;
  correctiveActionRaisedBy?: string;
  correctiveActionRootCause?: string;
  correctiveActionOwnerId?: string;
  correctiveActionCompleted?: boolean;
  correctiveActionUrl?: string;
  correctiveActionAttachments?: Array<Blob>;
  correctiveActionDeletedAttachmentIds?: Array<number>;
  correctiveActionActionType?: CorrectiveActionsCorrectiveActionIdPatchCorrectiveActionActionTypeEnum;
  correctiveActionCorrectiveActions?: string;
}

export interface CreateRecurringPaymentGetRequest {
  purchasableType: string;
  purchasableId: string;
  paymentPeriod: CreateRecurringPaymentGetPaymentPeriodEnum;
}

export interface CreateStripeRecurringPaymentPostRequest {
  body: RecurringPaymentCheckoutCreatePayload;
}

export interface CreateStripeSessionPostRequest {
  body: PaymentPlanVendorCheckoutCreatePayload;
}

export interface DataRoomEntitiesEntityIdPatchRequest {
  entityId: string;
  body: DataRoomEntityUpdatePayload;
}

export interface DataRoomFilesFileIdDownloadGetRequest {
  fileId: string;
}

export interface EvidencesEvidenceIdDeleteRequest {
  evidenceId: string;
}

export interface EvidencesEvidenceIdDownloadGetRequest {
  evidenceId: string;
}

export interface FrameworkGuidancesIdGetRequest {
  id: string;
}

export interface FrameworkTemplatesIdGetRequest {
  id: string;
}

export interface FrameworksFrameworkIdFrameworkRequirementCategoriesGetRequest {
  frameworkId: string;
}

export interface FrameworksIdExportEvidencePostRequest {
  id: string;
  body: FrameworkExportEvidencePayload;
}

export interface FrameworksIdGetRequest {
  id: string;
}

export interface FrameworksIdPoliciesGetRequest {
  id: string;
}

export interface IncidentEventsIncidentEventIdDeleteRequest {
  incidentEventId: string;
}

export interface IncidentEventsIncidentEventIdGetRequest {
  incidentEventId: string;
}

export interface IncidentEventsIncidentEventIdPatchRequest {
  incidentEventId: string;
  incidentEventName?: string;
  incidentEventDescription?: string;
  incidentEventIdentifier?: string;
  incidentEventDateIdentified?: string;
  incidentEventRaisedBy?: string;
  incidentEventRootCause?: string;
  incidentEventOwnerId?: string;
  incidentEventCompleted?: boolean;
  incidentEventUrl?: string;
  incidentEventAttachments?: Array<Blob>;
  incidentEventDeletedAttachmentIds?: Array<number>;
  incidentEventPreventativeActions?: string;
  incidentEventActionsTaken?: string;
  incidentEventDataBreach?: boolean;
  incidentEventDataBreachDetails?: string;
}

export interface LegislationsIdDeleteRequest {
  id: string;
}

export interface LegislationsIdGetRequest {
  id: string;
}

export interface LegislationsIdPatchRequest {
  id: string;
  body: LegislationUpdatePayload;
}

export interface NotificationsNotificationIdReadPatchRequest {
  notificationId: string;
}

export interface ParseEmailsPostRequest {
  email: EmailText;
}

export interface PaymentMethodsIdDeleteRequest {
  id: string;
}

export interface PaymentMethodsIdGetRequest {
  id: string;
}

export interface PaymentMethodsIdPatchRequest {
  id: string;
  card: PaymentMethodUpdatePayload;
}

export interface PaymentMethodsPostRequest {
  card?: Card;
}

export interface PaymentPlanVendorsGetRequest {
  paymentPlanId: string;
}

export interface PaymentPlansPricingGetRequest {
  usersLimit: number;
  paymentPeriod: PaymentPlansPricingGetPaymentPeriodEnum;
  paymentPlanIds: Array<string>;
}

export interface PestelItemsIdDeleteRequest {
  id: string;
}

export interface PestelItemsIdGetRequest {
  id: string;
}

export interface PestelItemsIdPatchRequest {
  id: string;
  body: PestelItemUpdatePayload;
}

export interface RemindersReminderIdDeleteRequest {
  reminderId: string;
}

export interface RemindersReminderIdPatchRequest {
  reminderId: string;
  body?: ReminderUpdatePayload;
}

export interface ReportsIdDeleteRequest {
  id: string;
}

export interface ReportsIdDocumentGetRequest {
  id: string;
}

export interface ReportsIdGetRequest {
  id: string;
}

export interface RiskCategoriesGetRequest {
  vendorId?: string;
}

export interface RiskReportsIdDeleteRequest {
  id: string;
}

export interface RiskReportsIdGetRequest {
  id: string;
}

export interface RisksIdApprovePostRequest {
  id: string;
}

export interface RisksIdDeleteRequest {
  id: string;
}

export interface RisksIdGetRequest {
  id: string;
}

export interface RisksIdPatchRequest {
  id: string;
  body: RiskUpdatePayload;
}

export interface SearchRegisteredCompaniesqqGetRequest {
  q: string;
}

export interface SubscriptionsIdDeleteRequest {
  id: string;
}

export interface SubscriptionsIdPatchRequest {
  id: string;
  body: SubscriptionUpdatePayload;
}

export interface SurveyClausesSurveyClauseIdPatchRequest {
  surveyClauseId: string;
  body: SurveyClauseUpdatePayload;
}

export interface SurveyClausesSurveyClauseIdRevertCustomTextPatchRequest {
  surveyClauseId: string;
}

export interface TablesTypeGetRequest {
  type: string;
}

export interface TasksTaskIdAvailableAssertionsPatchRequest {
  taskId: string;
  body: TaskAvailableAssertionsPayload;
}

export interface TasksTaskIdEvidencesPostRequest {
  taskId: string;
  attachment?: Blob;
  description?: string;
}

export interface TasksTaskIdGetRequest {
  taskId: string;
}

export interface TasksTaskIdPatchRequest {
  taskId: string;
  body: TaskUpdatePayload;
}

export interface UserDetailsPatchRequest {
  userDetails?: UserDetails;
}

export interface UsersGetRequest {
  queryEmail?: string;
}

export interface UsersIdSignatureGetRequest {
  id: string;
}

export interface UsersIdSignaturePatchRequest {
  id: string;
  image: Blob;
}

export interface UsersInvitationsPostRequest {
  invitation?: UserInvitation;
}

export interface UsersInvitationsTokenGetRequest {
  token: string;
}

export interface UsersPasswordPatchRequest {
  user?: UserResetPassword;
}

export interface UsersPasswordPostRequest {
  user?: UserForgotPassword;
}

export interface UsersPatchRequest {
  password?: UserPassword;
}

export interface UsersPostRequest {
  user?: UserSignUp;
}

export interface UsersSignInPostRequest {
  session?: UserSignIn;
}

export interface UsersSmsRequestPostRequest {
  payload?: TwoFactorSmsRequestPayload;
}

export interface UsersVerifyOtpPostRequest {
  payload?: TwoFactorVerifyOtpPayload;
}

export interface UsersVerifyTokenPostRequest {
  payload?: TwoFactorVerifyTokenPayload;
}

export interface VendorActionsVendorActionIdDeleteRequest {
  vendorActionId: string;
}

export interface VendorActionsVendorActionIdGetRequest {
  vendorActionId: string;
}

export interface VendorActionsVendorActionIdPatchRequest {
  vendorActionId: string;
  vendorActionName: string;
  vendorActionOwnerId: string;
  vendorActionCompleted: boolean;
  vendorActionDescription?: string;
  vendorActionDueDate?: string;
  vendorActionAttachments?: Array<Blob>;
  vendorActionUrl?: string;
  vendorActionActionType?: string;
  vendorActionDeletedAttachmentIds?: Array<number>;
}

export interface VendorActionsVendorActionIdReferencesDeleteRequest {
  vendorActionId: string;
  referenceableId: string;
  referenceableType: string;
}

export interface VendorActionsVendorActionIdReferencesPostRequest {
  vendorActionId: string;
  body: ReferenceCreatePayload;
}

export interface VendorDocumentAttestationsAttestationIdNotifyPostRequest {
  attestationId: string;
}

export interface VendorDocumentsIdApprovePostRequest {
  id: string;
}

export interface VendorDocumentsIdArchivePostRequest {
  id: string;
}

export interface VendorDocumentsIdAttestPostRequest {
  id: string;
}

export interface VendorDocumentsIdDeleteRequest {
  id: string;
}

export interface VendorDocumentsIdDownloadGetRequest {
  id: string;
}

export interface VendorDocumentsIdGetRequest {
  id: string;
}

export interface VendorDocumentsIdPatchRequest {
  id: string;
  vendorDocument?: VendorDocumentUpdatePayload;
}

export interface VendorDocumentsIdRejectPostRequest {
  id: string;
  vendorDocumentApproval: VendorDocumentApprovalReject;
}

export interface VendorDocumentsIdSkipApprovalPostRequest {
  id: string;
}

export interface VendorDocumentsIdSkipAttestationPostRequest {
  id: string;
}

export interface VendorDocumentsIdSummaryGetRequest {
  id: string;
}

export interface VendorDocumentsIdUnarchivePostRequest {
  id: string;
}

export interface VendorDocumentsIdVendorDocumentApprovalsGetRequest {
  id: string;
}

export interface VendorDocumentsIdVendorDocumentAttestationGroupsGetRequest {
  id: string;
}

export interface VendorDocumentsIdVendorDocumentAttestationGroupsPatchRequest {
  id: string;
  body: VendorDocumentAttestationsGroupUpdatePayload;
}

export interface VendorEventsVendorEventIdDeleteRequest {
  vendorEventId: string;
}

export interface VendorEventsVendorEventIdGetRequest {
  vendorEventId: string;
}

export interface VendorEventsVendorEventIdPatchRequest {
  vendorEventId: string;
  vendorEventName: string;
  vendorEventDate: string;
  vendorEventEventType: string;
  vendorEventOwnerId: string;
  vendorEventDescription?: string;
  vendorEventAttachments?: Array<Blob>;
  vendorEventUrl?: string;
  vendorEventDeletedAttachmentIds?: Array<number>;
}

export interface VendorFrameworkRequirementsIdDeleteRequest {
  id: string;
}

export interface VendorIntegrationSyncsVendorIntegrationSyncIdDeleteRequest {
  vendorIntegrationSyncId: string;
}

export interface VendorIntegrationsVendorIntegrationIdDeleteRequest {
  vendorIntegrationId: string;
}

export interface VendorIntegrationsVendorIntegrationIdGetRequest {
  vendorIntegrationId: string;
}

export interface VendorIntegrationsVendorIntegrationIdPatchRequest {
  vendorIntegrationId: string;
  body: VendorIntegrationUpdatePayload;
}

export interface VendorIntegrationsVendorIntegrationIdVendorIntegrationSyncsPostRequest {
  vendorIntegrationId: string;
  body: VendorIntegrationSyncCreatePayload;
}

export interface VendorPoliciesIdDeleteRequest {
  id: string;
}

export interface VendorPoliciesIdGetRequest {
  id: string;
  omitClauseText?: boolean;
}

export interface VendorPoliciesIdPatchRequest {
  id: string;
  vendorPolicy?: VendorPolicyDetails;
}

export interface VendorPoliciesIdPdfGetRequest {
  id: string;
}

export interface VendorPoliciesIdProgressPatchRequest {
  id: string;
  progress?: VendorPolicyProgress;
}

export interface VendorPoliciesIdPublishPostRequest {
  id: string;
  vendorPolicy?: VendorPolicyPublish;
}

export interface VendorPoliciesIdVendorPolicyClausesGetRequest {
  id: string;
}

export interface VendorPolicyClausesIdGetRequest {
  id: string;
}

export interface VendorPolicyClausesIdPatchRequest {
  id: string;
  vendorPolicyClause?: VendorPolicyClause;
}

export interface VendorSuppliersIdDeleteRequest {
  id: string;
}

export interface VendorSuppliersIdGetRequest {
  id: string;
}

export interface VendorSuppliersIdPatchRequest {
  id: string;
  body: VendorSupplierPayload;
}

export interface VendorTeamsIdDeleteRequest {
  id: string;
}

export interface VendorTeamsIdGetRequest {
  id: string;
}

export interface VendorTeamsIdPatchRequest {
  id: string;
  vendorTeam?: VendorTeamModel;
}

export interface VendorUsersIdDeleteRequest {
  id: string;
}

export interface VendorUsersIdGetRequest {
  id: string;
}

export interface VendorUsersIdPatchRequest {
  id: string;
  body?: VendorUserUpdatePayload;
}

export interface VendorUsersIdPermissionsGetRequest {
  id: string;
}

export interface VendorUsersVendorUserIdNotificationsGetRequest {
  vendorUserId: string;
  perPage?: string;
  page?: string;
  showRead?: boolean;
}

export interface VendorUsersVendorUserIdRemindersGetRequest {
  vendorUserId: string;
  relatedToType?: string;
  relatedToId?: string;
  perPage?: string;
  page?: string;
}

export interface VendorUsersVendorUserIdRemindersPostRequest {
  vendorUserId: string;
  body?: ReminderCreatePayload;
}

export interface VendorsIdAgreementsGetRequest {
  id: string;
}

export interface VendorsIdAgreementsPostRequest {
  id: string;
  body: AgreementCreatePayload;
}

export interface VendorsIdAgreementsUploadPostRequest {
  id: string;
  agreementName: string;
  agreementDocument: Blob;
  agreementDescription?: string;
}

export interface VendorsIdAnswersDeleteRequest {
  id: string;
  body: AnswerDeletePayload;
}

export interface VendorsIdAnswersPostRequest {
  id: string;
  answers?: InlineObject;
}

export interface VendorsIdAssessmentResponsesGetRequest {
  id: string;
}

export interface VendorsIdAssessmentsGetRequest {
  id: string;
}

export interface VendorsIdAssessmentsPostRequest {
  id: string;
  body: AssessmentCreatePayload;
}

export interface VendorsIdChecklistTemplatesGetRequest {
  id: string;
}

export interface VendorsIdChecklistTemplatesPostRequest {
  id: string;
  body: ChecklistTemplateCreatePayload;
}

export interface VendorsIdChecklistsGetRequest {
  id: string;
}

export interface VendorsIdChecklistsPostRequest {
  id: string;
  body: ChecklistCreatePayload;
}

export interface VendorsIdDashboardSummaryGetRequest {
  id: string;
}

export interface VendorsIdEscrowAccountsGetRequest {
  id: string;
}

export interface VendorsIdFrameworksGetRequest {
  id: string;
  includeStats?: boolean;
}

export interface VendorsIdFrameworksPostRequest {
  id: string;
  body: FrameworkCreatePayload;
}

export interface VendorsIdGetRequest {
  id: string;
}

export interface VendorsIdInvoicesGetRequest {
  id: string;
}

export interface VendorsIdLogoGetRequest {
  id: string;
}

export interface VendorsIdLogoPatchRequest {
  id: string;
  image: Blob;
}

export interface VendorsIdOrgChartGetRequest {
  id: string;
}

export interface VendorsIdOrgChartPatchRequest {
  id: string;
  image: Blob;
}

export interface VendorsIdPatchRequest {
  id: string;
  vendor?: Vendor;
}

export interface VendorsIdPoliciesGetRequest {
  id: string;
}

export interface VendorsIdProductsGetRequest {
  id: string;
  queryProductType?: string;
  queryShowUnavailable?: boolean;
}

export interface VendorsIdSharesDeleteRequest {
  id: string;
  shareableType: VendorsIdSharesDeleteShareableTypeEnum;
  shareableId: string;
  body: MultipleShareDelete;
}

export interface VendorsIdSharesGetRequest {
  id: string;
  shareableType: VendorsIdSharesGetShareableTypeEnum;
  shareableId: string;
}

export interface VendorsIdSharesPostRequest {
  id: string;
  shareableType: VendorsIdSharesPostShareableTypeEnum;
  shareableId: string;
  body: MultipleShare;
}

export interface VendorsIdSharesSuggestionsGetRequest {
  id: string;
  shareableType: VendorsIdSharesSuggestionsGetShareableTypeEnum;
  shareableId: string;
  perPage?: string;
  textSearch?: string;
}

export interface VendorsIdTablesGetRequest {
  id: string;
  type: string;
  statuses?: Array<VendorUserStatus>;
}

export interface VendorsIdTreeGetRequest {
  id: string;
}

export interface VendorsIdVendorDocumentsGetRequest {
  id: string;
  queryDocumentType?: string;
  queryShowHistory?: boolean;
}

export interface VendorsIdVendorPoliciesGetRequest {
  id: string;
}

export interface VendorsIdVendorPoliciesPostRequest {
  id: string;
  vendorPolicy?: VendorPolicy;
}

export interface VendorsIdVendorTeamsGetRequest {
  id: string;
  query?: string;
  viewExtended?: boolean;
}

export interface VendorsIdVendorTeamsPostRequest {
  id: string;
  body?: VendorTeamUpsertPayload;
}

export interface VendorsIdVendorUsersChatWidgetLoginPostRequest {
  id: string;
}

export interface VendorsIdVendorUsersGetRequest {
  id: string;
  queryRoles?: Array<VendorUserRoles>;
  queryUserTypes?: Array<SharedUserType>;
  queryEmploymentStatuses?: Array<SharedEmploymentStatus>;
  queryStatuses?: Array<SharedVendorUserStatus>;
  includeComplianceStats?: boolean;
}

export interface VendorsIdVendorUsersPostRequest {
  id: string;
  body?: VendorUserCreatePayload;
}

export interface VendorsIdVendorUsersTrusthubLoginPostRequest {
  id: string;
}

export interface VendorsPostRequest {
  body?: VendorsCreatePayload;
}

export interface VendorsVendorIdAssertionRunsGetRequest {
  vendorId: string;
  assertionId?: string;
  assertionName?: string;
  assertionCategoryIdentifier?: string;
  assertionStatus?: string;
  showManual?: boolean;
  perPage?: string;
  page?: string;
}

export interface VendorsVendorIdAssertionsPostRequest {
  vendorId: string;
  body: AssertionCreatePayload;
}

export interface VendorsVendorIdAssessmentTemplatesGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdAuditsGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdAuditsPostRequest {
  vendorId: string;
  body: AuditCreatePayload;
}

export interface VendorsVendorIdAuditsUploadMeetingPostRequest {
  vendorId: string;
  auditName: string;
  auditOwnerId: string;
  auditFile: Blob;
  auditStartedAt: string;
}

export interface VendorsVendorIdAuditsUploadPostRequest {
  vendorId: string;
  auditName: string;
  auditOrigin: VendorsVendorIdAuditsUploadPostAuditOriginEnum;
  auditOwnerId: string;
  auditFile: Blob;
  auditStartedAt: string;
  auditCompletedAt: string;
  auditRating: VendorsVendorIdAuditsUploadPostAuditRatingEnum;
}

export interface VendorsVendorIdBusinessFunctionsGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdBusinessFunctionsPostRequest {
  vendorId: string;
  body: BusinessFunctionCreatePayload;
}

export interface VendorsVendorIdCalendarGetRequest {
  vendorId: string;
  startDate: string;
  endDate: string;
  viewMode: VendorsVendorIdCalendarGetViewModeEnum;
  statuses?: Array<VendorsVendorIdCalendarGetStatusesEnum>;
  ownerIds?: Array<string>;
  textSearch?: string;
  perPage?: string;
  page?: string;
}

export interface VendorsVendorIdCertificatesGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdCertificatesPostRequest {
  vendorId: string;
  attachment?: Blob;
  certificateType?: string;
  expireAt?: string;
}

export interface VendorsVendorIdControlCategoriesGetRequest {
  vendorId: string;
  frameworkId: string;
}

export interface VendorsVendorIdControlsGetRequest {
  vendorId: string;
  frameworkId?: string;
  queryApplicable?: string;
}

export interface VendorsVendorIdControlsPostRequest {
  vendorId: string;
  body: ControlCreatePayload;
}

export interface VendorsVendorIdCorrectiveActionsPostRequest {
  vendorId: string;
  correctiveActionName: string;
  correctiveActionDescription: string;
  correctiveActionDateIdentified: string;
  correctiveActionRaisedBy: string;
  correctiveActionRootCause: string;
  correctiveActionActionType: VendorsVendorIdCorrectiveActionsPostCorrectiveActionActionTypeEnum;
  correctiveActionCorrectiveActions: string;
  correctiveActionIdentifier?: string;
  correctiveActionOwnerId?: string;
  correctiveActionUrl?: string;
  correctiveActionAttachments?: Array<Blob>;
}

export interface VendorsVendorIdDataRoomEntitiesDeleteRequest {
  vendorId: string;
  body: DataRoomEntityDeletePayload;
}

export interface VendorsVendorIdDataRoomEntitiesGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdDataRoomEntitiesMovePatchRequest {
  vendorId: string;
  body: DataRoomEntityMovePayload;
}

export interface VendorsVendorIdDataRoomEntitiesTreeGetRequest {
  vendorId: string;
  nodeId?: string;
  depth?: number;
  type?: string;
}

export interface VendorsVendorIdDataRoomEvidenceGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdDataRoomFilesPostRequest {
  vendorId: string;
  parentFolderId: string;
  files0Attachment: Blob;
  files0Filename: string;
  files0Description?: string;
}

export interface VendorsVendorIdDataRoomFoldersPostRequest {
  vendorId: string;
  body: DataRoomEntityFolderCreatePayload;
}

export interface VendorsVendorIdIncidentEventsPostRequest {
  vendorId: string;
  incidentEventName: string;
  incidentEventDescription: string;
  incidentEventDateIdentified: string;
  incidentEventRaisedBy: string;
  incidentEventRootCause: string;
  incidentEventPreventativeActions: string;
  incidentEventActionsTaken: string;
  incidentEventDataBreach: boolean;
  incidentEventIdentifier?: string;
  incidentEventOwnerId?: string;
  incidentEventUrl?: string;
  incidentEventAttachments?: Array<Blob>;
  incidentEventDataBreachDetails?: string;
}

export interface VendorsVendorIdLegalRegisterGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdLegalRegisterPatchRequest {
  vendorId: string;
  body: LegalRegisterUpdatePayload;
}

export interface VendorsVendorIdLegislationsAddPostRequest {
  vendorId: string;
  body: LegislationAddPayload;
}

export interface VendorsVendorIdLegislationsGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdLegislationsPostRequest {
  vendorId: string;
  body: LegislationCreatePayload;
}

export interface VendorsVendorIdPestelItemsGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdPestelItemsPostRequest {
  vendorId: string;
  body: PestelItemCreatePayload;
}

export interface VendorsVendorIdPestelRegisterGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdPestelRegisterPatchRequest {
  vendorId: string;
  body: PestelRegisterUpdatePayload;
}

export interface VendorsVendorIdReportsGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdReportsPostRequest {
  vendorId: string;
  body: ReportCreatePayload;
}

export interface VendorsVendorIdReportsUploadPostRequest {
  vendorId: string;
  reportFile: Blob;
  reportName: string;
  reportCompletedAt: string;
  reportVersion: string;
  reportOwnerId: string;
}

export interface VendorsVendorIdReviewHistoryItemsGetRequest {
  vendorId: string;
  relatedToId: string;
  relatedToType: VendorsVendorIdReviewHistoryItemsGetRelatedToTypeEnum;
}

export interface VendorsVendorIdReviewHistoryItemsPostRequest {
  vendorId: string;
  body: ReviewHistoryItemCreatePayload;
}

export interface VendorsVendorIdRiskReportsGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdRiskReportsPostRequest {
  vendorId: string;
  body: RiskReportCreatePayload;
}

export interface VendorsVendorIdRisksGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdRisksPostRequest {
  vendorId: string;
  body: RiskCreatePayload;
}

export interface VendorsVendorIdSearchGetRequest {
  vendorId: string;
  term: string;
}

export interface VendorsVendorIdTasksGetRequest {
  vendorId: string;
  frameworkId?: string;
  notUsedInFrameworksIds?: Array<string>;
  applicable?: boolean;
  includeStats?: boolean;
  status?: Array<string>;
  name?: string;
}

export interface VendorsVendorIdTasksPostRequest {
  vendorId: string;
  body: TaskCreatePayload;
}

export interface VendorsVendorIdVendorActionsGetRequest {
  vendorId: string;
  referenceableId?: string;
  referenceableType?: string;
}

export interface VendorsVendorIdVendorActionsPostRequest {
  vendorId: string;
  vendorActionName: string;
  vendorActionOwnerId: string;
  vendorActionDescription?: string;
  vendorActionDueDate?: string;
  vendorActionUrl?: string;
  vendorActionActionType?: string;
  vendorActionAttachments?: Array<Blob>;
}

export interface VendorsVendorIdVendorDocumentAttestationsGetRequest {
  vendorId: string;
  targetId: string;
  targetType: VendorsVendorIdVendorDocumentAttestationsGetTargetTypeEnum;
  includePossibleAttestations?: boolean;
}

export interface VendorsVendorIdVendorDocumentsPostRequest {
  vendorId: string;
  vendorDocumentDocument?: Blob;
  vendorDocumentName?: string;
  vendorDocumentDocumentType?: string;
  vendorDocumentDescription?: string;
  vendorDocumentPolicyCategoryId?: string;
  vendorDocumentFileSizeInBytes?: number;
}

export interface VendorsVendorIdVendorEventsGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdVendorEventsPostRequest {
  vendorId: string;
  vendorEventName: string;
  vendorEventDate: string;
  vendorEventEventType: string;
  vendorEventOwnerId: string;
  vendorEventDescription?: string;
  vendorEventAttachments?: Array<Blob>;
  vendorEventUrl?: string;
}

export interface VendorsVendorIdVendorFrameworkRequirementsPostRequest {
  vendorId: string;
  body: VendorFrameworkRequirementCreatePayload;
}

export interface VendorsVendorIdVendorIntegrationsGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdVendorIntegrationsPostRequest {
  vendorId: string;
  body: VendorIntegrationCreatePayload;
}

export interface VendorsVendorIdVendorPoliciesGetRequest {
  vendorId: string;
  queryStatus?: Array<VendorsVendorIdVendorPoliciesGetQueryStatusEnum>;
  queryType?: Array<VendorPolicyType>;
}

export interface VendorsVendorIdVendorSuppliersCategoriesGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdVendorSuppliersGetRequest {
  vendorId: string;
}

export interface VendorsVendorIdVendorSuppliersPostRequest {
  vendorId: string;
  body: VendorSupplierPayload;
}

/**
 * no description
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   * Remove agreement signatory
   */
  async agreementSignatoriesIdDeleteRaw(
    requestParameters: AgreementSignatoriesIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling agreementSignatoriesIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/agreement_signatories/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Remove agreement signatory
   */
  async agreementSignatoriesIdDelete(
    requestParameters: AgreementSignatoriesIdDeleteRequest
  ): Promise<void> {
    await this.agreementSignatoriesIdDeleteRaw(requestParameters);
  }

  /**
   * Get agreement signatory
   */
  async agreementSignatoriesIdGetRaw(
    requestParameters: AgreementSignatoriesIdGetRequest
  ): Promise<runtime.ApiResponse<AgreementSignatoryModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling agreementSignatoriesIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/agreement_signatories/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AgreementSignatoryModelFromJSON(jsonValue)
    );
  }

  /**
   * Get agreement signatory
   */
  async agreementSignatoriesIdGet(
    requestParameters: AgreementSignatoriesIdGetRequest
  ): Promise<AgreementSignatoryModel> {
    const response = await this.agreementSignatoriesIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update agreement signatory
   */
  async agreementSignatoriesIdPatchRaw(
    requestParameters: AgreementSignatoriesIdPatchRequest
  ): Promise<runtime.ApiResponse<AgreementSignatoryModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling agreementSignatoriesIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.agreementSignatoryType !== undefined) {
      formParams.append(
        'agreement_signatory[type]',
        requestParameters.agreementSignatoryType as any
      );
    }

    if (requestParameters.agreementSignatoryFirstName !== undefined) {
      formParams.append(
        'agreement_signatory[first_name]',
        requestParameters.agreementSignatoryFirstName as any
      );
    }

    if (requestParameters.agreementSignatoryLastName !== undefined) {
      formParams.append(
        'agreement_signatory[last_name]',
        requestParameters.agreementSignatoryLastName as any
      );
    }

    if (requestParameters.agreementSignatoryStatus !== undefined) {
      formParams.append(
        'agreement_signatory[status]',
        requestParameters.agreementSignatoryStatus as any
      );
    }

    if (requestParameters.agreementSignatoryEmail !== undefined) {
      formParams.append(
        'agreement_signatory[email]',
        requestParameters.agreementSignatoryEmail as any
      );
    }

    if (requestParameters.agreementSignatoryAddress !== undefined) {
      formParams.append(
        'agreement_signatory[address]',
        requestParameters.agreementSignatoryAddress as any
      );
    }

    if (requestParameters.agreementSignatoryCompanyName !== undefined) {
      formParams.append(
        'agreement_signatory[company_name]',
        requestParameters.agreementSignatoryCompanyName as any
      );
    }

    if (requestParameters.agreementSignatoryCompanyAddress !== undefined) {
      formParams.append(
        'agreement_signatory[company_address]',
        requestParameters.agreementSignatoryCompanyAddress as any
      );
    }

    if (requestParameters.agreementSignatoryCompanyNumber !== undefined) {
      formParams.append(
        'agreement_signatory[company_number]',
        requestParameters.agreementSignatoryCompanyNumber as any
      );
    }

    if (requestParameters.agreementSignatoryDocument !== undefined) {
      formParams.append(
        'agreement_signatory[document]',
        requestParameters.agreementSignatoryDocument as any
      );
    }

    const response = await this.request({
      path: `/agreement_signatories/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AgreementSignatoryModelFromJSON(jsonValue)
    );
  }

  /**
   * Update agreement signatory
   */
  async agreementSignatoriesIdPatch(
    requestParameters: AgreementSignatoriesIdPatchRequest
  ): Promise<AgreementSignatoryModel> {
    const response =
      await this.agreementSignatoriesIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Notify signatory
   */
  async agreementSignatoriesSignatoryIdNotifyPostRaw(
    requestParameters: AgreementSignatoriesSignatoryIdNotifyPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.signatoryId === null ||
      requestParameters.signatoryId === undefined
    ) {
      throw new runtime.RequiredError(
        'signatoryId',
        'Required parameter requestParameters.signatoryId was null or undefined when calling agreementSignatoriesSignatoryIdNotifyPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/agreement_signatories/{signatory_id}/notify`.replace(
        `{${'signatory_id'}}`,
        encodeURIComponent(String(requestParameters.signatoryId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AgreementSignatoryNotifyPayloadToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Notify signatory
   */
  async agreementSignatoriesSignatoryIdNotifyPost(
    requestParameters: AgreementSignatoriesSignatoryIdNotifyPostRequest
  ): Promise<void> {
    await this.agreementSignatoriesSignatoryIdNotifyPostRaw(requestParameters);
  }

  /**
   * Reject agreement
   */
  async agreementSignatoriesSignatoryIdRejectPatchRaw(
    requestParameters: AgreementSignatoriesSignatoryIdRejectPatchRequest
  ): Promise<runtime.ApiResponse<AgreementSignatoryModel>> {
    if (
      requestParameters.signatoryId === null ||
      requestParameters.signatoryId === undefined
    ) {
      throw new runtime.RequiredError(
        'signatoryId',
        'Required parameter requestParameters.signatoryId was null or undefined when calling agreementSignatoriesSignatoryIdRejectPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/agreement_signatories/{signatory_id}/reject`.replace(
        `{${'signatory_id'}}`,
        encodeURIComponent(String(requestParameters.signatoryId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: AgreementSignatoryRejectPayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AgreementSignatoryModelFromJSON(jsonValue)
    );
  }

  /**
   * Reject agreement
   */
  async agreementSignatoriesSignatoryIdRejectPatch(
    requestParameters: AgreementSignatoriesSignatoryIdRejectPatchRequest
  ): Promise<AgreementSignatoryModel> {
    const response =
      await this.agreementSignatoriesSignatoryIdRejectPatchRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Sign agreement
   */
  async agreementSignatoriesSignatoryIdSignPatchRaw(
    requestParameters: AgreementSignatoriesSignatoryIdSignPatchRequest
  ): Promise<runtime.ApiResponse<AgreementSignatoryModel>> {
    if (
      requestParameters.signatoryId === null ||
      requestParameters.signatoryId === undefined
    ) {
      throw new runtime.RequiredError(
        'signatoryId',
        'Required parameter requestParameters.signatoryId was null or undefined when calling agreementSignatoriesSignatoryIdSignPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/agreement_signatories/{signatory_id}/sign`.replace(
        `{${'signatory_id'}}`,
        encodeURIComponent(String(requestParameters.signatoryId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AgreementSignatoryModelFromJSON(jsonValue)
    );
  }

  /**
   * Sign agreement
   */
  async agreementSignatoriesSignatoryIdSignPatch(
    requestParameters: AgreementSignatoriesSignatoryIdSignPatchRequest
  ): Promise<AgreementSignatoryModel> {
    const response =
      await this.agreementSignatoriesSignatoryIdSignPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get agreement template categories
   */
  async agreementTemplateCategoriesGetRaw(): Promise<
    runtime.ApiResponse<Array<AgreementTemplateCategoryModel>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/agreement_template_categories`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AgreementTemplateCategoryModelFromJSON)
    );
  }

  /**
   * Get agreement template categories
   */
  async agreementTemplateCategoriesGet(): Promise<
    Array<AgreementTemplateCategoryModel>
  > {
    const response = await this.agreementTemplateCategoriesGetRaw();
    return await response.value();
  }

  /**
   * Get agreement templates
   */
  async agreementTemplatesGetRaw(
    requestParameters: AgreementTemplatesGetRequest
  ): Promise<runtime.ApiResponse<Array<AgreementTemplateModel>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.vendorId !== undefined) {
      queryParameters['vendor_id'] = requestParameters.vendorId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/agreement_templates`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AgreementTemplateModelFromJSON)
    );
  }

  /**
   * Get agreement templates
   */
  async agreementTemplatesGet(
    requestParameters: AgreementTemplatesGetRequest
  ): Promise<Array<AgreementTemplateModel>> {
    const response = await this.agreementTemplatesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get agreement template
   */
  async agreementTemplatesIdGetRaw(
    requestParameters: AgreementTemplatesIdGetRequest
  ): Promise<runtime.ApiResponse<AgreementTemplateModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling agreementTemplatesIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/agreement_templates/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AgreementTemplateModelFromJSON(jsonValue)
    );
  }

  /**
   * Get agreement template
   */
  async agreementTemplatesIdGet(
    requestParameters: AgreementTemplatesIdGetRequest
  ): Promise<AgreementTemplateModel> {
    const response = await this.agreementTemplatesIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create agreement external signatories
   */
  async agreementsAgreementIdAgreementSignatoriesExternalPostRaw(
    requestParameters: AgreementsAgreementIdAgreementSignatoriesExternalPostRequest
  ): Promise<runtime.ApiResponse<Array<AgreementSignatoryModel>>> {
    if (
      requestParameters.agreementId === null ||
      requestParameters.agreementId === undefined
    ) {
      throw new runtime.RequiredError(
        'agreementId',
        'Required parameter requestParameters.agreementId was null or undefined when calling agreementsAgreementIdAgreementSignatoriesExternalPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling agreementsAgreementIdAgreementSignatoriesExternalPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/agreements/{agreement_id}/agreement_signatories/external`.replace(
        `{${'agreement_id'}}`,
        encodeURIComponent(String(requestParameters.agreementId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AgreementSignatoryCreateExternalPayloadToJSON(
        requestParameters.body
      ),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AgreementSignatoryModelFromJSON)
    );
  }

  /**
   * Create agreement external signatories
   */
  async agreementsAgreementIdAgreementSignatoriesExternalPost(
    requestParameters: AgreementsAgreementIdAgreementSignatoriesExternalPostRequest
  ): Promise<Array<AgreementSignatoryModel>> {
    const response =
      await this.agreementsAgreementIdAgreementSignatoriesExternalPostRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get agreement signatories
   */
  async agreementsAgreementIdAgreementSignatoriesGetRaw(
    requestParameters: AgreementsAgreementIdAgreementSignatoriesGetRequest
  ): Promise<runtime.ApiResponse<Array<AgreementSignatoryModel>>> {
    if (
      requestParameters.agreementId === null ||
      requestParameters.agreementId === undefined
    ) {
      throw new runtime.RequiredError(
        'agreementId',
        'Required parameter requestParameters.agreementId was null or undefined when calling agreementsAgreementIdAgreementSignatoriesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/agreements/{agreement_id}/agreement_signatories`.replace(
        `{${'agreement_id'}}`,
        encodeURIComponent(String(requestParameters.agreementId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AgreementSignatoryModelFromJSON)
    );
  }

  /**
   * Get agreement signatories
   */
  async agreementsAgreementIdAgreementSignatoriesGet(
    requestParameters: AgreementsAgreementIdAgreementSignatoriesGetRequest
  ): Promise<Array<AgreementSignatoryModel>> {
    const response =
      await this.agreementsAgreementIdAgreementSignatoriesGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Update agreement internal signatories
   */
  async agreementsAgreementIdAgreementSignatoriesInternalPatchRaw(
    requestParameters: AgreementsAgreementIdAgreementSignatoriesInternalPatchRequest
  ): Promise<runtime.ApiResponse<Array<AgreementSignatoryModel>>> {
    if (
      requestParameters.agreementId === null ||
      requestParameters.agreementId === undefined
    ) {
      throw new runtime.RequiredError(
        'agreementId',
        'Required parameter requestParameters.agreementId was null or undefined when calling agreementsAgreementIdAgreementSignatoriesInternalPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling agreementsAgreementIdAgreementSignatoriesInternalPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/agreements/{agreement_id}/agreement_signatories/internal`.replace(
        `{${'agreement_id'}}`,
        encodeURIComponent(String(requestParameters.agreementId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: AgreementSignatoryUpdateInternalPayloadToJSON(
        requestParameters.body
      ),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AgreementSignatoryModelFromJSON)
    );
  }

  /**
   * Update agreement internal signatories
   */
  async agreementsAgreementIdAgreementSignatoriesInternalPatch(
    requestParameters: AgreementsAgreementIdAgreementSignatoriesInternalPatchRequest
  ): Promise<Array<AgreementSignatoryModel>> {
    const response =
      await this.agreementsAgreementIdAgreementSignatoriesInternalPatchRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Archive agreement
   */
  async agreementsIdArchivePostRaw(
    requestParameters: AgreementsIdArchivePostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling agreementsIdArchivePost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/agreements/{id}/archive`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Archive agreement
   */
  async agreementsIdArchivePost(
    requestParameters: AgreementsIdArchivePostRequest
  ): Promise<void> {
    await this.agreementsIdArchivePostRaw(requestParameters);
  }

  /**
   * Delete agreement
   */
  async agreementsIdDeleteRaw(
    requestParameters: AgreementsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling agreementsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/agreements/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete agreement
   */
  async agreementsIdDelete(
    requestParameters: AgreementsIdDeleteRequest
  ): Promise<void> {
    await this.agreementsIdDeleteRaw(requestParameters);
  }

  /**
   * Get agreement
   */
  async agreementsIdGetRaw(
    requestParameters: AgreementsIdGetRequest
  ): Promise<runtime.ApiResponse<AgreementExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling agreementsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/agreements/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AgreementExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get agreement
   */
  async agreementsIdGet(
    requestParameters: AgreementsIdGetRequest
  ): Promise<AgreementExtended> {
    const response = await this.agreementsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update agreement
   */
  async agreementsIdPatchRaw(
    requestParameters: AgreementsIdPatchRequest
  ): Promise<runtime.ApiResponse<AgreementExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling agreementsIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling agreementsIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/agreements/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: AgreementUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AgreementExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update agreement
   */
  async agreementsIdPatch(
    requestParameters: AgreementsIdPatchRequest
  ): Promise<AgreementExtended> {
    const response = await this.agreementsIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get agreement preview pdf
   */
  async agreementsIdPreviewGetRaw(
    requestParameters: AgreementsIdPreviewGetRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling agreementsIdPreviewGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/agreements/{id}/preview`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Get agreement preview pdf
   */
  async agreementsIdPreviewGet(
    requestParameters: AgreementsIdPreviewGetRequest
  ): Promise<void> {
    await this.agreementsIdPreviewGetRaw(requestParameters);
  }

  /**
   * Send notifications to review or sign agreement
   */
  async agreementsIdSendPostRaw(
    requestParameters: AgreementsIdSendPostRequest
  ): Promise<runtime.ApiResponse<AgreementExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling agreementsIdSendPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling agreementsIdSendPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/agreements/{id}/send`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AgreementSendPayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AgreementExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Send notifications to review or sign agreement
   */
  async agreementsIdSendPost(
    requestParameters: AgreementsIdSendPostRequest
  ): Promise<AgreementExtended> {
    const response = await this.agreementsIdSendPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Unarchive agreement
   */
  async agreementsIdUnarchivePostRaw(
    requestParameters: AgreementsIdUnarchivePostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling agreementsIdUnarchivePost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/agreements/{id}/unarchive`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Unarchive agreement
   */
  async agreementsIdUnarchivePost(
    requestParameters: AgreementsIdUnarchivePostRequest
  ): Promise<void> {
    await this.agreementsIdUnarchivePostRaw(requestParameters);
  }

  /**
   * Create new version of agreement
   */
  async agreementsIdVersionsPostRaw(
    requestParameters: AgreementsIdVersionsPostRequest
  ): Promise<runtime.ApiResponse<AgreementExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling agreementsIdVersionsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling agreementsIdVersionsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/agreements/{id}/versions`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AgreementVersionCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AgreementExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create new version of agreement
   */
  async agreementsIdVersionsPost(
    requestParameters: AgreementsIdVersionsPostRequest
  ): Promise<AgreementExtended> {
    const response = await this.agreementsIdVersionsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update the answer
   */
  async answersIdPatchRaw(
    requestParameters: AnswersIdPatchRequest
  ): Promise<runtime.ApiResponse<Answer>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling answersIdPatch.'
      );
    }

    if (
      requestParameters.answer === null ||
      requestParameters.answer === undefined
    ) {
      throw new runtime.RequiredError(
        'answer',
        'Required parameter requestParameters.answer was null or undefined when calling answersIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/answers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: AnswerToJSON(requestParameters.answer),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AnswerFromJSON(jsonValue)
    );
  }

  /**
   * Update the answer
   */
  async answersIdPatch(
    requestParameters: AnswersIdPatchRequest
  ): Promise<Answer> {
    const response = await this.answersIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get assertion categories list
   */
  async assertionCategoriesGetRaw(): Promise<
    runtime.ApiResponse<Array<AssertionCategoryModel>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assertion_categories`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AssertionCategoryModelFromJSON)
    );
  }

  /**
   * Get assertion categories list
   */
  async assertionCategoriesGet(): Promise<Array<AssertionCategoryModel>> {
    const response = await this.assertionCategoriesGetRaw();
    return await response.value();
  }

  /**
   * Exclude assertion result
   */
  async assertionResultsAssertionResultIdExcludePatchRaw(
    requestParameters: AssertionResultsAssertionResultIdExcludePatchRequest
  ): Promise<runtime.ApiResponse<AssertionResultExtended>> {
    if (
      requestParameters.assertionResultId === null ||
      requestParameters.assertionResultId === undefined
    ) {
      throw new runtime.RequiredError(
        'assertionResultId',
        'Required parameter requestParameters.assertionResultId was null or undefined when calling assertionResultsAssertionResultIdExcludePatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling assertionResultsAssertionResultIdExcludePatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/assertion_results/{assertion_result_id}/exclude`.replace(
        `{${'assertion_result_id'}}`,
        encodeURIComponent(String(requestParameters.assertionResultId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: AssertionResultExcludePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssertionResultExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Exclude assertion result
   */
  async assertionResultsAssertionResultIdExcludePatch(
    requestParameters: AssertionResultsAssertionResultIdExcludePatchRequest
  ): Promise<AssertionResultExtended> {
    const response =
      await this.assertionResultsAssertionResultIdExcludePatchRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get assertion result
   */
  async assertionResultsAssertionResultIdGetRaw(
    requestParameters: AssertionResultsAssertionResultIdGetRequest
  ): Promise<runtime.ApiResponse<AssertionResultExtended>> {
    if (
      requestParameters.assertionResultId === null ||
      requestParameters.assertionResultId === undefined
    ) {
      throw new runtime.RequiredError(
        'assertionResultId',
        'Required parameter requestParameters.assertionResultId was null or undefined when calling assertionResultsAssertionResultIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assertion_results/{assertion_result_id}`.replace(
        `{${'assertion_result_id'}}`,
        encodeURIComponent(String(requestParameters.assertionResultId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssertionResultExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get assertion result
   */
  async assertionResultsAssertionResultIdGet(
    requestParameters: AssertionResultsAssertionResultIdGetRequest
  ): Promise<AssertionResultExtended> {
    const response =
      await this.assertionResultsAssertionResultIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Include assertion result
   */
  async assertionResultsAssertionResultIdIncludePatchRaw(
    requestParameters: AssertionResultsAssertionResultIdIncludePatchRequest
  ): Promise<runtime.ApiResponse<AssertionResultExtended>> {
    if (
      requestParameters.assertionResultId === null ||
      requestParameters.assertionResultId === undefined
    ) {
      throw new runtime.RequiredError(
        'assertionResultId',
        'Required parameter requestParameters.assertionResultId was null or undefined when calling assertionResultsAssertionResultIdIncludePatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assertion_results/{assertion_result_id}/include`.replace(
        `{${'assertion_result_id'}}`,
        encodeURIComponent(String(requestParameters.assertionResultId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssertionResultExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Include assertion result
   */
  async assertionResultsAssertionResultIdIncludePatch(
    requestParameters: AssertionResultsAssertionResultIdIncludePatchRequest
  ): Promise<AssertionResultExtended> {
    const response =
      await this.assertionResultsAssertionResultIdIncludePatchRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get assertion run
   */
  async assertionRunsAssertionRunIdGetRaw(
    requestParameters: AssertionRunsAssertionRunIdGetRequest
  ): Promise<runtime.ApiResponse<AssertionRunExtended>> {
    if (
      requestParameters.assertionRunId === null ||
      requestParameters.assertionRunId === undefined
    ) {
      throw new runtime.RequiredError(
        'assertionRunId',
        'Required parameter requestParameters.assertionRunId was null or undefined when calling assertionRunsAssertionRunIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assertion_runs/{assertion_run_id}`.replace(
        `{${'assertion_run_id'}}`,
        encodeURIComponent(String(requestParameters.assertionRunId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssertionRunExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get assertion run
   */
  async assertionRunsAssertionRunIdGet(
    requestParameters: AssertionRunsAssertionRunIdGetRequest
  ): Promise<AssertionRunExtended> {
    const response =
      await this.assertionRunsAssertionRunIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create review
   */
  async assertionsAssertionIdCreateReviewPostRaw(
    requestParameters: AssertionsAssertionIdCreateReviewPostRequest
  ): Promise<runtime.ApiResponse<AssertionExtended>> {
    if (
      requestParameters.assertionId === null ||
      requestParameters.assertionId === undefined
    ) {
      throw new runtime.RequiredError(
        'assertionId',
        'Required parameter requestParameters.assertionId was null or undefined when calling assertionsAssertionIdCreateReviewPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.assertionReviewStatus !== undefined) {
      formParams.append(
        'assertion_review[status]',
        requestParameters.assertionReviewStatus as any
      );
    }

    if (requestParameters.assertionReviewComment !== undefined) {
      formParams.append(
        'assertion_review[comment]',
        requestParameters.assertionReviewComment as any
      );
    }

    if (requestParameters.assertionReviewEvidences) {
      formParams.append(
        'assertion_review[evidences]',
        requestParameters.assertionReviewEvidences.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    const response = await this.request({
      path: `/assertions/{assertion_id}/create_review`.replace(
        `{${'assertion_id'}}`,
        encodeURIComponent(String(requestParameters.assertionId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssertionExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create review
   */
  async assertionsAssertionIdCreateReviewPost(
    requestParameters: AssertionsAssertionIdCreateReviewPostRequest
  ): Promise<AssertionExtended> {
    const response =
      await this.assertionsAssertionIdCreateReviewPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete assertion
   */
  async assertionsAssertionIdDeleteRaw(
    requestParameters: AssertionsAssertionIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.assertionId === null ||
      requestParameters.assertionId === undefined
    ) {
      throw new runtime.RequiredError(
        'assertionId',
        'Required parameter requestParameters.assertionId was null or undefined when calling assertionsAssertionIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assertions/{assertion_id}`.replace(
        `{${'assertion_id'}}`,
        encodeURIComponent(String(requestParameters.assertionId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete assertion
   */
  async assertionsAssertionIdDelete(
    requestParameters: AssertionsAssertionIdDeleteRequest
  ): Promise<void> {
    await this.assertionsAssertionIdDeleteRaw(requestParameters);
  }

  /**
   * Get assertion
   */
  async assertionsAssertionIdGetRaw(
    requestParameters: AssertionsAssertionIdGetRequest
  ): Promise<runtime.ApiResponse<AssertionExtended>> {
    if (
      requestParameters.assertionId === null ||
      requestParameters.assertionId === undefined
    ) {
      throw new runtime.RequiredError(
        'assertionId',
        'Required parameter requestParameters.assertionId was null or undefined when calling assertionsAssertionIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assertions/{assertion_id}`.replace(
        `{${'assertion_id'}}`,
        encodeURIComponent(String(requestParameters.assertionId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssertionExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get assertion
   */
  async assertionsAssertionIdGet(
    requestParameters: AssertionsAssertionIdGetRequest
  ): Promise<AssertionExtended> {
    const response = await this.assertionsAssertionIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update assertion
   */
  async assertionsAssertionIdPatchRaw(
    requestParameters: AssertionsAssertionIdPatchRequest
  ): Promise<runtime.ApiResponse<AssertionExtended>> {
    if (
      requestParameters.assertionId === null ||
      requestParameters.assertionId === undefined
    ) {
      throw new runtime.RequiredError(
        'assertionId',
        'Required parameter requestParameters.assertionId was null or undefined when calling assertionsAssertionIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling assertionsAssertionIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/assertions/{assertion_id}`.replace(
        `{${'assertion_id'}}`,
        encodeURIComponent(String(requestParameters.assertionId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: AssertionUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssertionExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update assertion
   */
  async assertionsAssertionIdPatch(
    requestParameters: AssertionsAssertionIdPatchRequest
  ): Promise<AssertionExtended> {
    const response =
      await this.assertionsAssertionIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Run assertion
   */
  async assertionsAssertionIdRunPostRaw(
    requestParameters: AssertionsAssertionIdRunPostRequest
  ): Promise<runtime.ApiResponse<AssertionExtended>> {
    if (
      requestParameters.assertionId === null ||
      requestParameters.assertionId === undefined
    ) {
      throw new runtime.RequiredError(
        'assertionId',
        'Required parameter requestParameters.assertionId was null or undefined when calling assertionsAssertionIdRunPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assertions/{assertion_id}/run`.replace(
        `{${'assertion_id'}}`,
        encodeURIComponent(String(requestParameters.assertionId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssertionExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Run assertion
   */
  async assertionsAssertionIdRunPost(
    requestParameters: AssertionsAssertionIdRunPostRequest
  ): Promise<AssertionExtended> {
    const response =
      await this.assertionsAssertionIdRunPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Cancel assessment download
   */
  async assessmentDownloadsIdDeleteRaw(
    requestParameters: AssessmentDownloadsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentDownloadsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessment_downloads/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Cancel assessment download
   */
  async assessmentDownloadsIdDelete(
    requestParameters: AssessmentDownloadsIdDeleteRequest
  ): Promise<void> {
    await this.assessmentDownloadsIdDeleteRaw(requestParameters);
  }

  /**
   * Get assessment download
   */
  async assessmentDownloadsIdGetRaw(
    requestParameters: AssessmentDownloadsIdGetRequest
  ): Promise<runtime.ApiResponse<AssessmentDownload>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentDownloadsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessment_downloads/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssessmentDownloadFromJSON(jsonValue)
    );
  }

  /**
   * Get assessment download
   */
  async assessmentDownloadsIdGet(
    requestParameters: AssessmentDownloadsIdGetRequest
  ): Promise<AssessmentDownload> {
    const response = await this.assessmentDownloadsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete assessment response answer file
   */
  async assessmentResponseAnswerFilesIdDeleteRaw(
    requestParameters: AssessmentResponseAnswerFilesIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentResponseAnswerFilesIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessment_response_answer_files/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete assessment response answer file
   */
  async assessmentResponseAnswerFilesIdDelete(
    requestParameters: AssessmentResponseAnswerFilesIdDeleteRequest
  ): Promise<void> {
    await this.assessmentResponseAnswerFilesIdDeleteRaw(requestParameters);
  }

  /**
   * Confirm assessment response answer
   */
  async assessmentResponseAnswersIdConfirmPatchRaw(
    requestParameters: AssessmentResponseAnswersIdConfirmPatchRequest
  ): Promise<runtime.ApiResponse<AssessmentResponseAnswer>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentResponseAnswersIdConfirmPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessment_response_answers/{id}/confirm`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssessmentResponseAnswerFromJSON(jsonValue)
    );
  }

  /**
   * Confirm assessment response answer
   */
  async assessmentResponseAnswersIdConfirmPatch(
    requestParameters: AssessmentResponseAnswersIdConfirmPatchRequest
  ): Promise<AssessmentResponseAnswer> {
    const response =
      await this.assessmentResponseAnswersIdConfirmPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete assessment response answer
   */
  async assessmentResponseAnswersIdDeleteRaw(
    requestParameters: AssessmentResponseAnswersIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentResponseAnswersIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessment_response_answers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete assessment response answer
   */
  async assessmentResponseAnswersIdDelete(
    requestParameters: AssessmentResponseAnswersIdDeleteRequest
  ): Promise<void> {
    await this.assessmentResponseAnswersIdDeleteRaw(requestParameters);
  }

  /**
   * Get answer history by question ID/CODE
   */
  async assessmentResponsesIdAssessmentResponseAnswersHistoryGetRaw(
    requestParameters: AssessmentResponsesIdAssessmentResponseAnswersHistoryGetRequest
  ): Promise<runtime.ApiResponse<Array<AssessmentResponseAnswer>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentResponsesIdAssessmentResponseAnswersHistoryGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.questionId !== undefined) {
      queryParameters['question_id'] = requestParameters.questionId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessment_responses/{id}/assessment_response_answers/history`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AssessmentResponseAnswerFromJSON)
    );
  }

  /**
   * Get answer history by question ID/CODE
   */
  async assessmentResponsesIdAssessmentResponseAnswersHistoryGet(
    requestParameters: AssessmentResponsesIdAssessmentResponseAnswersHistoryGetRequest
  ): Promise<Array<AssessmentResponseAnswer>> {
    const response =
      await this.assessmentResponsesIdAssessmentResponseAnswersHistoryGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Delete assessment response
   */
  async assessmentResponsesIdDeleteRaw(
    requestParameters: AssessmentResponsesIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentResponsesIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessment_responses/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete assessment response
   */
  async assessmentResponsesIdDelete(
    requestParameters: AssessmentResponsesIdDeleteRequest
  ): Promise<void> {
    await this.assessmentResponsesIdDeleteRaw(requestParameters);
  }

  /**
   * Get the details of one assessment response
   */
  async assessmentResponsesIdGetRaw(
    requestParameters: AssessmentResponsesIdGetRequest
  ): Promise<runtime.ApiResponse<AssessmentResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentResponsesIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessment_responses/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssessmentResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get the details of one assessment response
   */
  async assessmentResponsesIdGet(
    requestParameters: AssessmentResponsesIdGetRequest
  ): Promise<AssessmentResponse> {
    const response = await this.assessmentResponsesIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update an assessment response
   */
  async assessmentResponsesIdPatchRaw(
    requestParameters: AssessmentResponsesIdPatchRequest
  ): Promise<runtime.ApiResponse<AssessmentResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentResponsesIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling assessmentResponsesIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/assessment_responses/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: AssessmentResponseToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssessmentResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update an assessment response
   */
  async assessmentResponsesIdPatch(
    requestParameters: AssessmentResponsesIdPatchRequest
  ): Promise<AssessmentResponse> {
    const response =
      await this.assessmentResponsesIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Publish an assessment response
   */
  async assessmentResponsesIdPublishPatchRaw(
    requestParameters: AssessmentResponsesIdPublishPatchRequest
  ): Promise<runtime.ApiResponse<AssessmentResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentResponsesIdPublishPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessment_responses/{id}/publish`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssessmentResponseFromJSON(jsonValue)
    );
  }

  /**
   * Publish an assessment response
   */
  async assessmentResponsesIdPublishPatch(
    requestParameters: AssessmentResponsesIdPublishPatchRequest
  ): Promise<AssessmentResponse> {
    const response =
      await this.assessmentResponsesIdPublishPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all available assessment_templates for the given vendor
   */
  async assessmentTemplatesIdGetRaw(
    requestParameters: AssessmentTemplatesIdGetRequest
  ): Promise<runtime.ApiResponse<AssessmentTemplateExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentTemplatesIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessment_templates/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssessmentTemplateExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get the list of all available assessment_templates for the given vendor
   */
  async assessmentTemplatesIdGet(
    requestParameters: AssessmentTemplatesIdGetRequest
  ): Promise<AssessmentTemplateExtended> {
    const response = await this.assessmentTemplatesIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete assessment recipients
   */
  async assessmentsAssessmentIdAssessmentRecipientsRecipientIdDeleteRaw(
    requestParameters: AssessmentsAssessmentIdAssessmentRecipientsRecipientIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.assessmentId === null ||
      requestParameters.assessmentId === undefined
    ) {
      throw new runtime.RequiredError(
        'assessmentId',
        'Required parameter requestParameters.assessmentId was null or undefined when calling assessmentsAssessmentIdAssessmentRecipientsRecipientIdDelete.'
      );
    }

    if (
      requestParameters.recipientId === null ||
      requestParameters.recipientId === undefined
    ) {
      throw new runtime.RequiredError(
        'recipientId',
        'Required parameter requestParameters.recipientId was null or undefined when calling assessmentsAssessmentIdAssessmentRecipientsRecipientIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessments/{assessment_id}/assessment_recipients/{recipient_id}`
        .replace(
          `{${'assessment_id'}}`,
          encodeURIComponent(String(requestParameters.assessmentId))
        )
        .replace(
          `{${'recipient_id'}}`,
          encodeURIComponent(String(requestParameters.recipientId))
        ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete assessment recipients
   */
  async assessmentsAssessmentIdAssessmentRecipientsRecipientIdDelete(
    requestParameters: AssessmentsAssessmentIdAssessmentRecipientsRecipientIdDeleteRequest
  ): Promise<void> {
    await this.assessmentsAssessmentIdAssessmentRecipientsRecipientIdDeleteRaw(
      requestParameters
    );
  }

  /**
   * Get assessment downloads for current user
   */
  async assessmentsIdAssessmentDownloadsGetRaw(
    requestParameters: AssessmentsIdAssessmentDownloadsGetRequest
  ): Promise<runtime.ApiResponse<Array<AssessmentDownload>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentsIdAssessmentDownloadsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessments/{id}/assessment_downloads`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AssessmentDownloadFromJSON)
    );
  }

  /**
   * Get assessment downloads for current user
   */
  async assessmentsIdAssessmentDownloadsGet(
    requestParameters: AssessmentsIdAssessmentDownloadsGetRequest
  ): Promise<Array<AssessmentDownload>> {
    const response =
      await this.assessmentsIdAssessmentDownloadsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create a new assessment download
   */
  async assessmentsIdAssessmentDownloadsPostRaw(
    requestParameters: AssessmentsIdAssessmentDownloadsPostRequest
  ): Promise<runtime.ApiResponse<AssessmentDownload>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentsIdAssessmentDownloadsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/assessments/{id}/assessment_downloads`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AssessmentDownloadToJSON(requestParameters.assessmentDownload),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssessmentDownloadFromJSON(jsonValue)
    );
  }

  /**
   * Create a new assessment download
   */
  async assessmentsIdAssessmentDownloadsPost(
    requestParameters: AssessmentsIdAssessmentDownloadsPostRequest
  ): Promise<AssessmentDownload> {
    const response =
      await this.assessmentsIdAssessmentDownloadsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all assessment responses for the given assessment
   */
  async assessmentsIdAssessmentResponsesGetRaw(
    requestParameters: AssessmentsIdAssessmentResponsesGetRequest
  ): Promise<runtime.ApiResponse<Array<AssessmentResponse>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentsIdAssessmentResponsesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessments/{id}/assessment_responses`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AssessmentResponseFromJSON)
    );
  }

  /**
   * Get the list of all assessment responses for the given assessment
   */
  async assessmentsIdAssessmentResponsesGet(
    requestParameters: AssessmentsIdAssessmentResponsesGetRequest
  ): Promise<Array<AssessmentResponse>> {
    const response =
      await this.assessmentsIdAssessmentResponsesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete assessment and all related responses
   */
  async assessmentsIdDeleteRaw(
    requestParameters: AssessmentsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentsIdDelete.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling assessmentsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/assessments/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      body: AssessmentDeletePayloadToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete assessment and all related responses
   */
  async assessmentsIdDelete(
    requestParameters: AssessmentsIdDeleteRequest
  ): Promise<void> {
    await this.assessmentsIdDeleteRaw(requestParameters);
  }

  /**
   * Get the details of one assessment
   */
  async assessmentsIdGetRaw(
    requestParameters: AssessmentsIdGetRequest
  ): Promise<runtime.ApiResponse<Assessment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/assessments/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssessmentFromJSON(jsonValue)
    );
  }

  /**
   * Get the details of one assessment
   */
  async assessmentsIdGet(
    requestParameters: AssessmentsIdGetRequest
  ): Promise<Assessment> {
    const response = await this.assessmentsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update assessment
   */
  async assessmentsIdPatchRaw(
    requestParameters: AssessmentsIdPatchRequest
  ): Promise<runtime.ApiResponse<Assessment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentsIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling assessmentsIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/assessments/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: AssessmentUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssessmentFromJSON(jsonValue)
    );
  }

  /**
   * Update assessment
   */
  async assessmentsIdPatch(
    requestParameters: AssessmentsIdPatchRequest
  ): Promise<Assessment> {
    const response = await this.assessmentsIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Share an assessment
   */
  async assessmentsIdSharePostRaw(
    requestParameters: AssessmentsIdSharePostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling assessmentsIdSharePost.'
      );
    }

    if (
      requestParameters.externalShare === null ||
      requestParameters.externalShare === undefined
    ) {
      throw new runtime.RequiredError(
        'externalShare',
        'Required parameter requestParameters.externalShare was null or undefined when calling assessmentsIdSharePost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/assessments/{id}/share`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ExternalShareToJSON(requestParameters.externalShare),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Share an assessment
   */
  async assessmentsIdSharePost(
    requestParameters: AssessmentsIdSharePostRequest
  ): Promise<void> {
    await this.assessmentsIdSharePostRaw(requestParameters);
  }

  /**
   * Get audit templates
   */
  async auditTemplatesGetRaw(): Promise<
    runtime.ApiResponse<Array<AuditTemplateModel>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/audit_templates`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AuditTemplateModelFromJSON)
    );
  }

  /**
   * Get audit templates
   */
  async auditTemplatesGet(): Promise<Array<AuditTemplateModel>> {
    const response = await this.auditTemplatesGetRaw();
    return await response.value();
  }

  /**
   * Get audit template
   */
  async auditTemplatesIdGetRaw(
    requestParameters: AuditTemplatesIdGetRequest
  ): Promise<runtime.ApiResponse<AuditTemplateExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling auditTemplatesIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/audit_templates/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AuditTemplateExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get audit template
   */
  async auditTemplatesIdGet(
    requestParameters: AuditTemplatesIdGetRequest
  ): Promise<AuditTemplateExtended> {
    const response = await this.auditTemplatesIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get audit review history by question id
   */
  async auditsAuditIdReviewHistoryGetRaw(
    requestParameters: AuditsAuditIdReviewHistoryGetRequest
  ): Promise<runtime.ApiResponse<Array<AuditReviewHistoryItem>>> {
    if (
      requestParameters.auditId === null ||
      requestParameters.auditId === undefined
    ) {
      throw new runtime.RequiredError(
        'auditId',
        'Required parameter requestParameters.auditId was null or undefined when calling auditsAuditIdReviewHistoryGet.'
      );
    }

    if (
      requestParameters.questionId === null ||
      requestParameters.questionId === undefined
    ) {
      throw new runtime.RequiredError(
        'questionId',
        'Required parameter requestParameters.questionId was null or undefined when calling auditsAuditIdReviewHistoryGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.questionId !== undefined) {
      queryParameters['question_id'] = requestParameters.questionId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/audits/{audit_id}/review_history`.replace(
        `{${'audit_id'}}`,
        encodeURIComponent(String(requestParameters.auditId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AuditReviewHistoryItemFromJSON)
    );
  }

  /**
   * Get audit review history by question id
   */
  async auditsAuditIdReviewHistoryGet(
    requestParameters: AuditsAuditIdReviewHistoryGetRequest
  ): Promise<Array<AuditReviewHistoryItem>> {
    const response =
      await this.auditsAuditIdReviewHistoryGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Complete Audit
   */
  async auditsIdCompletePostRaw(
    requestParameters: AuditsIdCompletePostRequest
  ): Promise<runtime.ApiResponse<AuditExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling auditsIdCompletePost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/audits/{id}/complete`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AuditExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Complete Audit
   */
  async auditsIdCompletePost(
    requestParameters: AuditsIdCompletePostRequest
  ): Promise<AuditExtended> {
    const response = await this.auditsIdCompletePostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete audit
   */
  async auditsIdDeleteRaw(
    requestParameters: AuditsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling auditsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/audits/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete audit
   */
  async auditsIdDelete(
    requestParameters: AuditsIdDeleteRequest
  ): Promise<void> {
    await this.auditsIdDeleteRaw(requestParameters);
  }

  /**
   * Get audit
   */
  async auditsIdGetRaw(
    requestParameters: AuditsIdGetRequest
  ): Promise<runtime.ApiResponse<AuditExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling auditsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/audits/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AuditExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get audit
   */
  async auditsIdGet(
    requestParameters: AuditsIdGetRequest
  ): Promise<AuditExtended> {
    const response = await this.auditsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update audit
   */
  async auditsIdPatchRaw(
    requestParameters: AuditsIdPatchRequest
  ): Promise<runtime.ApiResponse<AuditExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling auditsIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling auditsIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/audits/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: AuditUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AuditExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update audit
   */
  async auditsIdPatch(
    requestParameters: AuditsIdPatchRequest
  ): Promise<AuditExtended> {
    const response = await this.auditsIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get audit preview pdf
   */
  async auditsIdPreviewGetRaw(
    requestParameters: AuditsIdPreviewGetRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling auditsIdPreviewGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/audits/{id}/preview`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Get audit preview pdf
   */
  async auditsIdPreviewGet(
    requestParameters: AuditsIdPreviewGetRequest
  ): Promise<void> {
    await this.auditsIdPreviewGetRaw(requestParameters);
  }

  /**
   * Delete business function
   */
  async businessFunctionsIdDeleteRaw(
    requestParameters: BusinessFunctionsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling businessFunctionsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/business_functions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete business function
   */
  async businessFunctionsIdDelete(
    requestParameters: BusinessFunctionsIdDeleteRequest
  ): Promise<void> {
    await this.businessFunctionsIdDeleteRaw(requestParameters);
  }

  /**
   * Get a specific business function
   */
  async businessFunctionsIdGetRaw(
    requestParameters: BusinessFunctionsIdGetRequest
  ): Promise<runtime.ApiResponse<BusinessFunctionModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling businessFunctionsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/business_functions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BusinessFunctionModelFromJSON(jsonValue)
    );
  }

  /**
   * Get a specific business function
   */
  async businessFunctionsIdGet(
    requestParameters: BusinessFunctionsIdGetRequest
  ): Promise<BusinessFunctionModel> {
    const response = await this.businessFunctionsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update a specific business function
   */
  async businessFunctionsIdPatchRaw(
    requestParameters: BusinessFunctionsIdPatchRequest
  ): Promise<runtime.ApiResponse<BusinessFunctionModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling businessFunctionsIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling businessFunctionsIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/business_functions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: BusinessFunctionUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BusinessFunctionModelFromJSON(jsonValue)
    );
  }

  /**
   * Update a specific business function
   */
  async businessFunctionsIdPatch(
    requestParameters: BusinessFunctionsIdPatchRequest
  ): Promise<BusinessFunctionModel> {
    const response = await this.businessFunctionsIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete certificate
   */
  async certificatesIdDeleteRaw(
    requestParameters: CertificatesIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling certificatesIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/certificates/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete certificate
   */
  async certificatesIdDelete(
    requestParameters: CertificatesIdDeleteRequest
  ): Promise<void> {
    await this.certificatesIdDeleteRaw(requestParameters);
  }

  /**
   * Get certificate pdf
   */
  async certificatesIdDocumentGetRaw(
    requestParameters: CertificatesIdDocumentGetRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling certificatesIdDocumentGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/certificates/{id}/document`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Get certificate pdf
   */
  async certificatesIdDocumentGet(
    requestParameters: CertificatesIdDocumentGetRequest
  ): Promise<void> {
    await this.certificatesIdDocumentGetRaw(requestParameters);
  }

  /**
   * Get details for one certificate
   */
  async certificatesIdGetRaw(
    requestParameters: CertificatesIdGetRequest
  ): Promise<runtime.ApiResponse<CertificateModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling certificatesIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/certificates/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CertificateModelFromJSON(jsonValue)
    );
  }

  /**
   * Get details for one certificate
   */
  async certificatesIdGet(
    requestParameters: CertificatesIdGetRequest
  ): Promise<CertificateModel> {
    const response = await this.certificatesIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get checklist template
   */
  async checklistTemplatesIdGetRaw(
    requestParameters: ChecklistTemplatesIdGetRequest
  ): Promise<runtime.ApiResponse<ChecklistTemplateExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling checklistTemplatesIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/checklist_templates/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ChecklistTemplateExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get checklist template
   */
  async checklistTemplatesIdGet(
    requestParameters: ChecklistTemplatesIdGetRequest
  ): Promise<ChecklistTemplateExtended> {
    const response = await this.checklistTemplatesIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update checklist template
   */
  async checklistTemplatesIdPatchRaw(
    requestParameters: ChecklistTemplatesIdPatchRequest
  ): Promise<runtime.ApiResponse<ChecklistTemplateExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling checklistTemplatesIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling checklistTemplatesIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/checklist_templates/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: ChecklistTemplateUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ChecklistTemplateExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update checklist template
   */
  async checklistTemplatesIdPatch(
    requestParameters: ChecklistTemplatesIdPatchRequest
  ): Promise<ChecklistTemplateExtended> {
    const response = await this.checklistTemplatesIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Checklist update status
   */
  async checklistsIdChangeStatusPatchRaw(
    requestParameters: ChecklistsIdChangeStatusPatchRequest
  ): Promise<runtime.ApiResponse<ChecklistExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling checklistsIdChangeStatusPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling checklistsIdChangeStatusPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/checklists/{id}/change_status`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: ChecklistChangedStatusPayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ChecklistExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Checklist update status
   */
  async checklistsIdChangeStatusPatch(
    requestParameters: ChecklistsIdChangeStatusPatchRequest
  ): Promise<ChecklistExtended> {
    const response =
      await this.checklistsIdChangeStatusPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a checklist
   */
  async checklistsIdGetRaw(
    requestParameters: ChecklistsIdGetRequest
  ): Promise<runtime.ApiResponse<ChecklistExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling checklistsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/checklists/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ChecklistExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get a checklist
   */
  async checklistsIdGet(
    requestParameters: ChecklistsIdGetRequest
  ): Promise<ChecklistExtended> {
    const response = await this.checklistsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update checklist
   */
  async checklistsIdPatchRaw(
    requestParameters: ChecklistsIdPatchRequest
  ): Promise<runtime.ApiResponse<ChecklistExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling checklistsIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling checklistsIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/checklists/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: ChecklistUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ChecklistExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update checklist
   */
  async checklistsIdPatch(
    requestParameters: ChecklistsIdPatchRequest
  ): Promise<ChecklistExtended> {
    const response = await this.checklistsIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get company identifiers
   */
  async companiesIdCompanyIdentifiersGetRaw(
    requestParameters: CompaniesIdCompanyIdentifiersGetRequest
  ): Promise<runtime.ApiResponse<Array<CompanyIdentifierModel>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling companiesIdCompanyIdentifiersGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/companies/{id}/company_identifiers`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(CompanyIdentifierModelFromJSON)
    );
  }

  /**
   * Get company identifiers
   */
  async companiesIdCompanyIdentifiersGet(
    requestParameters: CompaniesIdCompanyIdentifiersGetRequest
  ): Promise<Array<CompanyIdentifierModel>> {
    const response =
      await this.companiesIdCompanyIdentifiersGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create company identifier
   */
  async companiesIdCompanyIdentifiersPostRaw(
    requestParameters: CompaniesIdCompanyIdentifiersPostRequest
  ): Promise<runtime.ApiResponse<CompanyIdentifierModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling companiesIdCompanyIdentifiersPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling companiesIdCompanyIdentifiersPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/companies/{id}/company_identifiers`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CompanyIdentifierCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CompanyIdentifierModelFromJSON(jsonValue)
    );
  }

  /**
   * Create company identifier
   */
  async companiesIdCompanyIdentifiersPost(
    requestParameters: CompaniesIdCompanyIdentifiersPostRequest
  ): Promise<CompanyIdentifierModel> {
    const response =
      await this.companiesIdCompanyIdentifiersPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get company
   */
  async companiesIdGetRaw(
    requestParameters: CompaniesIdGetRequest
  ): Promise<runtime.ApiResponse<CompanyExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling companiesIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/companies/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CompanyExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get company
   */
  async companiesIdGet(
    requestParameters: CompaniesIdGetRequest
  ): Promise<CompanyExtended> {
    const response = await this.companiesIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete the office of a particular company
   */
  async companiesIdOfficesOfficeIdDeleteRaw(
    requestParameters: CompaniesIdOfficesOfficeIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling companiesIdOfficesOfficeIdDelete.'
      );
    }

    if (
      requestParameters.officeId === null ||
      requestParameters.officeId === undefined
    ) {
      throw new runtime.RequiredError(
        'officeId',
        'Required parameter requestParameters.officeId was null or undefined when calling companiesIdOfficesOfficeIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/companies/{id}/offices/{office_id}`
        .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id)))
        .replace(
          `{${'office_id'}}`,
          encodeURIComponent(String(requestParameters.officeId))
        ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete the office of a particular company
   */
  async companiesIdOfficesOfficeIdDelete(
    requestParameters: CompaniesIdOfficesOfficeIdDeleteRequest
  ): Promise<void> {
    await this.companiesIdOfficesOfficeIdDeleteRaw(requestParameters);
  }

  /**
   * Update the details for a particular company
   */
  async companiesIdPatchRaw(
    requestParameters: CompaniesIdPatchRequest
  ): Promise<runtime.ApiResponse<CompanyExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling companiesIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling companiesIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/companies/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: CompanyUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CompanyExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update the details for a particular company
   */
  async companiesIdPatch(
    requestParameters: CompaniesIdPatchRequest
  ): Promise<CompanyExtended> {
    const response = await this.companiesIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Validate new company data
   */
  async companiesValidatePostRaw(
    requestParameters: CompaniesValidatePostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling companiesValidatePost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling companiesValidatePost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/companies/validate`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CompanyToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Validate new company data
   */
  async companiesValidatePost(
    requestParameters: CompaniesValidatePostRequest
  ): Promise<void> {
    await this.companiesValidatePostRaw(requestParameters);
  }

  /**
   * Delete company identifier
   */
  async companyIdentifiersIdDeleteRaw(
    requestParameters: CompanyIdentifiersIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling companyIdentifiersIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/company_identifiers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete company identifier
   */
  async companyIdentifiersIdDelete(
    requestParameters: CompanyIdentifiersIdDeleteRequest
  ): Promise<void> {
    await this.companyIdentifiersIdDeleteRaw(requestParameters);
  }

  /**
   * Get company identifier
   */
  async companyIdentifiersIdGetRaw(
    requestParameters: CompanyIdentifiersIdGetRequest
  ): Promise<runtime.ApiResponse<CompanyIdentifierModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling companyIdentifiersIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/company_identifiers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CompanyIdentifierModelFromJSON(jsonValue)
    );
  }

  /**
   * Get company identifier
   */
  async companyIdentifiersIdGet(
    requestParameters: CompanyIdentifiersIdGetRequest
  ): Promise<CompanyIdentifierModel> {
    const response = await this.companyIdentifiersIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update company identifier
   */
  async companyIdentifiersIdPatchRaw(
    requestParameters: CompanyIdentifiersIdPatchRequest
  ): Promise<runtime.ApiResponse<CompanyIdentifierModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling companyIdentifiersIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling companyIdentifiersIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/company_identifiers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: CompanyIdentifierUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CompanyIdentifierModelFromJSON(jsonValue)
    );
  }

  /**
   * Update company identifier
   */
  async companyIdentifiersIdPatch(
    requestParameters: CompanyIdentifiersIdPatchRequest
  ): Promise<CompanyIdentifierModel> {
    const response = await this.companyIdentifiersIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get identifiers types
   */
  async companyIdentifiersTypesGetRaw(): Promise<
    runtime.ApiResponse<Array<CompanyIdentifierType>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/company_identifiers/types`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(CompanyIdentifierTypeFromJSON)
    );
  }

  /**
   * Get identifiers types
   */
  async companyIdentifiersTypesGet(): Promise<Array<CompanyIdentifierType>> {
    const response = await this.companyIdentifiersTypesGetRaw();
    return await response.value();
  }

  /**
   * Get config
   */
  async configsConfigIdGetRaw(
    requestParameters: ConfigsConfigIdGetRequest
  ): Promise<runtime.ApiResponse<Array<object>>> {
    if (
      requestParameters.configId === null ||
      requestParameters.configId === undefined
    ) {
      throw new runtime.RequiredError(
        'configId',
        'Required parameter requestParameters.configId was null or undefined when calling configsConfigIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/configs/{config_id}`.replace(
        `{${'config_id'}}`,
        encodeURIComponent(String(requestParameters.configId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Get config
   */
  async configsConfigIdGet(
    requestParameters: ConfigsConfigIdGetRequest
  ): Promise<Array<object>> {
    const response = await this.configsConfigIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get control
   */
  async controlsControlIdGetRaw(
    requestParameters: ControlsControlIdGetRequest
  ): Promise<runtime.ApiResponse<ControlExtended>> {
    if (
      requestParameters.controlId === null ||
      requestParameters.controlId === undefined
    ) {
      throw new runtime.RequiredError(
        'controlId',
        'Required parameter requestParameters.controlId was null or undefined when calling controlsControlIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.vendorId !== undefined) {
      queryParameters['vendor_id'] = requestParameters.vendorId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/controls/{control_id}`.replace(
        `{${'control_id'}}`,
        encodeURIComponent(String(requestParameters.controlId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ControlExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get control
   */
  async controlsControlIdGet(
    requestParameters: ControlsControlIdGetRequest
  ): Promise<ControlExtended> {
    const response = await this.controlsControlIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update control
   */
  async controlsControlIdPatchRaw(
    requestParameters: ControlsControlIdPatchRequest
  ): Promise<runtime.ApiResponse<ControlExtended>> {
    if (
      requestParameters.controlId === null ||
      requestParameters.controlId === undefined
    ) {
      throw new runtime.RequiredError(
        'controlId',
        'Required parameter requestParameters.controlId was null or undefined when calling controlsControlIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling controlsControlIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/controls/{control_id}`.replace(
        `{${'control_id'}}`,
        encodeURIComponent(String(requestParameters.controlId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: ControlUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ControlExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update control
   */
  async controlsControlIdPatch(
    requestParameters: ControlsControlIdPatchRequest
  ): Promise<ControlExtended> {
    const response = await this.controlsControlIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Remove reference
   */
  async controlsControlIdReferencesDeleteRaw(
    requestParameters: ControlsControlIdReferencesDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.controlId === null ||
      requestParameters.controlId === undefined
    ) {
      throw new runtime.RequiredError(
        'controlId',
        'Required parameter requestParameters.controlId was null or undefined when calling controlsControlIdReferencesDelete.'
      );
    }

    if (
      requestParameters.referenceableId === null ||
      requestParameters.referenceableId === undefined
    ) {
      throw new runtime.RequiredError(
        'referenceableId',
        'Required parameter requestParameters.referenceableId was null or undefined when calling controlsControlIdReferencesDelete.'
      );
    }

    if (
      requestParameters.referenceableType === null ||
      requestParameters.referenceableType === undefined
    ) {
      throw new runtime.RequiredError(
        'referenceableType',
        'Required parameter requestParameters.referenceableType was null or undefined when calling controlsControlIdReferencesDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.referenceableId !== undefined) {
      queryParameters['referenceable_id'] = requestParameters.referenceableId;
    }

    if (requestParameters.referenceableType !== undefined) {
      queryParameters['referenceable_type'] =
        requestParameters.referenceableType;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/controls/{control_id}/references`.replace(
        `{${'control_id'}}`,
        encodeURIComponent(String(requestParameters.controlId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Remove reference
   */
  async controlsControlIdReferencesDelete(
    requestParameters: ControlsControlIdReferencesDeleteRequest
  ): Promise<void> {
    await this.controlsControlIdReferencesDeleteRaw(requestParameters);
  }

  /**
   * Create reference
   */
  async controlsControlIdReferencesPostRaw(
    requestParameters: ControlsControlIdReferencesPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.controlId === null ||
      requestParameters.controlId === undefined
    ) {
      throw new runtime.RequiredError(
        'controlId',
        'Required parameter requestParameters.controlId was null or undefined when calling controlsControlIdReferencesPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling controlsControlIdReferencesPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/controls/{control_id}/references`.replace(
        `{${'control_id'}}`,
        encodeURIComponent(String(requestParameters.controlId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ReferenceCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create reference
   */
  async controlsControlIdReferencesPost(
    requestParameters: ControlsControlIdReferencesPostRequest
  ): Promise<void> {
    await this.controlsControlIdReferencesPostRaw(requestParameters);
  }

  /**
   * Update control status
   */
  async controlsControlIdStatusPatchRaw(
    requestParameters: ControlsControlIdStatusPatchRequest
  ): Promise<runtime.ApiResponse<ControlExtended>> {
    if (
      requestParameters.controlId === null ||
      requestParameters.controlId === undefined
    ) {
      throw new runtime.RequiredError(
        'controlId',
        'Required parameter requestParameters.controlId was null or undefined when calling controlsControlIdStatusPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling controlsControlIdStatusPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/controls/{control_id}/status`.replace(
        `{${'control_id'}}`,
        encodeURIComponent(String(requestParameters.controlId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: ControlUpdateStatusPayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ControlExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update control status
   */
  async controlsControlIdStatusPatch(
    requestParameters: ControlsControlIdStatusPatchRequest
  ): Promise<ControlExtended> {
    const response =
      await this.controlsControlIdStatusPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete action
   */
  async correctiveActionsCorrectiveActionIdDeleteRaw(
    requestParameters: CorrectiveActionsCorrectiveActionIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.correctiveActionId === null ||
      requestParameters.correctiveActionId === undefined
    ) {
      throw new runtime.RequiredError(
        'correctiveActionId',
        'Required parameter requestParameters.correctiveActionId was null or undefined when calling correctiveActionsCorrectiveActionIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/corrective_actions/{corrective_action_id}`.replace(
        `{${'corrective_action_id'}}`,
        encodeURIComponent(String(requestParameters.correctiveActionId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete action
   */
  async correctiveActionsCorrectiveActionIdDelete(
    requestParameters: CorrectiveActionsCorrectiveActionIdDeleteRequest
  ): Promise<void> {
    await this.correctiveActionsCorrectiveActionIdDeleteRaw(requestParameters);
  }

  /**
   * Get action
   */
  async correctiveActionsCorrectiveActionIdGetRaw(
    requestParameters: CorrectiveActionsCorrectiveActionIdGetRequest
  ): Promise<runtime.ApiResponse<CorrectiveActionExtended>> {
    if (
      requestParameters.correctiveActionId === null ||
      requestParameters.correctiveActionId === undefined
    ) {
      throw new runtime.RequiredError(
        'correctiveActionId',
        'Required parameter requestParameters.correctiveActionId was null or undefined when calling correctiveActionsCorrectiveActionIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/corrective_actions/{corrective_action_id}`.replace(
        `{${'corrective_action_id'}}`,
        encodeURIComponent(String(requestParameters.correctiveActionId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CorrectiveActionExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get action
   */
  async correctiveActionsCorrectiveActionIdGet(
    requestParameters: CorrectiveActionsCorrectiveActionIdGetRequest
  ): Promise<CorrectiveActionExtended> {
    const response =
      await this.correctiveActionsCorrectiveActionIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update action
   */
  async correctiveActionsCorrectiveActionIdPatchRaw(
    requestParameters: CorrectiveActionsCorrectiveActionIdPatchRequest
  ): Promise<runtime.ApiResponse<CorrectiveActionExtended>> {
    if (
      requestParameters.correctiveActionId === null ||
      requestParameters.correctiveActionId === undefined
    ) {
      throw new runtime.RequiredError(
        'correctiveActionId',
        'Required parameter requestParameters.correctiveActionId was null or undefined when calling correctiveActionsCorrectiveActionIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.correctiveActionName !== undefined) {
      formParams.append(
        'corrective_action[name]',
        requestParameters.correctiveActionName as any
      );
    }

    if (requestParameters.correctiveActionDescription !== undefined) {
      formParams.append(
        'corrective_action[description]',
        requestParameters.correctiveActionDescription as any
      );
    }

    if (requestParameters.correctiveActionIdentifier !== undefined) {
      formParams.append(
        'corrective_action[identifier]',
        requestParameters.correctiveActionIdentifier as any
      );
    }

    if (requestParameters.correctiveActionDateIdentified !== undefined) {
      formParams.append(
        'corrective_action[date_identified]',
        requestParameters.correctiveActionDateIdentified as any
      );
    }

    if (requestParameters.correctiveActionRaisedBy !== undefined) {
      formParams.append(
        'corrective_action[raised_by]',
        requestParameters.correctiveActionRaisedBy as any
      );
    }

    if (requestParameters.correctiveActionRootCause !== undefined) {
      formParams.append(
        'corrective_action[root_cause]',
        requestParameters.correctiveActionRootCause as any
      );
    }

    if (requestParameters.correctiveActionOwnerId !== undefined) {
      formParams.append(
        'corrective_action[owner_id]',
        requestParameters.correctiveActionOwnerId as any
      );
    }

    if (requestParameters.correctiveActionCompleted !== undefined) {
      formParams.append(
        'corrective_action[completed]',
        requestParameters.correctiveActionCompleted as any
      );
    }

    if (requestParameters.correctiveActionUrl !== undefined) {
      formParams.append(
        'corrective_action[url]',
        requestParameters.correctiveActionUrl as any
      );
    }

    if (requestParameters.correctiveActionAttachments) {
      formParams.append(
        'corrective_action[attachments]',
        requestParameters.correctiveActionAttachments.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    if (requestParameters.correctiveActionDeletedAttachmentIds) {
      formParams.append(
        'corrective_action[deleted_attachment_ids]',
        requestParameters.correctiveActionDeletedAttachmentIds.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    if (requestParameters.correctiveActionActionType !== undefined) {
      formParams.append(
        'corrective_action[action_type]',
        requestParameters.correctiveActionActionType as any
      );
    }

    if (requestParameters.correctiveActionCorrectiveActions !== undefined) {
      formParams.append(
        'corrective_action[corrective_actions]',
        requestParameters.correctiveActionCorrectiveActions as any
      );
    }

    const response = await this.request({
      path: `/corrective_actions/{corrective_action_id}`.replace(
        `{${'corrective_action_id'}}`,
        encodeURIComponent(String(requestParameters.correctiveActionId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CorrectiveActionExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update action
   */
  async correctiveActionsCorrectiveActionIdPatch(
    requestParameters: CorrectiveActionsCorrectiveActionIdPatchRequest
  ): Promise<CorrectiveActionExtended> {
    const response =
      await this.correctiveActionsCorrectiveActionIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create subscription after Stripe checkout from Stripe session
   */
  async createRecurringPaymentGetRaw(
    requestParameters: CreateRecurringPaymentGetRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.purchasableType === null ||
      requestParameters.purchasableType === undefined
    ) {
      throw new runtime.RequiredError(
        'purchasableType',
        'Required parameter requestParameters.purchasableType was null or undefined when calling createRecurringPaymentGet.'
      );
    }

    if (
      requestParameters.purchasableId === null ||
      requestParameters.purchasableId === undefined
    ) {
      throw new runtime.RequiredError(
        'purchasableId',
        'Required parameter requestParameters.purchasableId was null or undefined when calling createRecurringPaymentGet.'
      );
    }

    if (
      requestParameters.paymentPeriod === null ||
      requestParameters.paymentPeriod === undefined
    ) {
      throw new runtime.RequiredError(
        'paymentPeriod',
        'Required parameter requestParameters.paymentPeriod was null or undefined when calling createRecurringPaymentGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.paymentPeriod !== undefined) {
      queryParameters['payment_period'] = requestParameters.paymentPeriod;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/create_recurring_payment`
        .replace(
          `{${'purchasable_type'}}`,
          encodeURIComponent(String(requestParameters.purchasableType))
        )
        .replace(
          `{${'purchasable_id'}}`,
          encodeURIComponent(String(requestParameters.purchasableId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create subscription after Stripe checkout from Stripe session
   */
  async createRecurringPaymentGet(
    requestParameters: CreateRecurringPaymentGetRequest
  ): Promise<void> {
    await this.createRecurringPaymentGetRaw(requestParameters);
  }

  /**
   * Create recurring_payment stripe checkout
   */
  async createStripeRecurringPaymentPostRaw(
    requestParameters: CreateStripeRecurringPaymentPostRequest
  ): Promise<runtime.ApiResponse<RecurringPaymentCheckoutModel>> {
    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling createStripeRecurringPaymentPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/create_stripe_recurring_payment`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: RecurringPaymentCheckoutCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RecurringPaymentCheckoutModelFromJSON(jsonValue)
    );
  }

  /**
   * Create recurring_payment stripe checkout
   */
  async createStripeRecurringPaymentPost(
    requestParameters: CreateStripeRecurringPaymentPostRequest
  ): Promise<RecurringPaymentCheckoutModel> {
    const response =
      await this.createStripeRecurringPaymentPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create subscription payment stripe checkout
   */
  async createStripeSessionPostRaw(
    requestParameters: CreateStripeSessionPostRequest
  ): Promise<runtime.ApiResponse<PaymentPlanVendorCheckoutModel>> {
    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling createStripeSessionPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/create_stripe_session`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: PaymentPlanVendorCheckoutCreatePayloadToJSON(
        requestParameters.body
      ),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      PaymentPlanVendorCheckoutModelFromJSON(jsonValue)
    );
  }

  /**
   * Create subscription payment stripe checkout
   */
  async createStripeSessionPost(
    requestParameters: CreateStripeSessionPostRequest
  ): Promise<PaymentPlanVendorCheckoutModel> {
    const response = await this.createStripeSessionPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update entity
   */
  async dataRoomEntitiesEntityIdPatchRaw(
    requestParameters: DataRoomEntitiesEntityIdPatchRequest
  ): Promise<runtime.ApiResponse<DataRoomEntityModel>> {
    if (
      requestParameters.entityId === null ||
      requestParameters.entityId === undefined
    ) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling dataRoomEntitiesEntityIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling dataRoomEntitiesEntityIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/data_room/entities/{entity_id}`.replace(
        `{${'entity_id'}}`,
        encodeURIComponent(String(requestParameters.entityId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: DataRoomEntityUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      DataRoomEntityModelFromJSON(jsonValue)
    );
  }

  /**
   * Update entity
   */
  async dataRoomEntitiesEntityIdPatch(
    requestParameters: DataRoomEntitiesEntityIdPatchRequest
  ): Promise<DataRoomEntityModel> {
    const response =
      await this.dataRoomEntitiesEntityIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Data Room file with attachment download link
   */
  async dataRoomFilesFileIdDownloadGetRaw(
    requestParameters: DataRoomFilesFileIdDownloadGetRequest
  ): Promise<runtime.ApiResponse<DataRoomEntityModel>> {
    if (
      requestParameters.fileId === null ||
      requestParameters.fileId === undefined
    ) {
      throw new runtime.RequiredError(
        'fileId',
        'Required parameter requestParameters.fileId was null or undefined when calling dataRoomFilesFileIdDownloadGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/data_room/files/{file_id}/download`.replace(
        `{${'file_id'}}`,
        encodeURIComponent(String(requestParameters.fileId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      DataRoomEntityModelFromJSON(jsonValue)
    );
  }

  /**
   * Get Data Room file with attachment download link
   */
  async dataRoomFilesFileIdDownloadGet(
    requestParameters: DataRoomFilesFileIdDownloadGetRequest
  ): Promise<DataRoomEntityModel> {
    const response =
      await this.dataRoomFilesFileIdDownloadGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete evidence (deprecated)
   */
  async evidencesEvidenceIdDeleteRaw(
    requestParameters: EvidencesEvidenceIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.evidenceId === null ||
      requestParameters.evidenceId === undefined
    ) {
      throw new runtime.RequiredError(
        'evidenceId',
        'Required parameter requestParameters.evidenceId was null or undefined when calling evidencesEvidenceIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/evidences/{evidence_id}`.replace(
        `{${'evidence_id'}}`,
        encodeURIComponent(String(requestParameters.evidenceId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete evidence (deprecated)
   */
  async evidencesEvidenceIdDelete(
    requestParameters: EvidencesEvidenceIdDeleteRequest
  ): Promise<void> {
    await this.evidencesEvidenceIdDeleteRaw(requestParameters);
  }

  /**
   * Get evidence data with attachment download link (deprecated)
   */
  async evidencesEvidenceIdDownloadGetRaw(
    requestParameters: EvidencesEvidenceIdDownloadGetRequest
  ): Promise<runtime.ApiResponse<EvidenceModel>> {
    if (
      requestParameters.evidenceId === null ||
      requestParameters.evidenceId === undefined
    ) {
      throw new runtime.RequiredError(
        'evidenceId',
        'Required parameter requestParameters.evidenceId was null or undefined when calling evidencesEvidenceIdDownloadGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/evidences/{evidence_id}/download`.replace(
        `{${'evidence_id'}}`,
        encodeURIComponent(String(requestParameters.evidenceId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      EvidenceModelFromJSON(jsonValue)
    );
  }

  /**
   * Get evidence data with attachment download link (deprecated)
   */
  async evidencesEvidenceIdDownloadGet(
    requestParameters: EvidencesEvidenceIdDownloadGetRequest
  ): Promise<EvidenceModel> {
    const response =
      await this.evidencesEvidenceIdDownloadGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get framework guidance
   */
  async frameworkGuidancesIdGetRaw(
    requestParameters: FrameworkGuidancesIdGetRequest
  ): Promise<runtime.ApiResponse<FrameworkGuidanceModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling frameworkGuidancesIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/framework_guidances/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      FrameworkGuidanceModelFromJSON(jsonValue)
    );
  }

  /**
   * Get framework guidance
   */
  async frameworkGuidancesIdGet(
    requestParameters: FrameworkGuidancesIdGetRequest
  ): Promise<FrameworkGuidanceModel> {
    const response = await this.frameworkGuidancesIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get framework templates
   */
  async frameworkTemplatesGetRaw(): Promise<
    runtime.ApiResponse<Array<FrameworkTemplateModel>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/framework_templates`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(FrameworkTemplateModelFromJSON)
    );
  }

  /**
   * Get framework templates
   */
  async frameworkTemplatesGet(): Promise<Array<FrameworkTemplateModel>> {
    const response = await this.frameworkTemplatesGetRaw();
    return await response.value();
  }

  /**
   * Get framework template
   */
  async frameworkTemplatesIdGetRaw(
    requestParameters: FrameworkTemplatesIdGetRequest
  ): Promise<runtime.ApiResponse<FrameworkTemplateExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling frameworkTemplatesIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/framework_templates/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      FrameworkTemplateExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get framework template
   */
  async frameworkTemplatesIdGet(
    requestParameters: FrameworkTemplatesIdGetRequest
  ): Promise<FrameworkTemplateExtended> {
    const response = await this.frameworkTemplatesIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get framework requirement categories
   */
  async frameworksFrameworkIdFrameworkRequirementCategoriesGetRaw(
    requestParameters: FrameworksFrameworkIdFrameworkRequirementCategoriesGetRequest
  ): Promise<runtime.ApiResponse<Array<FrameworkRequirementCategoryExtended>>> {
    if (
      requestParameters.frameworkId === null ||
      requestParameters.frameworkId === undefined
    ) {
      throw new runtime.RequiredError(
        'frameworkId',
        'Required parameter requestParameters.frameworkId was null or undefined when calling frameworksFrameworkIdFrameworkRequirementCategoriesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/frameworks/{framework_id}/framework_requirement_categories`.replace(
        `{${'framework_id'}}`,
        encodeURIComponent(String(requestParameters.frameworkId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(FrameworkRequirementCategoryExtendedFromJSON)
    );
  }

  /**
   * Get framework requirement categories
   */
  async frameworksFrameworkIdFrameworkRequirementCategoriesGet(
    requestParameters: FrameworksFrameworkIdFrameworkRequirementCategoriesGetRequest
  ): Promise<Array<FrameworkRequirementCategoryExtended>> {
    const response =
      await this.frameworksFrameworkIdFrameworkRequirementCategoriesGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Run evidence export
   */
  async frameworksIdExportEvidencePostRaw(
    requestParameters: FrameworksIdExportEvidencePostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling frameworksIdExportEvidencePost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling frameworksIdExportEvidencePost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/frameworks/{id}/export_evidence`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: FrameworkExportEvidencePayloadToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Run evidence export
   */
  async frameworksIdExportEvidencePost(
    requestParameters: FrameworksIdExportEvidencePostRequest
  ): Promise<void> {
    await this.frameworksIdExportEvidencePostRaw(requestParameters);
  }

  /**
   * Get framework
   */
  async frameworksIdGetRaw(
    requestParameters: FrameworksIdGetRequest
  ): Promise<runtime.ApiResponse<FrameworkExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling frameworksIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/frameworks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      FrameworkExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get framework
   */
  async frameworksIdGet(
    requestParameters: FrameworksIdGetRequest
  ): Promise<FrameworkExtended> {
    const response = await this.frameworksIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get framework policies
   */
  async frameworksIdPoliciesGetRaw(
    requestParameters: FrameworksIdPoliciesGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorDocument>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling frameworksIdPoliciesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/frameworks/{id}/policies`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorDocumentFromJSON)
    );
  }

  /**
   * Get framework policies
   */
  async frameworksIdPoliciesGet(
    requestParameters: FrameworksIdPoliciesGetRequest
  ): Promise<Array<VendorDocument>> {
    const response = await this.frameworksIdPoliciesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete action
   */
  async incidentEventsIncidentEventIdDeleteRaw(
    requestParameters: IncidentEventsIncidentEventIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.incidentEventId === null ||
      requestParameters.incidentEventId === undefined
    ) {
      throw new runtime.RequiredError(
        'incidentEventId',
        'Required parameter requestParameters.incidentEventId was null or undefined when calling incidentEventsIncidentEventIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/incident_events/{incident_event_id}`.replace(
        `{${'incident_event_id'}}`,
        encodeURIComponent(String(requestParameters.incidentEventId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete action
   */
  async incidentEventsIncidentEventIdDelete(
    requestParameters: IncidentEventsIncidentEventIdDeleteRequest
  ): Promise<void> {
    await this.incidentEventsIncidentEventIdDeleteRaw(requestParameters);
  }

  /**
   * Get action
   */
  async incidentEventsIncidentEventIdGetRaw(
    requestParameters: IncidentEventsIncidentEventIdGetRequest
  ): Promise<runtime.ApiResponse<IncidentEventExtended>> {
    if (
      requestParameters.incidentEventId === null ||
      requestParameters.incidentEventId === undefined
    ) {
      throw new runtime.RequiredError(
        'incidentEventId',
        'Required parameter requestParameters.incidentEventId was null or undefined when calling incidentEventsIncidentEventIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/incident_events/{incident_event_id}`.replace(
        `{${'incident_event_id'}}`,
        encodeURIComponent(String(requestParameters.incidentEventId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      IncidentEventExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get action
   */
  async incidentEventsIncidentEventIdGet(
    requestParameters: IncidentEventsIncidentEventIdGetRequest
  ): Promise<IncidentEventExtended> {
    const response =
      await this.incidentEventsIncidentEventIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update action
   */
  async incidentEventsIncidentEventIdPatchRaw(
    requestParameters: IncidentEventsIncidentEventIdPatchRequest
  ): Promise<runtime.ApiResponse<IncidentEventExtended>> {
    if (
      requestParameters.incidentEventId === null ||
      requestParameters.incidentEventId === undefined
    ) {
      throw new runtime.RequiredError(
        'incidentEventId',
        'Required parameter requestParameters.incidentEventId was null or undefined when calling incidentEventsIncidentEventIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.incidentEventName !== undefined) {
      formParams.append(
        'incident_event[name]',
        requestParameters.incidentEventName as any
      );
    }

    if (requestParameters.incidentEventDescription !== undefined) {
      formParams.append(
        'incident_event[description]',
        requestParameters.incidentEventDescription as any
      );
    }

    if (requestParameters.incidentEventIdentifier !== undefined) {
      formParams.append(
        'incident_event[identifier]',
        requestParameters.incidentEventIdentifier as any
      );
    }

    if (requestParameters.incidentEventDateIdentified !== undefined) {
      formParams.append(
        'incident_event[date_identified]',
        requestParameters.incidentEventDateIdentified as any
      );
    }

    if (requestParameters.incidentEventRaisedBy !== undefined) {
      formParams.append(
        'incident_event[raised_by]',
        requestParameters.incidentEventRaisedBy as any
      );
    }

    if (requestParameters.incidentEventRootCause !== undefined) {
      formParams.append(
        'incident_event[root_cause]',
        requestParameters.incidentEventRootCause as any
      );
    }

    if (requestParameters.incidentEventOwnerId !== undefined) {
      formParams.append(
        'incident_event[owner_id]',
        requestParameters.incidentEventOwnerId as any
      );
    }

    if (requestParameters.incidentEventCompleted !== undefined) {
      formParams.append(
        'incident_event[completed]',
        requestParameters.incidentEventCompleted as any
      );
    }

    if (requestParameters.incidentEventUrl !== undefined) {
      formParams.append(
        'incident_event[url]',
        requestParameters.incidentEventUrl as any
      );
    }

    if (requestParameters.incidentEventAttachments) {
      formParams.append(
        'incident_event[attachments]',
        requestParameters.incidentEventAttachments.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    if (requestParameters.incidentEventDeletedAttachmentIds) {
      formParams.append(
        'incident_event[deleted_attachment_ids]',
        requestParameters.incidentEventDeletedAttachmentIds.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    if (requestParameters.incidentEventPreventativeActions !== undefined) {
      formParams.append(
        'incident_event[preventative_actions]',
        requestParameters.incidentEventPreventativeActions as any
      );
    }

    if (requestParameters.incidentEventActionsTaken !== undefined) {
      formParams.append(
        'incident_event[actions_taken]',
        requestParameters.incidentEventActionsTaken as any
      );
    }

    if (requestParameters.incidentEventDataBreach !== undefined) {
      formParams.append(
        'incident_event[data_breach]',
        requestParameters.incidentEventDataBreach as any
      );
    }

    if (requestParameters.incidentEventDataBreachDetails !== undefined) {
      formParams.append(
        'incident_event[data_breach_details]',
        requestParameters.incidentEventDataBreachDetails as any
      );
    }

    const response = await this.request({
      path: `/incident_events/{incident_event_id}`.replace(
        `{${'incident_event_id'}}`,
        encodeURIComponent(String(requestParameters.incidentEventId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      IncidentEventExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update action
   */
  async incidentEventsIncidentEventIdPatch(
    requestParameters: IncidentEventsIncidentEventIdPatchRequest
  ): Promise<IncidentEventExtended> {
    const response =
      await this.incidentEventsIncidentEventIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get jurisdictions
   */
  async jurisdictionsGetRaw(): Promise<
    runtime.ApiResponse<Array<JurisdictionModel>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/jurisdictions`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(JurisdictionModelFromJSON)
    );
  }

  /**
   * Get jurisdictions
   */
  async jurisdictionsGet(): Promise<Array<JurisdictionModel>> {
    const response = await this.jurisdictionsGetRaw();
    return await response.value();
  }

  /**
   * Get legislation Classifications
   */
  async legislationClassificationsGetRaw(): Promise<
    runtime.ApiResponse<Array<LegislationClassificationModel>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/legislation_classifications`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(LegislationClassificationModelFromJSON)
    );
  }

  /**
   * Get legislation Classifications
   */
  async legislationClassificationsGet(): Promise<
    Array<LegislationClassificationModel>
  > {
    const response = await this.legislationClassificationsGetRaw();
    return await response.value();
  }

  /**
   * Get legislation templates
   */
  async legislationTemplatesGetRaw(): Promise<
    runtime.ApiResponse<Array<LegislationTemplateModel>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/legislation_templates`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(LegislationTemplateModelFromJSON)
    );
  }

  /**
   * Get legislation templates
   */
  async legislationTemplatesGet(): Promise<Array<LegislationTemplateModel>> {
    const response = await this.legislationTemplatesGetRaw();
    return await response.value();
  }

  /**
   * Delete legislation
   */
  async legislationsIdDeleteRaw(
    requestParameters: LegislationsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling legislationsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/legislations/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete legislation
   */
  async legislationsIdDelete(
    requestParameters: LegislationsIdDeleteRequest
  ): Promise<void> {
    await this.legislationsIdDeleteRaw(requestParameters);
  }

  /**
   * Get legislation
   */
  async legislationsIdGetRaw(
    requestParameters: LegislationsIdGetRequest
  ): Promise<runtime.ApiResponse<LegislationExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling legislationsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/legislations/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      LegislationExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get legislation
   */
  async legislationsIdGet(
    requestParameters: LegislationsIdGetRequest
  ): Promise<LegislationExtended> {
    const response = await this.legislationsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update legislation
   */
  async legislationsIdPatchRaw(
    requestParameters: LegislationsIdPatchRequest
  ): Promise<runtime.ApiResponse<LegislationExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling legislationsIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling legislationsIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/legislations/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: LegislationUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      LegislationExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update legislation
   */
  async legislationsIdPatch(
    requestParameters: LegislationsIdPatchRequest
  ): Promise<LegislationExtended> {
    const response = await this.legislationsIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Mark notification as read
   */
  async notificationsNotificationIdReadPatchRaw(
    requestParameters: NotificationsNotificationIdReadPatchRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.notificationId === null ||
      requestParameters.notificationId === undefined
    ) {
      throw new runtime.RequiredError(
        'notificationId',
        'Required parameter requestParameters.notificationId was null or undefined when calling notificationsNotificationIdReadPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/notifications/{notification_id}/read`.replace(
        `{${'notification_id'}}`,
        encodeURIComponent(String(requestParameters.notificationId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Mark notification as read
   */
  async notificationsNotificationIdReadPatch(
    requestParameters: NotificationsNotificationIdReadPatchRequest
  ): Promise<void> {
    await this.notificationsNotificationIdReadPatchRaw(requestParameters);
  }

  /**
   * Get an array of parsed emails
   */
  async parseEmailsPostRaw(
    requestParameters: ParseEmailsPostRequest
  ): Promise<runtime.ApiResponse<EmailArray>> {
    if (
      requestParameters.email === null ||
      requestParameters.email === undefined
    ) {
      throw new runtime.RequiredError(
        'email',
        'Required parameter requestParameters.email was null or undefined when calling parseEmailsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/parse_emails`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: EmailTextToJSON(requestParameters.email),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      EmailArrayFromJSON(jsonValue)
    );
  }

  /**
   * Get an array of parsed emails
   */
  async parseEmailsPost(
    requestParameters: ParseEmailsPostRequest
  ): Promise<EmailArray> {
    const response = await this.parseEmailsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all available payment methods
   */
  async paymentMethodsGetRaw(): Promise<
    runtime.ApiResponse<Array<PaymentMethod>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/payment_methods`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(PaymentMethodFromJSON)
    );
  }

  /**
   * Get the list of all available payment methods
   */
  async paymentMethodsGet(): Promise<Array<PaymentMethod>> {
    const response = await this.paymentMethodsGetRaw();
    return await response.value();
  }

  /**
   * Delete one payment method
   */
  async paymentMethodsIdDeleteRaw(
    requestParameters: PaymentMethodsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling paymentMethodsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/payment_methods/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete one payment method
   */
  async paymentMethodsIdDelete(
    requestParameters: PaymentMethodsIdDeleteRequest
  ): Promise<void> {
    await this.paymentMethodsIdDeleteRaw(requestParameters);
  }

  /**
   * Get the details of one payment method
   */
  async paymentMethodsIdGetRaw(
    requestParameters: PaymentMethodsIdGetRequest
  ): Promise<runtime.ApiResponse<PaymentMethod>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling paymentMethodsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/payment_methods/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      PaymentMethodFromJSON(jsonValue)
    );
  }

  /**
   * Get the details of one payment method
   */
  async paymentMethodsIdGet(
    requestParameters: PaymentMethodsIdGetRequest
  ): Promise<PaymentMethod> {
    const response = await this.paymentMethodsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update payment method
   */
  async paymentMethodsIdPatchRaw(
    requestParameters: PaymentMethodsIdPatchRequest
  ): Promise<runtime.ApiResponse<PaymentMethod>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling paymentMethodsIdPatch.'
      );
    }

    if (
      requestParameters.card === null ||
      requestParameters.card === undefined
    ) {
      throw new runtime.RequiredError(
        'card',
        'Required parameter requestParameters.card was null or undefined when calling paymentMethodsIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/payment_methods/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: PaymentMethodUpdatePayloadToJSON(requestParameters.card),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      PaymentMethodFromJSON(jsonValue)
    );
  }

  /**
   * Update payment method
   */
  async paymentMethodsIdPatch(
    requestParameters: PaymentMethodsIdPatchRequest
  ): Promise<PaymentMethod> {
    const response = await this.paymentMethodsIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create a new payment method
   */
  async paymentMethodsPostRaw(
    requestParameters: PaymentMethodsPostRequest
  ): Promise<runtime.ApiResponse<PaymentMethod>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/payment_methods`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CardToJSON(requestParameters.card),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      PaymentMethodFromJSON(jsonValue)
    );
  }

  /**
   * Create a new payment method
   */
  async paymentMethodsPost(
    requestParameters: PaymentMethodsPostRequest
  ): Promise<PaymentMethod> {
    const response = await this.paymentMethodsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create subscription after Stripe checkout from Stripe session
   */
  async paymentPlanVendorsGetRaw(
    requestParameters: PaymentPlanVendorsGetRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.paymentPlanId === null ||
      requestParameters.paymentPlanId === undefined
    ) {
      throw new runtime.RequiredError(
        'paymentPlanId',
        'Required parameter requestParameters.paymentPlanId was null or undefined when calling paymentPlanVendorsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/payment_plan_vendors`.replace(
        `{${'payment_plan_id'}}`,
        encodeURIComponent(String(requestParameters.paymentPlanId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create subscription after Stripe checkout from Stripe session
   */
  async paymentPlanVendorsGet(
    requestParameters: PaymentPlanVendorsGetRequest
  ): Promise<void> {
    await this.paymentPlanVendorsGetRaw(requestParameters);
  }

  /**
   * Get active Payment Plans
   */
  async paymentPlansGetRaw(): Promise<runtime.ApiResponse<Array<PaymentPlan>>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/payment_plans`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(PaymentPlanFromJSON)
    );
  }

  /**
   * Get active Payment Plans
   */
  async paymentPlansGet(): Promise<Array<PaymentPlan>> {
    const response = await this.paymentPlansGetRaw();
    return await response.value();
  }

  /**
   * Get actual price for payment plans
   */
  async paymentPlansPricingGetRaw(
    requestParameters: PaymentPlansPricingGetRequest
  ): Promise<runtime.ApiResponse<Array<PaymentPlanPrice>>> {
    if (
      requestParameters.usersLimit === null ||
      requestParameters.usersLimit === undefined
    ) {
      throw new runtime.RequiredError(
        'usersLimit',
        'Required parameter requestParameters.usersLimit was null or undefined when calling paymentPlansPricingGet.'
      );
    }

    if (
      requestParameters.paymentPeriod === null ||
      requestParameters.paymentPeriod === undefined
    ) {
      throw new runtime.RequiredError(
        'paymentPeriod',
        'Required parameter requestParameters.paymentPeriod was null or undefined when calling paymentPlansPricingGet.'
      );
    }

    if (
      requestParameters.paymentPlanIds === null ||
      requestParameters.paymentPlanIds === undefined
    ) {
      throw new runtime.RequiredError(
        'paymentPlanIds',
        'Required parameter requestParameters.paymentPlanIds was null or undefined when calling paymentPlansPricingGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.usersLimit !== undefined) {
      queryParameters['users_limit'] = requestParameters.usersLimit;
    }

    if (requestParameters.paymentPeriod !== undefined) {
      queryParameters['payment_period'] = requestParameters.paymentPeriod;
    }

    if (requestParameters.paymentPlanIds) {
      queryParameters['payment_plan_ids[]'] =
        requestParameters.paymentPlanIds.join(
          runtime.COLLECTION_FORMATS['csv']
        );
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/payment_plans/pricing`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(PaymentPlanPriceFromJSON)
    );
  }

  /**
   * Get actual price for payment plans
   */
  async paymentPlansPricingGet(
    requestParameters: PaymentPlansPricingGetRequest
  ): Promise<Array<PaymentPlanPrice>> {
    const response = await this.paymentPlansPricingGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete PESTEL item
   */
  async pestelItemsIdDeleteRaw(
    requestParameters: PestelItemsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling pestelItemsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/pestel_items/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete PESTEL item
   */
  async pestelItemsIdDelete(
    requestParameters: PestelItemsIdDeleteRequest
  ): Promise<void> {
    await this.pestelItemsIdDeleteRaw(requestParameters);
  }

  /**
   * Get PESTEL item
   */
  async pestelItemsIdGetRaw(
    requestParameters: PestelItemsIdGetRequest
  ): Promise<runtime.ApiResponse<PestelItemModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling pestelItemsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/pestel_items/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      PestelItemModelFromJSON(jsonValue)
    );
  }

  /**
   * Get PESTEL item
   */
  async pestelItemsIdGet(
    requestParameters: PestelItemsIdGetRequest
  ): Promise<PestelItemModel> {
    const response = await this.pestelItemsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update PESTEL item
   */
  async pestelItemsIdPatchRaw(
    requestParameters: PestelItemsIdPatchRequest
  ): Promise<runtime.ApiResponse<PestelItemModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling pestelItemsIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling pestelItemsIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/pestel_items/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: PestelItemUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      PestelItemModelFromJSON(jsonValue)
    );
  }

  /**
   * Update PESTEL item
   */
  async pestelItemsIdPatch(
    requestParameters: PestelItemsIdPatchRequest
  ): Promise<PestelItemModel> {
    const response = await this.pestelItemsIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all policies
   */
  async policiesGetRaw(): Promise<runtime.ApiResponse<Array<Policy>>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/policies`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(PolicyFromJSON)
    );
  }

  /**
   * Get the list of all policies
   */
  async policiesGet(): Promise<Array<Policy>> {
    const response = await this.policiesGetRaw();
    return await response.value();
  }

  /**
   * Get policy categories
   */
  async policyCategoriesGetRaw(): Promise<
    runtime.ApiResponse<Array<PolicyCategory>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/policy_categories`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(PolicyCategoryFromJSON)
    );
  }

  /**
   * Get policy categories
   */
  async policyCategoriesGet(): Promise<Array<PolicyCategory>> {
    const response = await this.policyCategoriesGetRaw();
    return await response.value();
  }

  /**
   * Delete reminder
   */
  async remindersReminderIdDeleteRaw(
    requestParameters: RemindersReminderIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.reminderId === null ||
      requestParameters.reminderId === undefined
    ) {
      throw new runtime.RequiredError(
        'reminderId',
        'Required parameter requestParameters.reminderId was null or undefined when calling remindersReminderIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/reminders/{reminder_id}`.replace(
        `{${'reminder_id'}}`,
        encodeURIComponent(String(requestParameters.reminderId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete reminder
   */
  async remindersReminderIdDelete(
    requestParameters: RemindersReminderIdDeleteRequest
  ): Promise<void> {
    await this.remindersReminderIdDeleteRaw(requestParameters);
  }

  /**
   * Update reminder
   */
  async remindersReminderIdPatchRaw(
    requestParameters: RemindersReminderIdPatchRequest
  ): Promise<runtime.ApiResponse<ReminderModel>> {
    if (
      requestParameters.reminderId === null ||
      requestParameters.reminderId === undefined
    ) {
      throw new runtime.RequiredError(
        'reminderId',
        'Required parameter requestParameters.reminderId was null or undefined when calling remindersReminderIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/reminders/{reminder_id}`.replace(
        `{${'reminder_id'}}`,
        encodeURIComponent(String(requestParameters.reminderId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: ReminderUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReminderModelFromJSON(jsonValue)
    );
  }

  /**
   * Update reminder
   */
  async remindersReminderIdPatch(
    requestParameters: RemindersReminderIdPatchRequest
  ): Promise<ReminderModel> {
    const response = await this.remindersReminderIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete report
   */
  async reportsIdDeleteRaw(
    requestParameters: ReportsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling reportsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/reports/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete report
   */
  async reportsIdDelete(
    requestParameters: ReportsIdDeleteRequest
  ): Promise<void> {
    await this.reportsIdDeleteRaw(requestParameters);
  }

  /**
   * Get report pdf
   */
  async reportsIdDocumentGetRaw(
    requestParameters: ReportsIdDocumentGetRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling reportsIdDocumentGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/reports/{id}/document`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Get report pdf
   */
  async reportsIdDocumentGet(
    requestParameters: ReportsIdDocumentGetRequest
  ): Promise<void> {
    await this.reportsIdDocumentGetRaw(requestParameters);
  }

  /**
   * Get report
   */
  async reportsIdGetRaw(
    requestParameters: ReportsIdGetRequest
  ): Promise<runtime.ApiResponse<ReportModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling reportsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/reports/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReportModelFromJSON(jsonValue)
    );
  }

  /**
   * Get report
   */
  async reportsIdGet(
    requestParameters: ReportsIdGetRequest
  ): Promise<ReportModel> {
    const response = await this.reportsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get risk categories
   */
  async riskCategoriesGetRaw(
    requestParameters: RiskCategoriesGetRequest
  ): Promise<runtime.ApiResponse<Array<RiskCategoryModel>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.vendorId !== undefined) {
      queryParameters['vendor_id'] = requestParameters.vendorId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/risk_categories`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(RiskCategoryModelFromJSON)
    );
  }

  /**
   * Get risk categories
   */
  async riskCategoriesGet(
    requestParameters: RiskCategoriesGetRequest
  ): Promise<Array<RiskCategoryModel>> {
    const response = await this.riskCategoriesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete risk report
   */
  async riskReportsIdDeleteRaw(
    requestParameters: RiskReportsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling riskReportsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/risk_reports/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete risk report
   */
  async riskReportsIdDelete(
    requestParameters: RiskReportsIdDeleteRequest
  ): Promise<void> {
    await this.riskReportsIdDeleteRaw(requestParameters);
  }

  /**
   * Get risk report
   */
  async riskReportsIdGetRaw(
    requestParameters: RiskReportsIdGetRequest
  ): Promise<runtime.ApiResponse<RiskReportModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling riskReportsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/risk_reports/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RiskReportModelFromJSON(jsonValue)
    );
  }

  /**
   * Get risk report
   */
  async riskReportsIdGet(
    requestParameters: RiskReportsIdGetRequest
  ): Promise<RiskReportModel> {
    const response = await this.riskReportsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get risk templates
   */
  async riskTemplatesGetRaw(): Promise<
    runtime.ApiResponse<Array<RiskTemplateModel>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/risk_templates`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(RiskTemplateModelFromJSON)
    );
  }

  /**
   * Get risk templates
   */
  async riskTemplatesGet(): Promise<Array<RiskTemplateModel>> {
    const response = await this.riskTemplatesGetRaw();
    return await response.value();
  }

  /**
   * Approve Risk
   */
  async risksIdApprovePostRaw(
    requestParameters: RisksIdApprovePostRequest
  ): Promise<runtime.ApiResponse<RiskExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling risksIdApprovePost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/risks/{id}/approve`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RiskExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Approve Risk
   */
  async risksIdApprovePost(
    requestParameters: RisksIdApprovePostRequest
  ): Promise<RiskExtended> {
    const response = await this.risksIdApprovePostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete risk
   */
  async risksIdDeleteRaw(
    requestParameters: RisksIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling risksIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/risks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete risk
   */
  async risksIdDelete(requestParameters: RisksIdDeleteRequest): Promise<void> {
    await this.risksIdDeleteRaw(requestParameters);
  }

  /**
   * Get risk
   */
  async risksIdGetRaw(
    requestParameters: RisksIdGetRequest
  ): Promise<runtime.ApiResponse<RiskExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling risksIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/risks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RiskExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get risk
   */
  async risksIdGet(
    requestParameters: RisksIdGetRequest
  ): Promise<RiskExtended> {
    const response = await this.risksIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update risk
   */
  async risksIdPatchRaw(
    requestParameters: RisksIdPatchRequest
  ): Promise<runtime.ApiResponse<RiskExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling risksIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling risksIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/risks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: RiskUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RiskExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update risk
   */
  async risksIdPatch(
    requestParameters: RisksIdPatchRequest
  ): Promise<RiskExtended> {
    const response = await this.risksIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get registered companies matching a query string
   */
  async searchRegisteredCompaniesqqGetRaw(
    requestParameters: SearchRegisteredCompaniesqqGetRequest
  ): Promise<runtime.ApiResponse<Array<RegisteredCompany>>> {
    if (requestParameters.q === null || requestParameters.q === undefined) {
      throw new runtime.RequiredError(
        'q',
        'Required parameter requestParameters.q was null or undefined when calling searchRegisteredCompaniesqqGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/search_registered_companies?q={q}`.replace(
        `{${'q'}}`,
        encodeURIComponent(String(requestParameters.q))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(RegisteredCompanyFromJSON)
    );
  }

  /**
   * Get registered companies matching a query string
   */
  async searchRegisteredCompaniesqqGet(
    requestParameters: SearchRegisteredCompaniesqqGetRequest
  ): Promise<Array<RegisteredCompany>> {
    const response =
      await this.searchRegisteredCompaniesqqGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the payment subscriptions
   */
  async subscriptionsGetRaw(): Promise<
    runtime.ApiResponse<Array<Subscription>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/subscriptions`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(SubscriptionFromJSON)
    );
  }

  /**
   * Get the payment subscriptions
   */
  async subscriptionsGet(): Promise<Array<Subscription>> {
    const response = await this.subscriptionsGetRaw();
    return await response.value();
  }

  /**
   * Delete subscription
   */
  async subscriptionsIdDeleteRaw(
    requestParameters: SubscriptionsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling subscriptionsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/subscriptions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete subscription
   */
  async subscriptionsIdDelete(
    requestParameters: SubscriptionsIdDeleteRequest
  ): Promise<void> {
    await this.subscriptionsIdDeleteRaw(requestParameters);
  }

  /**
   * Update subscription
   */
  async subscriptionsIdPatchRaw(
    requestParameters: SubscriptionsIdPatchRequest
  ): Promise<runtime.ApiResponse<Subscription>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling subscriptionsIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling subscriptionsIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/subscriptions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: SubscriptionUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SubscriptionFromJSON(jsonValue)
    );
  }

  /**
   * Update subscription
   */
  async subscriptionsIdPatch(
    requestParameters: SubscriptionsIdPatchRequest
  ): Promise<Subscription> {
    const response = await this.subscriptionsIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update survey clause
   */
  async surveyClausesSurveyClauseIdPatchRaw(
    requestParameters: SurveyClausesSurveyClauseIdPatchRequest
  ): Promise<runtime.ApiResponse<SurveyClause>> {
    if (
      requestParameters.surveyClauseId === null ||
      requestParameters.surveyClauseId === undefined
    ) {
      throw new runtime.RequiredError(
        'surveyClauseId',
        'Required parameter requestParameters.surveyClauseId was null or undefined when calling surveyClausesSurveyClauseIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling surveyClausesSurveyClauseIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/survey_clauses/{survey_clause_id}`.replace(
        `{${'survey_clause_id'}}`,
        encodeURIComponent(String(requestParameters.surveyClauseId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: SurveyClauseUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SurveyClauseFromJSON(jsonValue)
    );
  }

  /**
   * Update survey clause
   */
  async surveyClausesSurveyClauseIdPatch(
    requestParameters: SurveyClausesSurveyClauseIdPatchRequest
  ): Promise<SurveyClause> {
    const response =
      await this.surveyClausesSurveyClauseIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Revert survey clause custom text
   */
  async surveyClausesSurveyClauseIdRevertCustomTextPatchRaw(
    requestParameters: SurveyClausesSurveyClauseIdRevertCustomTextPatchRequest
  ): Promise<runtime.ApiResponse<SurveyClause>> {
    if (
      requestParameters.surveyClauseId === null ||
      requestParameters.surveyClauseId === undefined
    ) {
      throw new runtime.RequiredError(
        'surveyClauseId',
        'Required parameter requestParameters.surveyClauseId was null or undefined when calling surveyClausesSurveyClauseIdRevertCustomTextPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/survey_clauses/{survey_clause_id}/revert_custom_text`.replace(
        `{${'survey_clause_id'}}`,
        encodeURIComponent(String(requestParameters.surveyClauseId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SurveyClauseFromJSON(jsonValue)
    );
  }

  /**
   * Revert survey clause custom text
   */
  async surveyClausesSurveyClauseIdRevertCustomTextPatch(
    requestParameters: SurveyClausesSurveyClauseIdRevertCustomTextPatchRequest
  ): Promise<SurveyClause> {
    const response =
      await this.surveyClausesSurveyClauseIdRevertCustomTextPatchRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get a table of generic data
   */
  async tablesTypeGetRaw(
    requestParameters: TablesTypeGetRequest
  ): Promise<runtime.ApiResponse<Table>> {
    if (
      requestParameters.type === null ||
      requestParameters.type === undefined
    ) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter requestParameters.type was null or undefined when calling tablesTypeGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/tables/{type}`.replace(
        `{${'type'}}`,
        encodeURIComponent(String(requestParameters.type))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TableFromJSON(jsonValue)
    );
  }

  /**
   * Get a table of generic data
   */
  async tablesTypeGet(requestParameters: TablesTypeGetRequest): Promise<Table> {
    const response = await this.tablesTypeGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Set available assertions
   */
  async tasksTaskIdAvailableAssertionsPatchRaw(
    requestParameters: TasksTaskIdAvailableAssertionsPatchRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.taskId === null ||
      requestParameters.taskId === undefined
    ) {
      throw new runtime.RequiredError(
        'taskId',
        'Required parameter requestParameters.taskId was null or undefined when calling tasksTaskIdAvailableAssertionsPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling tasksTaskIdAvailableAssertionsPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/tasks/{task_id}/available_assertions`.replace(
        `{${'task_id'}}`,
        encodeURIComponent(String(requestParameters.taskId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: TaskAvailableAssertionsPayloadToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Set available assertions
   */
  async tasksTaskIdAvailableAssertionsPatch(
    requestParameters: TasksTaskIdAvailableAssertionsPatchRequest
  ): Promise<void> {
    await this.tasksTaskIdAvailableAssertionsPatchRaw(requestParameters);
  }

  /**
   * Create evidence
   */
  async tasksTaskIdEvidencesPostRaw(
    requestParameters: TasksTaskIdEvidencesPostRequest
  ): Promise<runtime.ApiResponse<EvidenceModel>> {
    if (
      requestParameters.taskId === null ||
      requestParameters.taskId === undefined
    ) {
      throw new runtime.RequiredError(
        'taskId',
        'Required parameter requestParameters.taskId was null or undefined when calling tasksTaskIdEvidencesPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.attachment !== undefined) {
      formParams.append('attachment', requestParameters.attachment as any);
    }

    if (requestParameters.description !== undefined) {
      formParams.append('description', requestParameters.description as any);
    }

    const response = await this.request({
      path: `/tasks/{task_id}/evidences`.replace(
        `{${'task_id'}}`,
        encodeURIComponent(String(requestParameters.taskId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      EvidenceModelFromJSON(jsonValue)
    );
  }

  /**
   * Create evidence
   */
  async tasksTaskIdEvidencesPost(
    requestParameters: TasksTaskIdEvidencesPostRequest
  ): Promise<EvidenceModel> {
    const response = await this.tasksTaskIdEvidencesPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get task
   */
  async tasksTaskIdGetRaw(
    requestParameters: TasksTaskIdGetRequest
  ): Promise<runtime.ApiResponse<TaskExtended>> {
    if (
      requestParameters.taskId === null ||
      requestParameters.taskId === undefined
    ) {
      throw new runtime.RequiredError(
        'taskId',
        'Required parameter requestParameters.taskId was null or undefined when calling tasksTaskIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/tasks/{task_id}`.replace(
        `{${'task_id'}}`,
        encodeURIComponent(String(requestParameters.taskId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TaskExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get task
   */
  async tasksTaskIdGet(
    requestParameters: TasksTaskIdGetRequest
  ): Promise<TaskExtended> {
    const response = await this.tasksTaskIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update task
   */
  async tasksTaskIdPatchRaw(
    requestParameters: TasksTaskIdPatchRequest
  ): Promise<runtime.ApiResponse<TaskExtended>> {
    if (
      requestParameters.taskId === null ||
      requestParameters.taskId === undefined
    ) {
      throw new runtime.RequiredError(
        'taskId',
        'Required parameter requestParameters.taskId was null or undefined when calling tasksTaskIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling tasksTaskIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/tasks/{task_id}`.replace(
        `{${'task_id'}}`,
        encodeURIComponent(String(requestParameters.taskId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: TaskUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TaskExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update task
   */
  async tasksTaskIdPatch(
    requestParameters: TasksTaskIdPatchRequest
  ): Promise<TaskExtended> {
    const response = await this.tasksTaskIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the details of the current user
   */
  async userDetailsGetRaw(): Promise<runtime.ApiResponse<UserDetails>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/user_details`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UserDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Get the details of the current user
   */
  async userDetailsGet(): Promise<UserDetails> {
    const response = await this.userDetailsGetRaw();
    return await response.value();
  }

  /**
   * Change the details of the current user
   */
  async userDetailsPatchRaw(
    requestParameters: UserDetailsPatchRequest
  ): Promise<runtime.ApiResponse<UserDetails>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/user_details`,
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: UserDetailsToJSON(requestParameters.userDetails),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UserDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Change the details of the current user
   */
  async userDetailsPatch(
    requestParameters: UserDetailsPatchRequest
  ): Promise<UserDetails> {
    const response = await this.userDetailsPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete my account
   */
  async usersDeleteRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/users`,
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete my account
   */
  async usersDelete(): Promise<void> {
    await this.usersDeleteRaw();
  }

  /**
   * Get list of users by query params
   */
  async usersGetRaw(
    requestParameters: UsersGetRequest
  ): Promise<runtime.ApiResponse<Array<UserDetails>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.queryEmail !== undefined) {
      queryParameters['query[email]'] = requestParameters.queryEmail;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/users`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(UserDetailsFromJSON)
    );
  }

  /**
   * Get list of users by query params
   */
  async usersGet(
    requestParameters: UsersGetRequest
  ): Promise<Array<UserDetails>> {
    const response = await this.usersGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get user signature urls
   */
  async usersIdSignatureGetRaw(
    requestParameters: UsersIdSignatureGetRequest
  ): Promise<runtime.ApiResponse<Image>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling usersIdSignatureGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/users/{id}/signature`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ImageFromJSON(jsonValue)
    );
  }

  /**
   * Get user signature urls
   */
  async usersIdSignatureGet(
    requestParameters: UsersIdSignatureGetRequest
  ): Promise<Image> {
    const response = await this.usersIdSignatureGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update user signature
   */
  async usersIdSignaturePatchRaw(
    requestParameters: UsersIdSignaturePatchRequest
  ): Promise<runtime.ApiResponse<Image>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling usersIdSignaturePatch.'
      );
    }

    if (
      requestParameters.image === null ||
      requestParameters.image === undefined
    ) {
      throw new runtime.RequiredError(
        'image',
        'Required parameter requestParameters.image was null or undefined when calling usersIdSignaturePatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request({
      path: `/users/{id}/signature`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ImageFromJSON(jsonValue)
    );
  }

  /**
   * Update user signature
   */
  async usersIdSignaturePatch(
    requestParameters: UsersIdSignaturePatchRequest
  ): Promise<Image> {
    const response = await this.usersIdSignaturePatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create an invitation
   */
  async usersInvitationsPostRaw(
    requestParameters: UsersInvitationsPostRequest
  ): Promise<runtime.ApiResponse<MessageResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users/invitations`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: UserInvitationToJSON(requestParameters.invitation),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      MessageResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create an invitation
   */
  async usersInvitationsPost(
    requestParameters: UsersInvitationsPostRequest
  ): Promise<MessageResponse> {
    const response = await this.usersInvitationsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the details for the invitation
   */
  async usersInvitationsTokenGetRaw(
    requestParameters: UsersInvitationsTokenGetRequest
  ): Promise<runtime.ApiResponse<UserInvitation>> {
    if (
      requestParameters.token === null ||
      requestParameters.token === undefined
    ) {
      throw new runtime.RequiredError(
        'token',
        'Required parameter requestParameters.token was null or undefined when calling usersInvitationsTokenGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/users/invitations/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(requestParameters.token))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UserInvitationFromJSON(jsonValue)
    );
  }

  /**
   * Get the details for the invitation
   */
  async usersInvitationsTokenGet(
    requestParameters: UsersInvitationsTokenGetRequest
  ): Promise<UserInvitation> {
    const response = await this.usersInvitationsTokenGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Set new password
   */
  async usersPasswordPatchRaw(
    requestParameters: UsersPasswordPatchRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users/password`,
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: UserResetPasswordToJSON(requestParameters.user),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Set new password
   */
  async usersPasswordPatch(
    requestParameters: UsersPasswordPatchRequest
  ): Promise<void> {
    await this.usersPasswordPatchRaw(requestParameters);
  }

  /**
   * Forgot my password
   */
  async usersPasswordPostRaw(
    requestParameters: UsersPasswordPostRequest
  ): Promise<runtime.ApiResponse<UserDetails>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users/password`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: UserForgotPasswordToJSON(requestParameters.user),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UserDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Forgot my password
   */
  async usersPasswordPost(
    requestParameters: UsersPasswordPostRequest
  ): Promise<UserDetails> {
    const response = await this.usersPasswordPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Change email or password
   */
  async usersPatchRaw(
    requestParameters: UsersPatchRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users`,
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: UserPasswordToJSON(requestParameters.password),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Change email or password
   */
  async usersPatch(requestParameters: UsersPatchRequest): Promise<void> {
    await this.usersPatchRaw(requestParameters);
  }

  /**
   * Sign up to the platform
   */
  async usersPostRaw(
    requestParameters: UsersPostRequest
  ): Promise<runtime.ApiResponse<UserDetails>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: UserSignUpToJSON(requestParameters.user),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UserDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Sign up to the platform
   */
  async usersPost(requestParameters: UsersPostRequest): Promise<UserDetails> {
    const response = await this.usersPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Sign in to the platform
   */
  async usersSignInPostRaw(
    requestParameters: UsersSignInPostRequest
  ): Promise<runtime.ApiResponse<UserDetails>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users/sign_in`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: UserSignInToJSON(requestParameters.session),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UserDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Sign in to the platform
   */
  async usersSignInPost(
    requestParameters: UsersSignInPostRequest
  ): Promise<UserDetails> {
    const response = await this.usersSignInPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Sign out of the platform
   */
  async usersSignOutDeleteRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/users/sign_out`,
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Sign out of the platform
   */
  async usersSignOutDelete(): Promise<void> {
    await this.usersSignOutDeleteRaw();
  }

  /**
   * Verify 2FA token
   */
  async usersSmsRequestPostRaw(
    requestParameters: UsersSmsRequestPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users/sms_request`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TwoFactorSmsRequestPayloadToJSON(requestParameters.payload),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Verify 2FA token
   */
  async usersSmsRequestPost(
    requestParameters: UsersSmsRequestPostRequest
  ): Promise<void> {
    await this.usersSmsRequestPostRaw(requestParameters);
  }

  /**
   * Verify OTP attempt
   */
  async usersVerifyOtpPostRaw(
    requestParameters: UsersVerifyOtpPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users/verify_otp`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TwoFactorVerifyOtpPayloadToJSON(requestParameters.payload),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Verify OTP attempt
   */
  async usersVerifyOtpPost(
    requestParameters: UsersVerifyOtpPostRequest
  ): Promise<void> {
    await this.usersVerifyOtpPostRaw(requestParameters);
  }

  /**
   * Verify 2FA token
   */
  async usersVerifyTokenPostRaw(
    requestParameters: UsersVerifyTokenPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users/verify_token`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TwoFactorVerifyTokenPayloadToJSON(requestParameters.payload),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Verify 2FA token
   */
  async usersVerifyTokenPost(
    requestParameters: UsersVerifyTokenPostRequest
  ): Promise<void> {
    await this.usersVerifyTokenPostRaw(requestParameters);
  }

  /**
   * Delete action
   */
  async vendorActionsVendorActionIdDeleteRaw(
    requestParameters: VendorActionsVendorActionIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.vendorActionId === null ||
      requestParameters.vendorActionId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorActionId',
        'Required parameter requestParameters.vendorActionId was null or undefined when calling vendorActionsVendorActionIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_actions/{vendor_action_id}`.replace(
        `{${'vendor_action_id'}}`,
        encodeURIComponent(String(requestParameters.vendorActionId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete action
   */
  async vendorActionsVendorActionIdDelete(
    requestParameters: VendorActionsVendorActionIdDeleteRequest
  ): Promise<void> {
    await this.vendorActionsVendorActionIdDeleteRaw(requestParameters);
  }

  /**
   * Get action
   */
  async vendorActionsVendorActionIdGetRaw(
    requestParameters: VendorActionsVendorActionIdGetRequest
  ): Promise<runtime.ApiResponse<VendorActionExtended>> {
    if (
      requestParameters.vendorActionId === null ||
      requestParameters.vendorActionId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorActionId',
        'Required parameter requestParameters.vendorActionId was null or undefined when calling vendorActionsVendorActionIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_actions/{vendor_action_id}`.replace(
        `{${'vendor_action_id'}}`,
        encodeURIComponent(String(requestParameters.vendorActionId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorActionExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get action
   */
  async vendorActionsVendorActionIdGet(
    requestParameters: VendorActionsVendorActionIdGetRequest
  ): Promise<VendorActionExtended> {
    const response =
      await this.vendorActionsVendorActionIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update action
   */
  async vendorActionsVendorActionIdPatchRaw(
    requestParameters: VendorActionsVendorActionIdPatchRequest
  ): Promise<runtime.ApiResponse<VendorActionExtended>> {
    if (
      requestParameters.vendorActionId === null ||
      requestParameters.vendorActionId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorActionId',
        'Required parameter requestParameters.vendorActionId was null or undefined when calling vendorActionsVendorActionIdPatch.'
      );
    }

    if (
      requestParameters.vendorActionName === null ||
      requestParameters.vendorActionName === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorActionName',
        'Required parameter requestParameters.vendorActionName was null or undefined when calling vendorActionsVendorActionIdPatch.'
      );
    }

    if (
      requestParameters.vendorActionOwnerId === null ||
      requestParameters.vendorActionOwnerId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorActionOwnerId',
        'Required parameter requestParameters.vendorActionOwnerId was null or undefined when calling vendorActionsVendorActionIdPatch.'
      );
    }

    if (
      requestParameters.vendorActionCompleted === null ||
      requestParameters.vendorActionCompleted === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorActionCompleted',
        'Required parameter requestParameters.vendorActionCompleted was null or undefined when calling vendorActionsVendorActionIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.vendorActionName !== undefined) {
      formParams.append(
        'vendor_action[name]',
        requestParameters.vendorActionName as any
      );
    }

    if (requestParameters.vendorActionDescription !== undefined) {
      formParams.append(
        'vendor_action[description]',
        requestParameters.vendorActionDescription as any
      );
    }

    if (requestParameters.vendorActionDueDate !== undefined) {
      formParams.append(
        'vendor_action[due_date]',
        requestParameters.vendorActionDueDate as any
      );
    }

    if (requestParameters.vendorActionOwnerId !== undefined) {
      formParams.append(
        'vendor_action[owner_id]',
        requestParameters.vendorActionOwnerId as any
      );
    }

    if (requestParameters.vendorActionAttachments) {
      formParams.append(
        'vendor_action[attachments]',
        requestParameters.vendorActionAttachments.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    if (requestParameters.vendorActionUrl !== undefined) {
      formParams.append(
        'vendor_action[url]',
        requestParameters.vendorActionUrl as any
      );
    }

    if (requestParameters.vendorActionActionType !== undefined) {
      formParams.append(
        'vendor_action[action_type]',
        requestParameters.vendorActionActionType as any
      );
    }

    if (requestParameters.vendorActionCompleted !== undefined) {
      formParams.append(
        'vendor_action[completed]',
        requestParameters.vendorActionCompleted as any
      );
    }

    if (requestParameters.vendorActionDeletedAttachmentIds) {
      formParams.append(
        'vendor_action[deleted_attachment_ids]',
        requestParameters.vendorActionDeletedAttachmentIds.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    const response = await this.request({
      path: `/vendor_actions/{vendor_action_id}`.replace(
        `{${'vendor_action_id'}}`,
        encodeURIComponent(String(requestParameters.vendorActionId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorActionExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update action
   */
  async vendorActionsVendorActionIdPatch(
    requestParameters: VendorActionsVendorActionIdPatchRequest
  ): Promise<VendorActionExtended> {
    const response =
      await this.vendorActionsVendorActionIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Remove reference
   */
  async vendorActionsVendorActionIdReferencesDeleteRaw(
    requestParameters: VendorActionsVendorActionIdReferencesDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.vendorActionId === null ||
      requestParameters.vendorActionId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorActionId',
        'Required parameter requestParameters.vendorActionId was null or undefined when calling vendorActionsVendorActionIdReferencesDelete.'
      );
    }

    if (
      requestParameters.referenceableId === null ||
      requestParameters.referenceableId === undefined
    ) {
      throw new runtime.RequiredError(
        'referenceableId',
        'Required parameter requestParameters.referenceableId was null or undefined when calling vendorActionsVendorActionIdReferencesDelete.'
      );
    }

    if (
      requestParameters.referenceableType === null ||
      requestParameters.referenceableType === undefined
    ) {
      throw new runtime.RequiredError(
        'referenceableType',
        'Required parameter requestParameters.referenceableType was null or undefined when calling vendorActionsVendorActionIdReferencesDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.referenceableId !== undefined) {
      queryParameters['referenceable_id'] = requestParameters.referenceableId;
    }

    if (requestParameters.referenceableType !== undefined) {
      queryParameters['referenceable_type'] =
        requestParameters.referenceableType;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_actions/{vendor_action_id}/references`.replace(
        `{${'vendor_action_id'}}`,
        encodeURIComponent(String(requestParameters.vendorActionId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Remove reference
   */
  async vendorActionsVendorActionIdReferencesDelete(
    requestParameters: VendorActionsVendorActionIdReferencesDeleteRequest
  ): Promise<void> {
    await this.vendorActionsVendorActionIdReferencesDeleteRaw(
      requestParameters
    );
  }

  /**
   * Create reference
   */
  async vendorActionsVendorActionIdReferencesPostRaw(
    requestParameters: VendorActionsVendorActionIdReferencesPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.vendorActionId === null ||
      requestParameters.vendorActionId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorActionId',
        'Required parameter requestParameters.vendorActionId was null or undefined when calling vendorActionsVendorActionIdReferencesPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorActionsVendorActionIdReferencesPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_actions/{vendor_action_id}/references`.replace(
        `{${'vendor_action_id'}}`,
        encodeURIComponent(String(requestParameters.vendorActionId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ReferenceCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create reference
   */
  async vendorActionsVendorActionIdReferencesPost(
    requestParameters: VendorActionsVendorActionIdReferencesPostRequest
  ): Promise<void> {
    await this.vendorActionsVendorActionIdReferencesPostRaw(requestParameters);
  }

  /**
   * Send notification for attestation
   */
  async vendorDocumentAttestationsAttestationIdNotifyPostRaw(
    requestParameters: VendorDocumentAttestationsAttestationIdNotifyPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.attestationId === null ||
      requestParameters.attestationId === undefined
    ) {
      throw new runtime.RequiredError(
        'attestationId',
        'Required parameter requestParameters.attestationId was null or undefined when calling vendorDocumentAttestationsAttestationIdNotifyPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_document_attestations/{attestation_id}/notify`.replace(
        `{${'attestation_id'}}`,
        encodeURIComponent(String(requestParameters.attestationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send notification for attestation
   */
  async vendorDocumentAttestationsAttestationIdNotifyPost(
    requestParameters: VendorDocumentAttestationsAttestationIdNotifyPostRequest
  ): Promise<void> {
    await this.vendorDocumentAttestationsAttestationIdNotifyPostRaw(
      requestParameters
    );
  }

  /**
   * Approve the document for the current user
   */
  async vendorDocumentsIdApprovePostRaw(
    requestParameters: VendorDocumentsIdApprovePostRequest
  ): Promise<runtime.ApiResponse<VendorDocumentApproval>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdApprovePost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_documents/{id}/approve`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorDocumentApprovalFromJSON(jsonValue)
    );
  }

  /**
   * Approve the document for the current user
   */
  async vendorDocumentsIdApprovePost(
    requestParameters: VendorDocumentsIdApprovePostRequest
  ): Promise<VendorDocumentApproval> {
    const response =
      await this.vendorDocumentsIdApprovePostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Set archived flag for vendor document
   */
  async vendorDocumentsIdArchivePostRaw(
    requestParameters: VendorDocumentsIdArchivePostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdArchivePost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_documents/{id}/archive`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Set archived flag for vendor document
   */
  async vendorDocumentsIdArchivePost(
    requestParameters: VendorDocumentsIdArchivePostRequest
  ): Promise<void> {
    await this.vendorDocumentsIdArchivePostRaw(requestParameters);
  }

  /**
   * Attest the document for the current user
   */
  async vendorDocumentsIdAttestPostRaw(
    requestParameters: VendorDocumentsIdAttestPostRequest
  ): Promise<runtime.ApiResponse<VendorDocumentAttestation>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdAttestPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_documents/{id}/attest`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorDocumentAttestationFromJSON(jsonValue)
    );
  }

  /**
   * Attest the document for the current user
   */
  async vendorDocumentsIdAttestPost(
    requestParameters: VendorDocumentsIdAttestPostRequest
  ): Promise<VendorDocumentAttestation> {
    const response =
      await this.vendorDocumentsIdAttestPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete a vendor document
   */
  async vendorDocumentsIdDeleteRaw(
    requestParameters: VendorDocumentsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_documents/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a vendor document
   */
  async vendorDocumentsIdDelete(
    requestParameters: VendorDocumentsIdDeleteRequest
  ): Promise<void> {
    await this.vendorDocumentsIdDeleteRaw(requestParameters);
  }

  /**
   * Get serialized vendor document with download links
   */
  async vendorDocumentsIdDownloadGetRaw(
    requestParameters: VendorDocumentsIdDownloadGetRequest
  ): Promise<runtime.ApiResponse<VendorDocumentDownload>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdDownloadGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_documents/{id}/download`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorDocumentDownloadFromJSON(jsonValue)
    );
  }

  /**
   * Get serialized vendor document with download links
   */
  async vendorDocumentsIdDownloadGet(
    requestParameters: VendorDocumentsIdDownloadGetRequest
  ): Promise<VendorDocumentDownload> {
    const response =
      await this.vendorDocumentsIdDownloadGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details for one vendor document
   */
  async vendorDocumentsIdGetRaw(
    requestParameters: VendorDocumentsIdGetRequest
  ): Promise<runtime.ApiResponse<VendorDocumentExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_documents/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorDocumentExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get details for one vendor document
   */
  async vendorDocumentsIdGet(
    requestParameters: VendorDocumentsIdGetRequest
  ): Promise<VendorDocumentExtended> {
    const response = await this.vendorDocumentsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update the details for a vendor document
   */
  async vendorDocumentsIdPatchRaw(
    requestParameters: VendorDocumentsIdPatchRequest
  ): Promise<runtime.ApiResponse<VendorDocumentExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_documents/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: VendorDocumentUpdatePayloadToJSON(requestParameters.vendorDocument),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorDocumentExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update the details for a vendor document
   */
  async vendorDocumentsIdPatch(
    requestParameters: VendorDocumentsIdPatchRequest
  ): Promise<VendorDocumentExtended> {
    const response = await this.vendorDocumentsIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Reject the document approval
   */
  async vendorDocumentsIdRejectPostRaw(
    requestParameters: VendorDocumentsIdRejectPostRequest
  ): Promise<runtime.ApiResponse<VendorDocumentApproval>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdRejectPost.'
      );
    }

    if (
      requestParameters.vendorDocumentApproval === null ||
      requestParameters.vendorDocumentApproval === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorDocumentApproval',
        'Required parameter requestParameters.vendorDocumentApproval was null or undefined when calling vendorDocumentsIdRejectPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_documents/{id}/reject`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VendorDocumentApprovalRejectToJSON(
        requestParameters.vendorDocumentApproval
      ),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorDocumentApprovalFromJSON(jsonValue)
    );
  }

  /**
   * Reject the document approval
   */
  async vendorDocumentsIdRejectPost(
    requestParameters: VendorDocumentsIdRejectPostRequest
  ): Promise<VendorDocumentApproval> {
    const response =
      await this.vendorDocumentsIdRejectPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Skip attestation for the current document
   */
  async vendorDocumentsIdSkipApprovalPostRaw(
    requestParameters: VendorDocumentsIdSkipApprovalPostRequest
  ): Promise<runtime.ApiResponse<VendorDocumentExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdSkipApprovalPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_documents/{id}/skip_approval`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorDocumentExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Skip attestation for the current document
   */
  async vendorDocumentsIdSkipApprovalPost(
    requestParameters: VendorDocumentsIdSkipApprovalPostRequest
  ): Promise<VendorDocumentExtended> {
    const response =
      await this.vendorDocumentsIdSkipApprovalPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Skip attestation for the current document
   */
  async vendorDocumentsIdSkipAttestationPostRaw(
    requestParameters: VendorDocumentsIdSkipAttestationPostRequest
  ): Promise<runtime.ApiResponse<VendorDocumentExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdSkipAttestationPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_documents/{id}/skip_attestation`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorDocumentExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Skip attestation for the current document
   */
  async vendorDocumentsIdSkipAttestationPost(
    requestParameters: VendorDocumentsIdSkipAttestationPostRequest
  ): Promise<VendorDocumentExtended> {
    const response =
      await this.vendorDocumentsIdSkipAttestationPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a vendor document summary as pdf
   */
  async vendorDocumentsIdSummaryGetRaw(
    requestParameters: VendorDocumentsIdSummaryGetRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdSummaryGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_documents/{id}/summary`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Get a vendor document summary as pdf
   */
  async vendorDocumentsIdSummaryGet(
    requestParameters: VendorDocumentsIdSummaryGetRequest
  ): Promise<void> {
    await this.vendorDocumentsIdSummaryGetRaw(requestParameters);
  }

  /**
   * Unset archived flag for vendor document
   */
  async vendorDocumentsIdUnarchivePostRaw(
    requestParameters: VendorDocumentsIdUnarchivePostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdUnarchivePost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_documents/{id}/unarchive`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Unset archived flag for vendor document
   */
  async vendorDocumentsIdUnarchivePost(
    requestParameters: VendorDocumentsIdUnarchivePostRequest
  ): Promise<void> {
    await this.vendorDocumentsIdUnarchivePostRaw(requestParameters);
  }

  /**
   * Get the approvals for one document
   */
  async vendorDocumentsIdVendorDocumentApprovalsGetRaw(
    requestParameters: VendorDocumentsIdVendorDocumentApprovalsGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorDocumentApproval>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdVendorDocumentApprovalsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_documents/{id}/vendor_document_approvals`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorDocumentApprovalFromJSON)
    );
  }

  /**
   * Get the approvals for one document
   */
  async vendorDocumentsIdVendorDocumentApprovalsGet(
    requestParameters: VendorDocumentsIdVendorDocumentApprovalsGetRequest
  ): Promise<Array<VendorDocumentApproval>> {
    const response =
      await this.vendorDocumentsIdVendorDocumentApprovalsGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get the attestation groups for a vendor document
   */
  async vendorDocumentsIdVendorDocumentAttestationGroupsGetRaw(
    requestParameters: VendorDocumentsIdVendorDocumentAttestationGroupsGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorTeamModel>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdVendorDocumentAttestationGroupsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_documents/{id}/vendor_document_attestation_groups`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorTeamModelFromJSON)
    );
  }

  /**
   * Get the attestation groups for a vendor document
   */
  async vendorDocumentsIdVendorDocumentAttestationGroupsGet(
    requestParameters: VendorDocumentsIdVendorDocumentAttestationGroupsGetRequest
  ): Promise<Array<VendorTeamModel>> {
    const response =
      await this.vendorDocumentsIdVendorDocumentAttestationGroupsGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Update attestation groups for a vendor document
   */
  async vendorDocumentsIdVendorDocumentAttestationGroupsPatchRaw(
    requestParameters: VendorDocumentsIdVendorDocumentAttestationGroupsPatchRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorDocumentsIdVendorDocumentAttestationGroupsPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorDocumentsIdVendorDocumentAttestationGroupsPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_documents/{id}/vendor_document_attestation_groups`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: VendorDocumentAttestationsGroupUpdatePayloadToJSON(
        requestParameters.body
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update attestation groups for a vendor document
   */
  async vendorDocumentsIdVendorDocumentAttestationGroupsPatch(
    requestParameters: VendorDocumentsIdVendorDocumentAttestationGroupsPatchRequest
  ): Promise<void> {
    await this.vendorDocumentsIdVendorDocumentAttestationGroupsPatchRaw(
      requestParameters
    );
  }

  /**
   * Delete event
   */
  async vendorEventsVendorEventIdDeleteRaw(
    requestParameters: VendorEventsVendorEventIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.vendorEventId === null ||
      requestParameters.vendorEventId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorEventId',
        'Required parameter requestParameters.vendorEventId was null or undefined when calling vendorEventsVendorEventIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_events/{vendor_event_id}`.replace(
        `{${'vendor_event_id'}}`,
        encodeURIComponent(String(requestParameters.vendorEventId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete event
   */
  async vendorEventsVendorEventIdDelete(
    requestParameters: VendorEventsVendorEventIdDeleteRequest
  ): Promise<void> {
    await this.vendorEventsVendorEventIdDeleteRaw(requestParameters);
  }

  /**
   * Get event
   */
  async vendorEventsVendorEventIdGetRaw(
    requestParameters: VendorEventsVendorEventIdGetRequest
  ): Promise<runtime.ApiResponse<VendorEventExtended>> {
    if (
      requestParameters.vendorEventId === null ||
      requestParameters.vendorEventId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorEventId',
        'Required parameter requestParameters.vendorEventId was null or undefined when calling vendorEventsVendorEventIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_events/{vendor_event_id}`.replace(
        `{${'vendor_event_id'}}`,
        encodeURIComponent(String(requestParameters.vendorEventId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorEventExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get event
   */
  async vendorEventsVendorEventIdGet(
    requestParameters: VendorEventsVendorEventIdGetRequest
  ): Promise<VendorEventExtended> {
    const response =
      await this.vendorEventsVendorEventIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update event
   */
  async vendorEventsVendorEventIdPatchRaw(
    requestParameters: VendorEventsVendorEventIdPatchRequest
  ): Promise<runtime.ApiResponse<VendorEventExtended>> {
    if (
      requestParameters.vendorEventId === null ||
      requestParameters.vendorEventId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorEventId',
        'Required parameter requestParameters.vendorEventId was null or undefined when calling vendorEventsVendorEventIdPatch.'
      );
    }

    if (
      requestParameters.vendorEventName === null ||
      requestParameters.vendorEventName === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorEventName',
        'Required parameter requestParameters.vendorEventName was null or undefined when calling vendorEventsVendorEventIdPatch.'
      );
    }

    if (
      requestParameters.vendorEventDate === null ||
      requestParameters.vendorEventDate === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorEventDate',
        'Required parameter requestParameters.vendorEventDate was null or undefined when calling vendorEventsVendorEventIdPatch.'
      );
    }

    if (
      requestParameters.vendorEventEventType === null ||
      requestParameters.vendorEventEventType === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorEventEventType',
        'Required parameter requestParameters.vendorEventEventType was null or undefined when calling vendorEventsVendorEventIdPatch.'
      );
    }

    if (
      requestParameters.vendorEventOwnerId === null ||
      requestParameters.vendorEventOwnerId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorEventOwnerId',
        'Required parameter requestParameters.vendorEventOwnerId was null or undefined when calling vendorEventsVendorEventIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.vendorEventName !== undefined) {
      formParams.append(
        'vendor_event[name]',
        requestParameters.vendorEventName as any
      );
    }

    if (requestParameters.vendorEventDescription !== undefined) {
      formParams.append(
        'vendor_event[description]',
        requestParameters.vendorEventDescription as any
      );
    }

    if (requestParameters.vendorEventDate !== undefined) {
      formParams.append(
        'vendor_event[date]',
        requestParameters.vendorEventDate as any
      );
    }

    if (requestParameters.vendorEventEventType !== undefined) {
      formParams.append(
        'vendor_event[event_type]',
        requestParameters.vendorEventEventType as any
      );
    }

    if (requestParameters.vendorEventOwnerId !== undefined) {
      formParams.append(
        'vendor_event[owner_id]',
        requestParameters.vendorEventOwnerId as any
      );
    }

    if (requestParameters.vendorEventAttachments) {
      formParams.append(
        'vendor_event[attachments]',
        requestParameters.vendorEventAttachments.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    if (requestParameters.vendorEventUrl !== undefined) {
      formParams.append(
        'vendor_event[url]',
        requestParameters.vendorEventUrl as any
      );
    }

    if (requestParameters.vendorEventDeletedAttachmentIds) {
      formParams.append(
        'vendor_event[deleted_attachment_ids]',
        requestParameters.vendorEventDeletedAttachmentIds.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    const response = await this.request({
      path: `/vendor_events/{vendor_event_id}`.replace(
        `{${'vendor_event_id'}}`,
        encodeURIComponent(String(requestParameters.vendorEventId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorEventExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update event
   */
  async vendorEventsVendorEventIdPatch(
    requestParameters: VendorEventsVendorEventIdPatchRequest
  ): Promise<VendorEventExtended> {
    const response =
      await this.vendorEventsVendorEventIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete vendor framework requirement
   */
  async vendorFrameworkRequirementsIdDeleteRaw(
    requestParameters: VendorFrameworkRequirementsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorFrameworkRequirementsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_framework_requirements/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete vendor framework requirement
   */
  async vendorFrameworkRequirementsIdDelete(
    requestParameters: VendorFrameworkRequirementsIdDeleteRequest
  ): Promise<void> {
    await this.vendorFrameworkRequirementsIdDeleteRaw(requestParameters);
  }

  /**
   * Delete vendor integration sync
   */
  async vendorIntegrationSyncsVendorIntegrationSyncIdDeleteRaw(
    requestParameters: VendorIntegrationSyncsVendorIntegrationSyncIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.vendorIntegrationSyncId === null ||
      requestParameters.vendorIntegrationSyncId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIntegrationSyncId',
        'Required parameter requestParameters.vendorIntegrationSyncId was null or undefined when calling vendorIntegrationSyncsVendorIntegrationSyncIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_integration_syncs/{vendor_integration_sync_id}`.replace(
        `{${'vendor_integration_sync_id'}}`,
        encodeURIComponent(String(requestParameters.vendorIntegrationSyncId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete vendor integration sync
   */
  async vendorIntegrationSyncsVendorIntegrationSyncIdDelete(
    requestParameters: VendorIntegrationSyncsVendorIntegrationSyncIdDeleteRequest
  ): Promise<void> {
    await this.vendorIntegrationSyncsVendorIntegrationSyncIdDeleteRaw(
      requestParameters
    );
  }

  /**
   * Delete vendor integration
   */
  async vendorIntegrationsVendorIntegrationIdDeleteRaw(
    requestParameters: VendorIntegrationsVendorIntegrationIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.vendorIntegrationId === null ||
      requestParameters.vendorIntegrationId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIntegrationId',
        'Required parameter requestParameters.vendorIntegrationId was null or undefined when calling vendorIntegrationsVendorIntegrationIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_integrations/{vendor_integration_id}`.replace(
        `{${'vendor_integration_id'}}`,
        encodeURIComponent(String(requestParameters.vendorIntegrationId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete vendor integration
   */
  async vendorIntegrationsVendorIntegrationIdDelete(
    requestParameters: VendorIntegrationsVendorIntegrationIdDeleteRequest
  ): Promise<void> {
    await this.vendorIntegrationsVendorIntegrationIdDeleteRaw(
      requestParameters
    );
  }

  /**
   * Get vendor integration
   */
  async vendorIntegrationsVendorIntegrationIdGetRaw(
    requestParameters: VendorIntegrationsVendorIntegrationIdGetRequest
  ): Promise<runtime.ApiResponse<VendorIntegrationModel>> {
    if (
      requestParameters.vendorIntegrationId === null ||
      requestParameters.vendorIntegrationId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIntegrationId',
        'Required parameter requestParameters.vendorIntegrationId was null or undefined when calling vendorIntegrationsVendorIntegrationIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_integrations/{vendor_integration_id}`.replace(
        `{${'vendor_integration_id'}}`,
        encodeURIComponent(String(requestParameters.vendorIntegrationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorIntegrationModelFromJSON(jsonValue)
    );
  }

  /**
   * Get vendor integration
   */
  async vendorIntegrationsVendorIntegrationIdGet(
    requestParameters: VendorIntegrationsVendorIntegrationIdGetRequest
  ): Promise<VendorIntegrationModel> {
    const response =
      await this.vendorIntegrationsVendorIntegrationIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update vendor integration
   */
  async vendorIntegrationsVendorIntegrationIdPatchRaw(
    requestParameters: VendorIntegrationsVendorIntegrationIdPatchRequest
  ): Promise<runtime.ApiResponse<VendorIntegrationExtended>> {
    if (
      requestParameters.vendorIntegrationId === null ||
      requestParameters.vendorIntegrationId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIntegrationId',
        'Required parameter requestParameters.vendorIntegrationId was null or undefined when calling vendorIntegrationsVendorIntegrationIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorIntegrationsVendorIntegrationIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_integrations/{vendor_integration_id}`.replace(
        `{${'vendor_integration_id'}}`,
        encodeURIComponent(String(requestParameters.vendorIntegrationId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: VendorIntegrationUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorIntegrationExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update vendor integration
   */
  async vendorIntegrationsVendorIntegrationIdPatch(
    requestParameters: VendorIntegrationsVendorIntegrationIdPatchRequest
  ): Promise<VendorIntegrationExtended> {
    const response =
      await this.vendorIntegrationsVendorIntegrationIdPatchRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Create vendor integration sync
   */
  async vendorIntegrationsVendorIntegrationIdVendorIntegrationSyncsPostRaw(
    requestParameters: VendorIntegrationsVendorIntegrationIdVendorIntegrationSyncsPostRequest
  ): Promise<runtime.ApiResponse<VendorIntegrationSyncExtended>> {
    if (
      requestParameters.vendorIntegrationId === null ||
      requestParameters.vendorIntegrationId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIntegrationId',
        'Required parameter requestParameters.vendorIntegrationId was null or undefined when calling vendorIntegrationsVendorIntegrationIdVendorIntegrationSyncsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorIntegrationsVendorIntegrationIdVendorIntegrationSyncsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_integrations/{vendor_integration_id}/vendor_integration_syncs`.replace(
        `{${'vendor_integration_id'}}`,
        encodeURIComponent(String(requestParameters.vendorIntegrationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VendorIntegrationSyncCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorIntegrationSyncExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor integration sync
   */
  async vendorIntegrationsVendorIntegrationIdVendorIntegrationSyncsPost(
    requestParameters: VendorIntegrationsVendorIntegrationIdVendorIntegrationSyncsPostRequest
  ): Promise<VendorIntegrationSyncExtended> {
    const response =
      await this.vendorIntegrationsVendorIntegrationIdVendorIntegrationSyncsPostRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Delete a vendor policy
   */
  async vendorPoliciesIdDeleteRaw(
    requestParameters: VendorPoliciesIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorPoliciesIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_policies/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a vendor policy
   */
  async vendorPoliciesIdDelete(
    requestParameters: VendorPoliciesIdDeleteRequest
  ): Promise<void> {
    await this.vendorPoliciesIdDeleteRaw(requestParameters);
  }

  /**
   * Get a vendor policy
   */
  async vendorPoliciesIdGetRaw(
    requestParameters: VendorPoliciesIdGetRequest
  ): Promise<runtime.ApiResponse<VendorPolicyDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorPoliciesIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.omitClauseText !== undefined) {
      queryParameters['omit_clause_text'] = requestParameters.omitClauseText;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_policies/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorPolicyDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Get a vendor policy
   */
  async vendorPoliciesIdGet(
    requestParameters: VendorPoliciesIdGetRequest
  ): Promise<VendorPolicyDetails> {
    const response = await this.vendorPoliciesIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update a vendor policy
   */
  async vendorPoliciesIdPatchRaw(
    requestParameters: VendorPoliciesIdPatchRequest
  ): Promise<runtime.ApiResponse<VendorPolicyDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorPoliciesIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_policies/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: VendorPolicyDetailsToJSON(requestParameters.vendorPolicy),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorPolicyDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Update a vendor policy
   */
  async vendorPoliciesIdPatch(
    requestParameters: VendorPoliciesIdPatchRequest
  ): Promise<VendorPolicyDetails> {
    const response = await this.vendorPoliciesIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a vendor policy
   */
  async vendorPoliciesIdPdfGetRaw(
    requestParameters: VendorPoliciesIdPdfGetRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorPoliciesIdPdfGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_policies/{id}/pdf`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Get a vendor policy
   */
  async vendorPoliciesIdPdfGet(
    requestParameters: VendorPoliciesIdPdfGetRequest
  ): Promise<void> {
    await this.vendorPoliciesIdPdfGetRaw(requestParameters);
  }

  /**
   * update VendorPolicy progress (cache) column
   */
  async vendorPoliciesIdProgressPatchRaw(
    requestParameters: VendorPoliciesIdProgressPatchRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorPoliciesIdProgressPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_policies/{id}/progress`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: VendorPolicyProgressToJSON(requestParameters.progress),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update VendorPolicy progress (cache) column
   */
  async vendorPoliciesIdProgressPatch(
    requestParameters: VendorPoliciesIdProgressPatchRequest
  ): Promise<void> {
    await this.vendorPoliciesIdProgressPatchRaw(requestParameters);
  }

  /**
   * publish vendor_policy
   */
  async vendorPoliciesIdPublishPostRaw(
    requestParameters: VendorPoliciesIdPublishPostRequest
  ): Promise<runtime.ApiResponse<VendorPolicyDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorPoliciesIdPublishPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_policies/{id}/publish`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VendorPolicyPublishToJSON(requestParameters.vendorPolicy),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorPolicyDetailsFromJSON(jsonValue)
    );
  }

  /**
   * publish vendor_policy
   */
  async vendorPoliciesIdPublishPost(
    requestParameters: VendorPoliciesIdPublishPostRequest
  ): Promise<VendorPolicyDetails> {
    const response =
      await this.vendorPoliciesIdPublishPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all policy clauses for the specified vendor policy
   */
  async vendorPoliciesIdVendorPolicyClausesGetRaw(
    requestParameters: VendorPoliciesIdVendorPolicyClausesGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorPolicyClause>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorPoliciesIdVendorPolicyClausesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_policies/{id}/vendor_policy_clauses`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorPolicyClauseFromJSON)
    );
  }

  /**
   * Get the list of all policy clauses for the specified vendor policy
   */
  async vendorPoliciesIdVendorPolicyClausesGet(
    requestParameters: VendorPoliciesIdVendorPolicyClausesGetRequest
  ): Promise<Array<VendorPolicyClause>> {
    const response =
      await this.vendorPoliciesIdVendorPolicyClausesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a vendor policy clause
   */
  async vendorPolicyClausesIdGetRaw(
    requestParameters: VendorPolicyClausesIdGetRequest
  ): Promise<runtime.ApiResponse<VendorPolicyClause>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorPolicyClausesIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_policy_clauses/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorPolicyClauseFromJSON(jsonValue)
    );
  }

  /**
   * Get a vendor policy clause
   */
  async vendorPolicyClausesIdGet(
    requestParameters: VendorPolicyClausesIdGetRequest
  ): Promise<VendorPolicyClause> {
    const response = await this.vendorPolicyClausesIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update a vendor policy clause
   */
  async vendorPolicyClausesIdPatchRaw(
    requestParameters: VendorPolicyClausesIdPatchRequest
  ): Promise<runtime.ApiResponse<VendorPolicyClause>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorPolicyClausesIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_policy_clauses/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: VendorPolicyClauseToJSON(requestParameters.vendorPolicyClause),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorPolicyClauseFromJSON(jsonValue)
    );
  }

  /**
   * Update a vendor policy clause
   */
  async vendorPolicyClausesIdPatch(
    requestParameters: VendorPolicyClausesIdPatchRequest
  ): Promise<VendorPolicyClause> {
    const response =
      await this.vendorPolicyClausesIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete a specific vendor supplier
   */
  async vendorSuppliersIdDeleteRaw(
    requestParameters: VendorSuppliersIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorSuppliersIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_suppliers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a specific vendor supplier
   */
  async vendorSuppliersIdDelete(
    requestParameters: VendorSuppliersIdDeleteRequest
  ): Promise<void> {
    await this.vendorSuppliersIdDeleteRaw(requestParameters);
  }

  /**
   * Get a specific vendor supplier
   */
  async vendorSuppliersIdGetRaw(
    requestParameters: VendorSuppliersIdGetRequest
  ): Promise<runtime.ApiResponse<VendorSupplierExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorSuppliersIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_suppliers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorSupplierExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get a specific vendor supplier
   */
  async vendorSuppliersIdGet(
    requestParameters: VendorSuppliersIdGetRequest
  ): Promise<VendorSupplierExtended> {
    const response = await this.vendorSuppliersIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update a specific vendor supplier
   */
  async vendorSuppliersIdPatchRaw(
    requestParameters: VendorSuppliersIdPatchRequest
  ): Promise<runtime.ApiResponse<VendorSupplierExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorSuppliersIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorSuppliersIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_suppliers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: VendorSupplierPayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorSupplierExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update a specific vendor supplier
   */
  async vendorSuppliersIdPatch(
    requestParameters: VendorSuppliersIdPatchRequest
  ): Promise<VendorSupplierExtended> {
    const response = await this.vendorSuppliersIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete a vendor team
   */
  async vendorTeamsIdDeleteRaw(
    requestParameters: VendorTeamsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorTeamsIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_teams/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a vendor team
   */
  async vendorTeamsIdDelete(
    requestParameters: VendorTeamsIdDeleteRequest
  ): Promise<void> {
    await this.vendorTeamsIdDeleteRaw(requestParameters);
  }

  /**
   * Get the details for one vendor team
   */
  async vendorTeamsIdGetRaw(
    requestParameters: VendorTeamsIdGetRequest
  ): Promise<runtime.ApiResponse<VendorTeamModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorTeamsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_teams/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorTeamModelFromJSON(jsonValue)
    );
  }

  /**
   * Get the details for one vendor team
   */
  async vendorTeamsIdGet(
    requestParameters: VendorTeamsIdGetRequest
  ): Promise<VendorTeamModel> {
    const response = await this.vendorTeamsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update a vendor team
   */
  async vendorTeamsIdPatchRaw(
    requestParameters: VendorTeamsIdPatchRequest
  ): Promise<runtime.ApiResponse<VendorTeamModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorTeamsIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_teams/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: VendorTeamModelToJSON(requestParameters.vendorTeam),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorTeamModelFromJSON(jsonValue)
    );
  }

  /**
   * Update a vendor team
   */
  async vendorTeamsIdPatch(
    requestParameters: VendorTeamsIdPatchRequest
  ): Promise<VendorTeamModel> {
    const response = await this.vendorTeamsIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete a vendor user
   */
  async vendorUsersIdDeleteRaw(
    requestParameters: VendorUsersIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorUsersIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a vendor user
   */
  async vendorUsersIdDelete(
    requestParameters: VendorUsersIdDeleteRequest
  ): Promise<void> {
    await this.vendorUsersIdDeleteRaw(requestParameters);
  }

  /**
   * Get details for one vendor user
   */
  async vendorUsersIdGetRaw(
    requestParameters: VendorUsersIdGetRequest
  ): Promise<runtime.ApiResponse<VendorUser>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorUsersIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorUserFromJSON(jsonValue)
    );
  }

  /**
   * Get details for one vendor user
   */
  async vendorUsersIdGet(
    requestParameters: VendorUsersIdGetRequest
  ): Promise<VendorUser> {
    const response = await this.vendorUsersIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update the details for a vendor user
   */
  async vendorUsersIdPatchRaw(
    requestParameters: VendorUsersIdPatchRequest
  ): Promise<runtime.ApiResponse<VendorUser>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorUsersIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: VendorUserUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorUserFromJSON(jsonValue)
    );
  }

  /**
   * Update the details for a vendor user
   */
  async vendorUsersIdPatch(
    requestParameters: VendorUsersIdPatchRequest
  ): Promise<VendorUser> {
    const response = await this.vendorUsersIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of Object the user was granted permissions
   */
  async vendorUsersIdPermissionsGetRaw(
    requestParameters: VendorUsersIdPermissionsGetRequest
  ): Promise<runtime.ApiResponse<SharedDocumentList>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorUsersIdPermissionsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_users/{id}/permissions`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SharedDocumentListFromJSON(jsonValue)
    );
  }

  /**
   * Get the list of Object the user was granted permissions
   */
  async vendorUsersIdPermissionsGet(
    requestParameters: VendorUsersIdPermissionsGetRequest
  ): Promise<SharedDocumentList> {
    const response =
      await this.vendorUsersIdPermissionsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of vendor user\'s notifications
   */
  async vendorUsersVendorUserIdNotificationsGetRaw(
    requestParameters: VendorUsersVendorUserIdNotificationsGetRequest
  ): Promise<runtime.ApiResponse<NotificationList>> {
    if (
      requestParameters.vendorUserId === null ||
      requestParameters.vendorUserId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorUserId',
        'Required parameter requestParameters.vendorUserId was null or undefined when calling vendorUsersVendorUserIdNotificationsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.showRead !== undefined) {
      queryParameters['show_read'] = requestParameters.showRead;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_users/{vendor_user_id}/notifications`.replace(
        `{${'vendor_user_id'}}`,
        encodeURIComponent(String(requestParameters.vendorUserId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      NotificationListFromJSON(jsonValue)
    );
  }

  /**
   * Get the list of vendor user\'s notifications
   */
  async vendorUsersVendorUserIdNotificationsGet(
    requestParameters: VendorUsersVendorUserIdNotificationsGetRequest
  ): Promise<NotificationList> {
    const response =
      await this.vendorUsersVendorUserIdNotificationsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of vendor user\'s reminders
   */
  async vendorUsersVendorUserIdRemindersGetRaw(
    requestParameters: VendorUsersVendorUserIdRemindersGetRequest
  ): Promise<runtime.ApiResponse<ReminderList>> {
    if (
      requestParameters.vendorUserId === null ||
      requestParameters.vendorUserId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorUserId',
        'Required parameter requestParameters.vendorUserId was null or undefined when calling vendorUsersVendorUserIdRemindersGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.relatedToType !== undefined) {
      queryParameters['related_to_type'] = requestParameters.relatedToType;
    }

    if (requestParameters.relatedToId !== undefined) {
      queryParameters['related_to_id'] = requestParameters.relatedToId;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_users/{vendor_user_id}/reminders`.replace(
        `{${'vendor_user_id'}}`,
        encodeURIComponent(String(requestParameters.vendorUserId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReminderListFromJSON(jsonValue)
    );
  }

  /**
   * Get the list of vendor user\'s reminders
   */
  async vendorUsersVendorUserIdRemindersGet(
    requestParameters: VendorUsersVendorUserIdRemindersGetRequest
  ): Promise<ReminderList> {
    const response =
      await this.vendorUsersVendorUserIdRemindersGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create reminder
   */
  async vendorUsersVendorUserIdRemindersPostRaw(
    requestParameters: VendorUsersVendorUserIdRemindersPostRequest
  ): Promise<runtime.ApiResponse<ReminderModel>> {
    if (
      requestParameters.vendorUserId === null ||
      requestParameters.vendorUserId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorUserId',
        'Required parameter requestParameters.vendorUserId was null or undefined when calling vendorUsersVendorUserIdRemindersPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_users/{vendor_user_id}/reminders`.replace(
        `{${'vendor_user_id'}}`,
        encodeURIComponent(String(requestParameters.vendorUserId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ReminderCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReminderModelFromJSON(jsonValue)
    );
  }

  /**
   * Create reminder
   */
  async vendorUsersVendorUserIdRemindersPost(
    requestParameters: VendorUsersVendorUserIdRemindersPostRequest
  ): Promise<ReminderModel> {
    const response =
      await this.vendorUsersVendorUserIdRemindersPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a list of all visible vendors for the current user
   */
  async vendorsGetRaw(): Promise<runtime.ApiResponse<Array<Vendor>>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorFromJSON)
    );
  }

  /**
   * Get a list of all visible vendors for the current user
   */
  async vendorsGet(): Promise<Array<Vendor>> {
    const response = await this.vendorsGetRaw();
    return await response.value();
  }

  /**
   * Get vendor agreements
   */
  async vendorsIdAgreementsGetRaw(
    requestParameters: VendorsIdAgreementsGetRequest
  ): Promise<runtime.ApiResponse<Array<AgreementModel>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdAgreementsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/agreements`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AgreementModelFromJSON)
    );
  }

  /**
   * Get vendor agreements
   */
  async vendorsIdAgreementsGet(
    requestParameters: VendorsIdAgreementsGetRequest
  ): Promise<Array<AgreementModel>> {
    const response = await this.vendorsIdAgreementsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor agreement
   */
  async vendorsIdAgreementsPostRaw(
    requestParameters: VendorsIdAgreementsPostRequest
  ): Promise<runtime.ApiResponse<AgreementExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdAgreementsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsIdAgreementsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}/agreements`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AgreementCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AgreementExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor agreement
   */
  async vendorsIdAgreementsPost(
    requestParameters: VendorsIdAgreementsPostRequest
  ): Promise<AgreementExtended> {
    const response = await this.vendorsIdAgreementsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Upload agreement
   */
  async vendorsIdAgreementsUploadPostRaw(
    requestParameters: VendorsIdAgreementsUploadPostRequest
  ): Promise<runtime.ApiResponse<AgreementExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdAgreementsUploadPost.'
      );
    }

    if (
      requestParameters.agreementName === null ||
      requestParameters.agreementName === undefined
    ) {
      throw new runtime.RequiredError(
        'agreementName',
        'Required parameter requestParameters.agreementName was null or undefined when calling vendorsIdAgreementsUploadPost.'
      );
    }

    if (
      requestParameters.agreementDocument === null ||
      requestParameters.agreementDocument === undefined
    ) {
      throw new runtime.RequiredError(
        'agreementDocument',
        'Required parameter requestParameters.agreementDocument was null or undefined when calling vendorsIdAgreementsUploadPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.agreementName !== undefined) {
      formParams.append(
        'agreement[name]',
        requestParameters.agreementName as any
      );
    }

    if (requestParameters.agreementDocument !== undefined) {
      formParams.append(
        'agreement[document]',
        requestParameters.agreementDocument as any
      );
    }

    if (requestParameters.agreementDescription !== undefined) {
      formParams.append(
        'agreement[description]',
        requestParameters.agreementDescription as any
      );
    }

    const response = await this.request({
      path: `/vendors/{id}/agreements/upload`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AgreementExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Upload agreement
   */
  async vendorsIdAgreementsUploadPost(
    requestParameters: VendorsIdAgreementsUploadPostRequest
  ): Promise<AgreementExtended> {
    const response =
      await this.vendorsIdAgreementsUploadPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete answers
   */
  async vendorsIdAnswersDeleteRaw(
    requestParameters: VendorsIdAnswersDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdAnswersDelete.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsIdAnswersDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}/answers`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      body: AnswerDeletePayloadToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete answers
   */
  async vendorsIdAnswersDelete(
    requestParameters: VendorsIdAnswersDeleteRequest
  ): Promise<void> {
    await this.vendorsIdAnswersDeleteRaw(requestParameters);
  }

  /**
   * Upsert completed answers to the server
   */
  async vendorsIdAnswersPostRaw(
    requestParameters: VendorsIdAnswersPostRequest
  ): Promise<runtime.ApiResponse<Array<Answer>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdAnswersPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}/answers`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: InlineObjectToJSON(requestParameters.answers),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AnswerFromJSON)
    );
  }

  /**
   * Upsert completed answers to the server
   */
  async vendorsIdAnswersPost(
    requestParameters: VendorsIdAnswersPostRequest
  ): Promise<Array<Answer>> {
    const response = await this.vendorsIdAnswersPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all assessment responses for the given vendor
   */
  async vendorsIdAssessmentResponsesGetRaw(
    requestParameters: VendorsIdAssessmentResponsesGetRequest
  ): Promise<runtime.ApiResponse<Array<AssessmentResponse>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdAssessmentResponsesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/assessment_responses`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AssessmentResponseFromJSON)
    );
  }

  /**
   * Get the list of all assessment responses for the given vendor
   */
  async vendorsIdAssessmentResponsesGet(
    requestParameters: VendorsIdAssessmentResponsesGetRequest
  ): Promise<Array<AssessmentResponse>> {
    const response =
      await this.vendorsIdAssessmentResponsesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all available assessments for the given vendor
   */
  async vendorsIdAssessmentsGetRaw(
    requestParameters: VendorsIdAssessmentsGetRequest
  ): Promise<runtime.ApiResponse<Array<Assessment>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdAssessmentsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/assessments`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AssessmentFromJSON)
    );
  }

  /**
   * Get the list of all available assessments for the given vendor
   */
  async vendorsIdAssessmentsGet(
    requestParameters: VendorsIdAssessmentsGetRequest
  ): Promise<Array<Assessment>> {
    const response = await this.vendorsIdAssessmentsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create an assessment from a template
   */
  async vendorsIdAssessmentsPostRaw(
    requestParameters: VendorsIdAssessmentsPostRequest
  ): Promise<runtime.ApiResponse<Assessment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdAssessmentsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsIdAssessmentsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}/assessments`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AssessmentCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssessmentFromJSON(jsonValue)
    );
  }

  /**
   * Create an assessment from a template
   */
  async vendorsIdAssessmentsPost(
    requestParameters: VendorsIdAssessmentsPostRequest
  ): Promise<Assessment> {
    const response = await this.vendorsIdAssessmentsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor checklist templates
   */
  async vendorsIdChecklistTemplatesGetRaw(
    requestParameters: VendorsIdChecklistTemplatesGetRequest
  ): Promise<runtime.ApiResponse<Array<ChecklistTemplate>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdChecklistTemplatesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/checklist_templates`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ChecklistTemplateFromJSON)
    );
  }

  /**
   * Get vendor checklist templates
   */
  async vendorsIdChecklistTemplatesGet(
    requestParameters: VendorsIdChecklistTemplatesGetRequest
  ): Promise<Array<ChecklistTemplate>> {
    const response =
      await this.vendorsIdChecklistTemplatesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor checklist templates
   */
  async vendorsIdChecklistTemplatesPostRaw(
    requestParameters: VendorsIdChecklistTemplatesPostRequest
  ): Promise<runtime.ApiResponse<ChecklistTemplateExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdChecklistTemplatesPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsIdChecklistTemplatesPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}/checklist_templates`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ChecklistTemplateCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ChecklistTemplateExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor checklist templates
   */
  async vendorsIdChecklistTemplatesPost(
    requestParameters: VendorsIdChecklistTemplatesPostRequest
  ): Promise<ChecklistTemplateExtended> {
    const response =
      await this.vendorsIdChecklistTemplatesPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor checklists
   */
  async vendorsIdChecklistsGetRaw(
    requestParameters: VendorsIdChecklistsGetRequest
  ): Promise<runtime.ApiResponse<Array<ChecklistExtended>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdChecklistsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/checklists`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ChecklistExtendedFromJSON)
    );
  }

  /**
   * Get vendor checklists
   */
  async vendorsIdChecklistsGet(
    requestParameters: VendorsIdChecklistsGetRequest
  ): Promise<Array<ChecklistExtended>> {
    const response = await this.vendorsIdChecklistsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor checklists
   */
  async vendorsIdChecklistsPostRaw(
    requestParameters: VendorsIdChecklistsPostRequest
  ): Promise<runtime.ApiResponse<ChecklistExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdChecklistsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsIdChecklistsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}/checklists`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ChecklistCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ChecklistExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor checklists
   */
  async vendorsIdChecklistsPost(
    requestParameters: VendorsIdChecklistsPostRequest
  ): Promise<ChecklistExtended> {
    const response = await this.vendorsIdChecklistsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get dashboard widgets
   */
  async vendorsIdDashboardSummaryGetRaw(
    requestParameters: VendorsIdDashboardSummaryGetRequest
  ): Promise<runtime.ApiResponse<DashboardSummary>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdDashboardSummaryGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/dashboard/summary`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      DashboardSummaryFromJSON(jsonValue)
    );
  }

  /**
   * Get dashboard widgets
   */
  async vendorsIdDashboardSummaryGet(
    requestParameters: VendorsIdDashboardSummaryGetRequest
  ): Promise<DashboardSummary> {
    const response =
      await this.vendorsIdDashboardSummaryGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get escrow accounts
   */
  async vendorsIdEscrowAccountsGetRaw(
    requestParameters: VendorsIdEscrowAccountsGetRequest
  ): Promise<runtime.ApiResponse<Array<EscrowAccount>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdEscrowAccountsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/escrow_accounts`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(EscrowAccountFromJSON)
    );
  }

  /**
   * Get escrow accounts
   */
  async vendorsIdEscrowAccountsGet(
    requestParameters: VendorsIdEscrowAccountsGetRequest
  ): Promise<Array<EscrowAccount>> {
    const response =
      await this.vendorsIdEscrowAccountsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor frameworks
   */
  async vendorsIdFrameworksGetRaw(
    requestParameters: VendorsIdFrameworksGetRequest
  ): Promise<runtime.ApiResponse<Array<FrameworkModel>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdFrameworksGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.includeStats !== undefined) {
      queryParameters['include_stats'] = requestParameters.includeStats;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/frameworks`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(FrameworkModelFromJSON)
    );
  }

  /**
   * Get vendor frameworks
   */
  async vendorsIdFrameworksGet(
    requestParameters: VendorsIdFrameworksGetRequest
  ): Promise<Array<FrameworkModel>> {
    const response = await this.vendorsIdFrameworksGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor framework
   */
  async vendorsIdFrameworksPostRaw(
    requestParameters: VendorsIdFrameworksPostRequest
  ): Promise<runtime.ApiResponse<FrameworkExtended>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdFrameworksPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsIdFrameworksPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}/frameworks`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: FrameworkCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      FrameworkExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor framework
   */
  async vendorsIdFrameworksPost(
    requestParameters: VendorsIdFrameworksPostRequest
  ): Promise<FrameworkExtended> {
    const response = await this.vendorsIdFrameworksPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the details for a particular vendor
   */
  async vendorsIdGetRaw(
    requestParameters: VendorsIdGetRequest
  ): Promise<runtime.ApiResponse<VendorDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Get the details for a particular vendor
   */
  async vendorsIdGet(
    requestParameters: VendorsIdGetRequest
  ): Promise<VendorDetails> {
    const response = await this.vendorsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a list of all invoices for a given vendor
   */
  async vendorsIdInvoicesGetRaw(
    requestParameters: VendorsIdInvoicesGetRequest
  ): Promise<runtime.ApiResponse<Array<Invoice>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdInvoicesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/invoices`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(InvoiceFromJSON)
    );
  }

  /**
   * Get a list of all invoices for a given vendor
   */
  async vendorsIdInvoicesGet(
    requestParameters: VendorsIdInvoicesGetRequest
  ): Promise<Array<Invoice>> {
    const response = await this.vendorsIdInvoicesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor logo urls
   */
  async vendorsIdLogoGetRaw(
    requestParameters: VendorsIdLogoGetRequest
  ): Promise<runtime.ApiResponse<Image>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdLogoGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/logo`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ImageFromJSON(jsonValue)
    );
  }

  /**
   * Get vendor logo urls
   */
  async vendorsIdLogoGet(
    requestParameters: VendorsIdLogoGetRequest
  ): Promise<Image> {
    const response = await this.vendorsIdLogoGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update vendor logo
   */
  async vendorsIdLogoPatchRaw(
    requestParameters: VendorsIdLogoPatchRequest
  ): Promise<runtime.ApiResponse<Image>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdLogoPatch.'
      );
    }

    if (
      requestParameters.image === null ||
      requestParameters.image === undefined
    ) {
      throw new runtime.RequiredError(
        'image',
        'Required parameter requestParameters.image was null or undefined when calling vendorsIdLogoPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request({
      path: `/vendors/{id}/logo`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ImageFromJSON(jsonValue)
    );
  }

  /**
   * Update vendor logo
   */
  async vendorsIdLogoPatch(
    requestParameters: VendorsIdLogoPatchRequest
  ): Promise<Image> {
    const response = await this.vendorsIdLogoPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor org chart urls
   */
  async vendorsIdOrgChartGetRaw(
    requestParameters: VendorsIdOrgChartGetRequest
  ): Promise<runtime.ApiResponse<Image>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdOrgChartGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/org_chart`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ImageFromJSON(jsonValue)
    );
  }

  /**
   * Get vendor org chart urls
   */
  async vendorsIdOrgChartGet(
    requestParameters: VendorsIdOrgChartGetRequest
  ): Promise<Image> {
    const response = await this.vendorsIdOrgChartGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update vendor org chart
   */
  async vendorsIdOrgChartPatchRaw(
    requestParameters: VendorsIdOrgChartPatchRequest
  ): Promise<runtime.ApiResponse<Image>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdOrgChartPatch.'
      );
    }

    if (
      requestParameters.image === null ||
      requestParameters.image === undefined
    ) {
      throw new runtime.RequiredError(
        'image',
        'Required parameter requestParameters.image was null or undefined when calling vendorsIdOrgChartPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request({
      path: `/vendors/{id}/org_chart`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ImageFromJSON(jsonValue)
    );
  }

  /**
   * Update vendor org chart
   */
  async vendorsIdOrgChartPatch(
    requestParameters: VendorsIdOrgChartPatchRequest
  ): Promise<Image> {
    const response = await this.vendorsIdOrgChartPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update the details for a particular vendor
   */
  async vendorsIdPatchRaw(
    requestParameters: VendorsIdPatchRequest
  ): Promise<runtime.ApiResponse<VendorDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: VendorToJSON(requestParameters.vendor),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Update the details for a particular vendor
   */
  async vendorsIdPatch(
    requestParameters: VendorsIdPatchRequest
  ): Promise<VendorDetails> {
    const response = await this.vendorsIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all policies with stats for one vendor
   */
  async vendorsIdPoliciesGetRaw(
    requestParameters: VendorsIdPoliciesGetRequest
  ): Promise<runtime.ApiResponse<Array<Policy>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdPoliciesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/policies`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(PolicyFromJSON)
    );
  }

  /**
   * Get the list of all policies with stats for one vendor
   */
  async vendorsIdPoliciesGet(
    requestParameters: VendorsIdPoliciesGetRequest
  ): Promise<Array<Policy>> {
    const response = await this.vendorsIdPoliciesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of products available for vendor
   */
  async vendorsIdProductsGetRaw(
    requestParameters: VendorsIdProductsGetRequest
  ): Promise<runtime.ApiResponse<VendorProductIndex>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdProductsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.queryProductType !== undefined) {
      queryParameters['query[product_type]'] =
        requestParameters.queryProductType;
    }

    if (requestParameters.queryShowUnavailable !== undefined) {
      queryParameters['query[show_unavailable]'] =
        requestParameters.queryShowUnavailable;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/products`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorProductIndexFromJSON(jsonValue)
    );
  }

  /**
   * Get the list of products available for vendor
   */
  async vendorsIdProductsGet(
    requestParameters: VendorsIdProductsGetRequest
  ): Promise<VendorProductIndex> {
    const response = await this.vendorsIdProductsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete Shares
   */
  async vendorsIdSharesDeleteRaw(
    requestParameters: VendorsIdSharesDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdSharesDelete.'
      );
    }

    if (
      requestParameters.shareableType === null ||
      requestParameters.shareableType === undefined
    ) {
      throw new runtime.RequiredError(
        'shareableType',
        'Required parameter requestParameters.shareableType was null or undefined when calling vendorsIdSharesDelete.'
      );
    }

    if (
      requestParameters.shareableId === null ||
      requestParameters.shareableId === undefined
    ) {
      throw new runtime.RequiredError(
        'shareableId',
        'Required parameter requestParameters.shareableId was null or undefined when calling vendorsIdSharesDelete.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsIdSharesDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.shareableType !== undefined) {
      queryParameters['shareable[type]'] = requestParameters.shareableType;
    }

    if (requestParameters.shareableId !== undefined) {
      queryParameters['shareable[id]'] = requestParameters.shareableId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}/shares`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      body: MultipleShareDeleteToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Shares
   */
  async vendorsIdSharesDelete(
    requestParameters: VendorsIdSharesDeleteRequest
  ): Promise<void> {
    await this.vendorsIdSharesDeleteRaw(requestParameters);
  }

  /**
   * Get the list all Vendor Users who can view Object
   */
  async vendorsIdSharesGetRaw(
    requestParameters: VendorsIdSharesGetRequest
  ): Promise<runtime.ApiResponse<SharesList>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdSharesGet.'
      );
    }

    if (
      requestParameters.shareableType === null ||
      requestParameters.shareableType === undefined
    ) {
      throw new runtime.RequiredError(
        'shareableType',
        'Required parameter requestParameters.shareableType was null or undefined when calling vendorsIdSharesGet.'
      );
    }

    if (
      requestParameters.shareableId === null ||
      requestParameters.shareableId === undefined
    ) {
      throw new runtime.RequiredError(
        'shareableId',
        'Required parameter requestParameters.shareableId was null or undefined when calling vendorsIdSharesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.shareableType !== undefined) {
      queryParameters['shareable[type]'] = requestParameters.shareableType;
    }

    if (requestParameters.shareableId !== undefined) {
      queryParameters['shareable[id]'] = requestParameters.shareableId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/shares`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SharesListFromJSON(jsonValue)
    );
  }

  /**
   * Get the list all Vendor Users who can view Object
   */
  async vendorsIdSharesGet(
    requestParameters: VendorsIdSharesGetRequest
  ): Promise<SharesList> {
    const response = await this.vendorsIdSharesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create Shares
   */
  async vendorsIdSharesPostRaw(
    requestParameters: VendorsIdSharesPostRequest
  ): Promise<runtime.ApiResponse<Array<ShareReceiver>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdSharesPost.'
      );
    }

    if (
      requestParameters.shareableType === null ||
      requestParameters.shareableType === undefined
    ) {
      throw new runtime.RequiredError(
        'shareableType',
        'Required parameter requestParameters.shareableType was null or undefined when calling vendorsIdSharesPost.'
      );
    }

    if (
      requestParameters.shareableId === null ||
      requestParameters.shareableId === undefined
    ) {
      throw new runtime.RequiredError(
        'shareableId',
        'Required parameter requestParameters.shareableId was null or undefined when calling vendorsIdSharesPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsIdSharesPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.shareableType !== undefined) {
      queryParameters['shareable[type]'] = requestParameters.shareableType;
    }

    if (requestParameters.shareableId !== undefined) {
      queryParameters['shareable[id]'] = requestParameters.shareableId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}/shares`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: MultipleShareToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ShareReceiverFromJSON)
    );
  }

  /**
   * Create Shares
   */
  async vendorsIdSharesPost(
    requestParameters: VendorsIdSharesPostRequest
  ): Promise<Array<ShareReceiver>> {
    const response = await this.vendorsIdSharesPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a list of Suggestion Vendor Users
   */
  async vendorsIdSharesSuggestionsGetRaw(
    requestParameters: VendorsIdSharesSuggestionsGetRequest
  ): Promise<runtime.ApiResponse<ShareSuggestionList>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdSharesSuggestionsGet.'
      );
    }

    if (
      requestParameters.shareableType === null ||
      requestParameters.shareableType === undefined
    ) {
      throw new runtime.RequiredError(
        'shareableType',
        'Required parameter requestParameters.shareableType was null or undefined when calling vendorsIdSharesSuggestionsGet.'
      );
    }

    if (
      requestParameters.shareableId === null ||
      requestParameters.shareableId === undefined
    ) {
      throw new runtime.RequiredError(
        'shareableId',
        'Required parameter requestParameters.shareableId was null or undefined when calling vendorsIdSharesSuggestionsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.shareableType !== undefined) {
      queryParameters['shareable[type]'] = requestParameters.shareableType;
    }

    if (requestParameters.shareableId !== undefined) {
      queryParameters['shareable[id]'] = requestParameters.shareableId;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage;
    }

    if (requestParameters.textSearch !== undefined) {
      queryParameters['text_search'] = requestParameters.textSearch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/shares/suggestions`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ShareSuggestionListFromJSON(jsonValue)
    );
  }

  /**
   * Get a list of Suggestion Vendor Users
   */
  async vendorsIdSharesSuggestionsGet(
    requestParameters: VendorsIdSharesSuggestionsGetRequest
  ): Promise<ShareSuggestionList> {
    const response =
      await this.vendorsIdSharesSuggestionsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a table of data for a particular vendor
   */
  async vendorsIdTablesGetRaw(
    requestParameters: VendorsIdTablesGetRequest
  ): Promise<runtime.ApiResponse<Table>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdTablesGet.'
      );
    }

    if (
      requestParameters.type === null ||
      requestParameters.type === undefined
    ) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter requestParameters.type was null or undefined when calling vendorsIdTablesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type;
    }

    if (requestParameters.statuses) {
      queryParameters['statuses[]'] = requestParameters.statuses;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/tables`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TableFromJSON(jsonValue)
    );
  }

  /**
   * Get a table of data for a particular vendor
   */
  async vendorsIdTablesGet(
    requestParameters: VendorsIdTablesGetRequest
  ): Promise<Table> {
    const response = await this.vendorsIdTablesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor and his descendants
   */
  async vendorsIdTreeGetRaw(
    requestParameters: VendorsIdTreeGetRequest
  ): Promise<runtime.ApiResponse<VendorTreeNodeModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdTreeGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/tree`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorTreeNodeModelFromJSON(jsonValue)
    );
  }

  /**
   * Get vendor and his descendants
   */
  async vendorsIdTreeGet(
    requestParameters: VendorsIdTreeGetRequest
  ): Promise<VendorTreeNodeModel> {
    const response = await this.vendorsIdTreeGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all documents for one vendor
   */
  async vendorsIdVendorDocumentsGetRaw(
    requestParameters: VendorsIdVendorDocumentsGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorDocument>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdVendorDocumentsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.queryDocumentType !== undefined) {
      queryParameters['query[document_type]'] =
        requestParameters.queryDocumentType;
    }

    if (requestParameters.queryShowHistory !== undefined) {
      queryParameters['query[show_history]'] =
        requestParameters.queryShowHistory;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/vendor_documents`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorDocumentFromJSON)
    );
  }

  /**
   * Get the list of all documents for one vendor
   */
  async vendorsIdVendorDocumentsGet(
    requestParameters: VendorsIdVendorDocumentsGetRequest
  ): Promise<Array<VendorDocument>> {
    const response =
      await this.vendorsIdVendorDocumentsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all policies for the current vendor
   */
  async vendorsIdVendorPoliciesGetRaw(
    requestParameters: VendorsIdVendorPoliciesGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorPolicyDetails>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdVendorPoliciesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/vendor_policies`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorPolicyDetailsFromJSON)
    );
  }

  /**
   * Get the list of all policies for the current vendor
   */
  async vendorsIdVendorPoliciesGet(
    requestParameters: VendorsIdVendorPoliciesGetRequest
  ): Promise<Array<VendorPolicyDetails>> {
    const response =
      await this.vendorsIdVendorPoliciesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create a new vendor policy
   */
  async vendorsIdVendorPoliciesPostRaw(
    requestParameters: VendorsIdVendorPoliciesPostRequest
  ): Promise<runtime.ApiResponse<VendorPolicy>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdVendorPoliciesPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}/vendor_policies`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VendorPolicyToJSON(requestParameters.vendorPolicy),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorPolicyFromJSON(jsonValue)
    );
  }

  /**
   * Create a new vendor policy
   */
  async vendorsIdVendorPoliciesPost(
    requestParameters: VendorsIdVendorPoliciesPostRequest
  ): Promise<VendorPolicy> {
    const response =
      await this.vendorsIdVendorPoliciesPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all teams for one vendor
   */
  async vendorsIdVendorTeamsGetRaw(
    requestParameters: VendorsIdVendorTeamsGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorTeamModel>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdVendorTeamsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query;
    }

    if (requestParameters.viewExtended !== undefined) {
      queryParameters['view[extended]'] = requestParameters.viewExtended;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/vendor_teams`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorTeamModelFromJSON)
    );
  }

  /**
   * Get the list of all teams for one vendor
   */
  async vendorsIdVendorTeamsGet(
    requestParameters: VendorsIdVendorTeamsGetRequest
  ): Promise<Array<VendorTeamModel>> {
    const response = await this.vendorsIdVendorTeamsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Upsert vendor teams
   */
  async vendorsIdVendorTeamsPostRaw(
    requestParameters: VendorsIdVendorTeamsPostRequest
  ): Promise<runtime.ApiResponse<Array<VendorTeamModel>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdVendorTeamsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}/vendor_teams`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VendorTeamUpsertPayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorTeamModelFromJSON)
    );
  }

  /**
   * Upsert vendor teams
   */
  async vendorsIdVendorTeamsPost(
    requestParameters: VendorsIdVendorTeamsPostRequest
  ): Promise<Array<VendorTeamModel>> {
    const response = await this.vendorsIdVendorTeamsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetch hubspot token
   */
  async vendorsIdVendorUsersChatWidgetLoginPostRaw(
    requestParameters: VendorsIdVendorUsersChatWidgetLoginPostRequest
  ): Promise<runtime.ApiResponse<ChatWidgetLogin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdVendorUsersChatWidgetLoginPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/vendor_users/chat_widget_login`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ChatWidgetLoginFromJSON(jsonValue)
    );
  }

  /**
   * Fetch hubspot token
   */
  async vendorsIdVendorUsersChatWidgetLoginPost(
    requestParameters: VendorsIdVendorUsersChatWidgetLoginPostRequest
  ): Promise<ChatWidgetLogin> {
    const response =
      await this.vendorsIdVendorUsersChatWidgetLoginPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all users for one vendor
   */
  async vendorsIdVendorUsersGetRaw(
    requestParameters: VendorsIdVendorUsersGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorUser>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdVendorUsersGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.queryRoles) {
      queryParameters['query[roles][]'] = requestParameters.queryRoles;
    }

    if (requestParameters.queryUserTypes) {
      queryParameters['query[user_types][]'] = requestParameters.queryUserTypes;
    }

    if (requestParameters.queryEmploymentStatuses) {
      queryParameters['query[employment_statuses][]'] =
        requestParameters.queryEmploymentStatuses;
    }

    if (requestParameters.queryStatuses) {
      queryParameters['query[statuses][]'] = requestParameters.queryStatuses;
    }

    if (requestParameters.includeComplianceStats !== undefined) {
      queryParameters['include_compliance_stats'] =
        requestParameters.includeComplianceStats;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/vendor_users`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorUserFromJSON)
    );
  }

  /**
   * Get the list of all users for one vendor
   */
  async vendorsIdVendorUsersGet(
    requestParameters: VendorsIdVendorUsersGetRequest
  ): Promise<Array<VendorUser>> {
    const response = await this.vendorsIdVendorUsersGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor users
   */
  async vendorsIdVendorUsersPostRaw(
    requestParameters: VendorsIdVendorUsersPostRequest
  ): Promise<runtime.ApiResponse<Array<VendorUser>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdVendorUsersPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{id}/vendor_users`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VendorUserCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorUserFromJSON)
    );
  }

  /**
   * Create vendor users
   */
  async vendorsIdVendorUsersPost(
    requestParameters: VendorsIdVendorUsersPostRequest
  ): Promise<Array<VendorUser>> {
    const response = await this.vendorsIdVendorUsersPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create or find Trusthub vendor user and emulates his login
   */
  async vendorsIdVendorUsersTrusthubLoginPostRaw(
    requestParameters: VendorsIdVendorUsersTrusthubLoginPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling vendorsIdVendorUsersTrusthubLoginPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{id}/vendor_users/trusthub_login`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create or find Trusthub vendor user and emulates his login
   */
  async vendorsIdVendorUsersTrusthubLoginPost(
    requestParameters: VendorsIdVendorUsersTrusthubLoginPostRequest
  ): Promise<void> {
    await this.vendorsIdVendorUsersTrusthubLoginPostRaw(requestParameters);
  }

  /**
   * Create a new vendor
   */
  async vendorsPostRaw(
    requestParameters: VendorsPostRequest
  ): Promise<runtime.ApiResponse<VendorDetails>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VendorsCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Create a new vendor
   */
  async vendorsPost(
    requestParameters: VendorsPostRequest
  ): Promise<VendorDetails> {
    const response = await this.vendorsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor assertion runs
   */
  async vendorsVendorIdAssertionRunsGetRaw(
    requestParameters: VendorsVendorIdAssertionRunsGetRequest
  ): Promise<runtime.ApiResponse<AssertionRunList>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdAssertionRunsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.assertionId !== undefined) {
      queryParameters['assertion_id'] = requestParameters.assertionId;
    }

    if (requestParameters.assertionName !== undefined) {
      queryParameters['assertion_name'] = requestParameters.assertionName;
    }

    if (requestParameters.assertionCategoryIdentifier !== undefined) {
      queryParameters['assertion_category_identifier'] =
        requestParameters.assertionCategoryIdentifier;
    }

    if (requestParameters.assertionStatus !== undefined) {
      queryParameters['assertion_status'] = requestParameters.assertionStatus;
    }

    if (requestParameters.showManual !== undefined) {
      queryParameters['show_manual'] = requestParameters.showManual;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/assertion_runs`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssertionRunListFromJSON(jsonValue)
    );
  }

  /**
   * Get vendor assertion runs
   */
  async vendorsVendorIdAssertionRunsGet(
    requestParameters: VendorsVendorIdAssertionRunsGetRequest
  ): Promise<AssertionRunList> {
    const response =
      await this.vendorsVendorIdAssertionRunsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor assertion
   */
  async vendorsVendorIdAssertionsPostRaw(
    requestParameters: VendorsVendorIdAssertionsPostRequest
  ): Promise<runtime.ApiResponse<AssertionExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdAssertionsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdAssertionsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/assertions`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AssertionCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AssertionExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor assertion
   */
  async vendorsVendorIdAssertionsPost(
    requestParameters: VendorsVendorIdAssertionsPostRequest
  ): Promise<AssertionExtended> {
    const response =
      await this.vendorsVendorIdAssertionsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the list of all available assessment_templates for the given vendor
   */
  async vendorsVendorIdAssessmentTemplatesGetRaw(
    requestParameters: VendorsVendorIdAssessmentTemplatesGetRequest
  ): Promise<runtime.ApiResponse<Array<AssessmentTemplateModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdAssessmentTemplatesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/assessment_templates`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AssessmentTemplateModelFromJSON)
    );
  }

  /**
   * Get the list of all available assessment_templates for the given vendor
   */
  async vendorsVendorIdAssessmentTemplatesGet(
    requestParameters: VendorsVendorIdAssessmentTemplatesGetRequest
  ): Promise<Array<AssessmentTemplateModel>> {
    const response =
      await this.vendorsVendorIdAssessmentTemplatesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor audits
   */
  async vendorsVendorIdAuditsGetRaw(
    requestParameters: VendorsVendorIdAuditsGetRequest
  ): Promise<runtime.ApiResponse<Array<AuditModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdAuditsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/audits`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AuditModelFromJSON)
    );
  }

  /**
   * Get vendor audits
   */
  async vendorsVendorIdAuditsGet(
    requestParameters: VendorsVendorIdAuditsGetRequest
  ): Promise<Array<AuditModel>> {
    const response = await this.vendorsVendorIdAuditsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor audit
   */
  async vendorsVendorIdAuditsPostRaw(
    requestParameters: VendorsVendorIdAuditsPostRequest
  ): Promise<runtime.ApiResponse<AuditExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdAuditsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdAuditsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/audits`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AuditCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AuditExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor audit
   */
  async vendorsVendorIdAuditsPost(
    requestParameters: VendorsVendorIdAuditsPostRequest
  ): Promise<AuditExtended> {
    const response = await this.vendorsVendorIdAuditsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Upload meeting
   */
  async vendorsVendorIdAuditsUploadMeetingPostRaw(
    requestParameters: VendorsVendorIdAuditsUploadMeetingPostRequest
  ): Promise<runtime.ApiResponse<AuditModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdAuditsUploadMeetingPost.'
      );
    }

    if (
      requestParameters.auditName === null ||
      requestParameters.auditName === undefined
    ) {
      throw new runtime.RequiredError(
        'auditName',
        'Required parameter requestParameters.auditName was null or undefined when calling vendorsVendorIdAuditsUploadMeetingPost.'
      );
    }

    if (
      requestParameters.auditOwnerId === null ||
      requestParameters.auditOwnerId === undefined
    ) {
      throw new runtime.RequiredError(
        'auditOwnerId',
        'Required parameter requestParameters.auditOwnerId was null or undefined when calling vendorsVendorIdAuditsUploadMeetingPost.'
      );
    }

    if (
      requestParameters.auditFile === null ||
      requestParameters.auditFile === undefined
    ) {
      throw new runtime.RequiredError(
        'auditFile',
        'Required parameter requestParameters.auditFile was null or undefined when calling vendorsVendorIdAuditsUploadMeetingPost.'
      );
    }

    if (
      requestParameters.auditStartedAt === null ||
      requestParameters.auditStartedAt === undefined
    ) {
      throw new runtime.RequiredError(
        'auditStartedAt',
        'Required parameter requestParameters.auditStartedAt was null or undefined when calling vendorsVendorIdAuditsUploadMeetingPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.auditName !== undefined) {
      formParams.append('audit[name]', requestParameters.auditName as any);
    }

    if (requestParameters.auditOwnerId !== undefined) {
      formParams.append(
        'audit[owner_id]',
        requestParameters.auditOwnerId as any
      );
    }

    if (requestParameters.auditFile !== undefined) {
      formParams.append('audit[file]', requestParameters.auditFile as any);
    }

    if (requestParameters.auditStartedAt !== undefined) {
      formParams.append(
        'audit[started_at]',
        requestParameters.auditStartedAt as any
      );
    }

    const response = await this.request({
      path: `/vendors/{vendor_id}/audits/upload_meeting`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AuditModelFromJSON(jsonValue)
    );
  }

  /**
   * Upload meeting
   */
  async vendorsVendorIdAuditsUploadMeetingPost(
    requestParameters: VendorsVendorIdAuditsUploadMeetingPostRequest
  ): Promise<AuditModel> {
    const response =
      await this.vendorsVendorIdAuditsUploadMeetingPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Upload vendor audit
   */
  async vendorsVendorIdAuditsUploadPostRaw(
    requestParameters: VendorsVendorIdAuditsUploadPostRequest
  ): Promise<runtime.ApiResponse<AuditModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdAuditsUploadPost.'
      );
    }

    if (
      requestParameters.auditName === null ||
      requestParameters.auditName === undefined
    ) {
      throw new runtime.RequiredError(
        'auditName',
        'Required parameter requestParameters.auditName was null or undefined when calling vendorsVendorIdAuditsUploadPost.'
      );
    }

    if (
      requestParameters.auditOrigin === null ||
      requestParameters.auditOrigin === undefined
    ) {
      throw new runtime.RequiredError(
        'auditOrigin',
        'Required parameter requestParameters.auditOrigin was null or undefined when calling vendorsVendorIdAuditsUploadPost.'
      );
    }

    if (
      requestParameters.auditOwnerId === null ||
      requestParameters.auditOwnerId === undefined
    ) {
      throw new runtime.RequiredError(
        'auditOwnerId',
        'Required parameter requestParameters.auditOwnerId was null or undefined when calling vendorsVendorIdAuditsUploadPost.'
      );
    }

    if (
      requestParameters.auditFile === null ||
      requestParameters.auditFile === undefined
    ) {
      throw new runtime.RequiredError(
        'auditFile',
        'Required parameter requestParameters.auditFile was null or undefined when calling vendorsVendorIdAuditsUploadPost.'
      );
    }

    if (
      requestParameters.auditStartedAt === null ||
      requestParameters.auditStartedAt === undefined
    ) {
      throw new runtime.RequiredError(
        'auditStartedAt',
        'Required parameter requestParameters.auditStartedAt was null or undefined when calling vendorsVendorIdAuditsUploadPost.'
      );
    }

    if (
      requestParameters.auditCompletedAt === null ||
      requestParameters.auditCompletedAt === undefined
    ) {
      throw new runtime.RequiredError(
        'auditCompletedAt',
        'Required parameter requestParameters.auditCompletedAt was null or undefined when calling vendorsVendorIdAuditsUploadPost.'
      );
    }

    if (
      requestParameters.auditRating === null ||
      requestParameters.auditRating === undefined
    ) {
      throw new runtime.RequiredError(
        'auditRating',
        'Required parameter requestParameters.auditRating was null or undefined when calling vendorsVendorIdAuditsUploadPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.auditName !== undefined) {
      formParams.append('audit[name]', requestParameters.auditName as any);
    }

    if (requestParameters.auditOrigin !== undefined) {
      formParams.append('audit[origin]', requestParameters.auditOrigin as any);
    }

    if (requestParameters.auditOwnerId !== undefined) {
      formParams.append(
        'audit[owner_id]',
        requestParameters.auditOwnerId as any
      );
    }

    if (requestParameters.auditFile !== undefined) {
      formParams.append('audit[file]', requestParameters.auditFile as any);
    }

    if (requestParameters.auditStartedAt !== undefined) {
      formParams.append(
        'audit[started_at]',
        requestParameters.auditStartedAt as any
      );
    }

    if (requestParameters.auditCompletedAt !== undefined) {
      formParams.append(
        'audit[completed_at]',
        requestParameters.auditCompletedAt as any
      );
    }

    if (requestParameters.auditRating !== undefined) {
      formParams.append('audit[rating]', requestParameters.auditRating as any);
    }

    const response = await this.request({
      path: `/vendors/{vendor_id}/audits/upload`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AuditModelFromJSON(jsonValue)
    );
  }

  /**
   * Upload vendor audit
   */
  async vendorsVendorIdAuditsUploadPost(
    requestParameters: VendorsVendorIdAuditsUploadPostRequest
  ): Promise<AuditModel> {
    const response =
      await this.vendorsVendorIdAuditsUploadPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get business functions
   */
  async vendorsVendorIdBusinessFunctionsGetRaw(
    requestParameters: VendorsVendorIdBusinessFunctionsGetRequest
  ): Promise<runtime.ApiResponse<Array<BusinessFunctionModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdBusinessFunctionsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/business_functions`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(BusinessFunctionModelFromJSON)
    );
  }

  /**
   * Get business functions
   */
  async vendorsVendorIdBusinessFunctionsGet(
    requestParameters: VendorsVendorIdBusinessFunctionsGetRequest
  ): Promise<Array<BusinessFunctionModel>> {
    const response =
      await this.vendorsVendorIdBusinessFunctionsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create business function
   */
  async vendorsVendorIdBusinessFunctionsPostRaw(
    requestParameters: VendorsVendorIdBusinessFunctionsPostRequest
  ): Promise<runtime.ApiResponse<BusinessFunctionModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdBusinessFunctionsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdBusinessFunctionsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/business_functions`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: BusinessFunctionCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BusinessFunctionModelFromJSON(jsonValue)
    );
  }

  /**
   * Create business function
   */
  async vendorsVendorIdBusinessFunctionsPost(
    requestParameters: VendorsVendorIdBusinessFunctionsPostRequest
  ): Promise<BusinessFunctionModel> {
    const response =
      await this.vendorsVendorIdBusinessFunctionsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor calendar items
   */
  async vendorsVendorIdCalendarGetRaw(
    requestParameters: VendorsVendorIdCalendarGetRequest
  ): Promise<runtime.ApiResponse<CalendarItemList>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdCalendarGet.'
      );
    }

    if (
      requestParameters.startDate === null ||
      requestParameters.startDate === undefined
    ) {
      throw new runtime.RequiredError(
        'startDate',
        'Required parameter requestParameters.startDate was null or undefined when calling vendorsVendorIdCalendarGet.'
      );
    }

    if (
      requestParameters.endDate === null ||
      requestParameters.endDate === undefined
    ) {
      throw new runtime.RequiredError(
        'endDate',
        'Required parameter requestParameters.endDate was null or undefined when calling vendorsVendorIdCalendarGet.'
      );
    }

    if (
      requestParameters.viewMode === null ||
      requestParameters.viewMode === undefined
    ) {
      throw new runtime.RequiredError(
        'viewMode',
        'Required parameter requestParameters.viewMode was null or undefined when calling vendorsVendorIdCalendarGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.startDate !== undefined) {
      queryParameters['start_date'] = requestParameters.startDate;
    }

    if (requestParameters.endDate !== undefined) {
      queryParameters['end_date'] = requestParameters.endDate;
    }

    if (requestParameters.statuses) {
      queryParameters['statuses[]'] = requestParameters.statuses;
    }

    if (requestParameters.viewMode !== undefined) {
      queryParameters['view_mode'] = requestParameters.viewMode;
    }

    if (requestParameters.ownerIds) {
      queryParameters['owner_ids[]'] = requestParameters.ownerIds;
    }

    if (requestParameters.textSearch !== undefined) {
      queryParameters['text_search'] = requestParameters.textSearch;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/calendar`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CalendarItemListFromJSON(jsonValue)
    );
  }

  /**
   * Get vendor calendar items
   */
  async vendorsVendorIdCalendarGet(
    requestParameters: VendorsVendorIdCalendarGetRequest
  ): Promise<CalendarItemList> {
    const response =
      await this.vendorsVendorIdCalendarGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor certificates
   */
  async vendorsVendorIdCertificatesGetRaw(
    requestParameters: VendorsVendorIdCertificatesGetRequest
  ): Promise<runtime.ApiResponse<Array<CertificateModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdCertificatesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/certificates`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(CertificateModelFromJSON)
    );
  }

  /**
   * Get vendor certificates
   */
  async vendorsVendorIdCertificatesGet(
    requestParameters: VendorsVendorIdCertificatesGetRequest
  ): Promise<Array<CertificateModel>> {
    const response =
      await this.vendorsVendorIdCertificatesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create certificate
   */
  async vendorsVendorIdCertificatesPostRaw(
    requestParameters: VendorsVendorIdCertificatesPostRequest
  ): Promise<runtime.ApiResponse<CertificateModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdCertificatesPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.attachment !== undefined) {
      formParams.append('attachment', requestParameters.attachment as any);
    }

    if (requestParameters.certificateType !== undefined) {
      formParams.append(
        'certificate_type',
        requestParameters.certificateType as any
      );
    }

    if (requestParameters.expireAt !== undefined) {
      formParams.append('expire_at', requestParameters.expireAt as any);
    }

    const response = await this.request({
      path: `/vendors/{vendor_id}/certificates`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CertificateModelFromJSON(jsonValue)
    );
  }

  /**
   * Create certificate
   */
  async vendorsVendorIdCertificatesPost(
    requestParameters: VendorsVendorIdCertificatesPostRequest
  ): Promise<CertificateModel> {
    const response =
      await this.vendorsVendorIdCertificatesPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor control categories
   */
  async vendorsVendorIdControlCategoriesGetRaw(
    requestParameters: VendorsVendorIdControlCategoriesGetRequest
  ): Promise<runtime.ApiResponse<Array<ControlCategoryExtended>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdControlCategoriesGet.'
      );
    }

    if (
      requestParameters.frameworkId === null ||
      requestParameters.frameworkId === undefined
    ) {
      throw new runtime.RequiredError(
        'frameworkId',
        'Required parameter requestParameters.frameworkId was null or undefined when calling vendorsVendorIdControlCategoriesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.frameworkId !== undefined) {
      queryParameters['framework_id'] = requestParameters.frameworkId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/control_categories`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ControlCategoryExtendedFromJSON)
    );
  }

  /**
   * Get vendor control categories
   */
  async vendorsVendorIdControlCategoriesGet(
    requestParameters: VendorsVendorIdControlCategoriesGetRequest
  ): Promise<Array<ControlCategoryExtended>> {
    const response =
      await this.vendorsVendorIdControlCategoriesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor controls
   */
  async vendorsVendorIdControlsGetRaw(
    requestParameters: VendorsVendorIdControlsGetRequest
  ): Promise<runtime.ApiResponse<Array<ControlModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdControlsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.frameworkId !== undefined) {
      queryParameters['framework_id'] = requestParameters.frameworkId;
    }

    if (requestParameters.queryApplicable !== undefined) {
      queryParameters['query[applicable]'] = requestParameters.queryApplicable;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/controls`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ControlModelFromJSON)
    );
  }

  /**
   * Get vendor controls
   */
  async vendorsVendorIdControlsGet(
    requestParameters: VendorsVendorIdControlsGetRequest
  ): Promise<Array<ControlModel>> {
    const response =
      await this.vendorsVendorIdControlsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor control
   */
  async vendorsVendorIdControlsPostRaw(
    requestParameters: VendorsVendorIdControlsPostRequest
  ): Promise<runtime.ApiResponse<ControlExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdControlsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdControlsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/controls`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ControlCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ControlExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor control
   */
  async vendorsVendorIdControlsPost(
    requestParameters: VendorsVendorIdControlsPostRequest
  ): Promise<ControlExtended> {
    const response =
      await this.vendorsVendorIdControlsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create corrective action
   */
  async vendorsVendorIdCorrectiveActionsPostRaw(
    requestParameters: VendorsVendorIdCorrectiveActionsPostRequest
  ): Promise<runtime.ApiResponse<CorrectiveActionExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdCorrectiveActionsPost.'
      );
    }

    if (
      requestParameters.correctiveActionName === null ||
      requestParameters.correctiveActionName === undefined
    ) {
      throw new runtime.RequiredError(
        'correctiveActionName',
        'Required parameter requestParameters.correctiveActionName was null or undefined when calling vendorsVendorIdCorrectiveActionsPost.'
      );
    }

    if (
      requestParameters.correctiveActionDescription === null ||
      requestParameters.correctiveActionDescription === undefined
    ) {
      throw new runtime.RequiredError(
        'correctiveActionDescription',
        'Required parameter requestParameters.correctiveActionDescription was null or undefined when calling vendorsVendorIdCorrectiveActionsPost.'
      );
    }

    if (
      requestParameters.correctiveActionDateIdentified === null ||
      requestParameters.correctiveActionDateIdentified === undefined
    ) {
      throw new runtime.RequiredError(
        'correctiveActionDateIdentified',
        'Required parameter requestParameters.correctiveActionDateIdentified was null or undefined when calling vendorsVendorIdCorrectiveActionsPost.'
      );
    }

    if (
      requestParameters.correctiveActionRaisedBy === null ||
      requestParameters.correctiveActionRaisedBy === undefined
    ) {
      throw new runtime.RequiredError(
        'correctiveActionRaisedBy',
        'Required parameter requestParameters.correctiveActionRaisedBy was null or undefined when calling vendorsVendorIdCorrectiveActionsPost.'
      );
    }

    if (
      requestParameters.correctiveActionRootCause === null ||
      requestParameters.correctiveActionRootCause === undefined
    ) {
      throw new runtime.RequiredError(
        'correctiveActionRootCause',
        'Required parameter requestParameters.correctiveActionRootCause was null or undefined when calling vendorsVendorIdCorrectiveActionsPost.'
      );
    }

    if (
      requestParameters.correctiveActionActionType === null ||
      requestParameters.correctiveActionActionType === undefined
    ) {
      throw new runtime.RequiredError(
        'correctiveActionActionType',
        'Required parameter requestParameters.correctiveActionActionType was null or undefined when calling vendorsVendorIdCorrectiveActionsPost.'
      );
    }

    if (
      requestParameters.correctiveActionCorrectiveActions === null ||
      requestParameters.correctiveActionCorrectiveActions === undefined
    ) {
      throw new runtime.RequiredError(
        'correctiveActionCorrectiveActions',
        'Required parameter requestParameters.correctiveActionCorrectiveActions was null or undefined when calling vendorsVendorIdCorrectiveActionsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.correctiveActionName !== undefined) {
      formParams.append(
        'corrective_action[name]',
        requestParameters.correctiveActionName as any
      );
    }

    if (requestParameters.correctiveActionDescription !== undefined) {
      formParams.append(
        'corrective_action[description]',
        requestParameters.correctiveActionDescription as any
      );
    }

    if (requestParameters.correctiveActionIdentifier !== undefined) {
      formParams.append(
        'corrective_action[identifier]',
        requestParameters.correctiveActionIdentifier as any
      );
    }

    if (requestParameters.correctiveActionDateIdentified !== undefined) {
      formParams.append(
        'corrective_action[date_identified]',
        requestParameters.correctiveActionDateIdentified as any
      );
    }

    if (requestParameters.correctiveActionRaisedBy !== undefined) {
      formParams.append(
        'corrective_action[raised_by]',
        requestParameters.correctiveActionRaisedBy as any
      );
    }

    if (requestParameters.correctiveActionRootCause !== undefined) {
      formParams.append(
        'corrective_action[root_cause]',
        requestParameters.correctiveActionRootCause as any
      );
    }

    if (requestParameters.correctiveActionOwnerId !== undefined) {
      formParams.append(
        'corrective_action[owner_id]',
        requestParameters.correctiveActionOwnerId as any
      );
    }

    if (requestParameters.correctiveActionUrl !== undefined) {
      formParams.append(
        'corrective_action[url]',
        requestParameters.correctiveActionUrl as any
      );
    }

    if (requestParameters.correctiveActionAttachments) {
      formParams.append(
        'corrective_action[attachments]',
        requestParameters.correctiveActionAttachments.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    if (requestParameters.correctiveActionActionType !== undefined) {
      formParams.append(
        'corrective_action[action_type]',
        requestParameters.correctiveActionActionType as any
      );
    }

    if (requestParameters.correctiveActionCorrectiveActions !== undefined) {
      formParams.append(
        'corrective_action[corrective_actions]',
        requestParameters.correctiveActionCorrectiveActions as any
      );
    }

    const response = await this.request({
      path: `/vendors/{vendor_id}/corrective_actions`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CorrectiveActionExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create corrective action
   */
  async vendorsVendorIdCorrectiveActionsPost(
    requestParameters: VendorsVendorIdCorrectiveActionsPostRequest
  ): Promise<CorrectiveActionExtended> {
    const response =
      await this.vendorsVendorIdCorrectiveActionsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete Data Room entities
   */
  async vendorsVendorIdDataRoomEntitiesDeleteRaw(
    requestParameters: VendorsVendorIdDataRoomEntitiesDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdDataRoomEntitiesDelete.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdDataRoomEntitiesDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/data_room/entities`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      body: DataRoomEntityDeletePayloadToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Data Room entities
   */
  async vendorsVendorIdDataRoomEntitiesDelete(
    requestParameters: VendorsVendorIdDataRoomEntitiesDeleteRequest
  ): Promise<void> {
    await this.vendorsVendorIdDataRoomEntitiesDeleteRaw(requestParameters);
  }

  /**
   * Get Data Room entities accessible by current user
   */
  async vendorsVendorIdDataRoomEntitiesGetRaw(
    requestParameters: VendorsVendorIdDataRoomEntitiesGetRequest
  ): Promise<runtime.ApiResponse<Array<DataRoomEntityModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdDataRoomEntitiesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/data_room/entities`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(DataRoomEntityModelFromJSON)
    );
  }

  /**
   * Get Data Room entities accessible by current user
   */
  async vendorsVendorIdDataRoomEntitiesGet(
    requestParameters: VendorsVendorIdDataRoomEntitiesGetRequest
  ): Promise<Array<DataRoomEntityModel>> {
    const response =
      await this.vendorsVendorIdDataRoomEntitiesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Move Data Room entities
   */
  async vendorsVendorIdDataRoomEntitiesMovePatchRaw(
    requestParameters: VendorsVendorIdDataRoomEntitiesMovePatchRequest
  ): Promise<runtime.ApiResponse<Array<DataRoomEntityModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdDataRoomEntitiesMovePatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdDataRoomEntitiesMovePatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/data_room/entities/move`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: DataRoomEntityMovePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(DataRoomEntityModelFromJSON)
    );
  }

  /**
   * Move Data Room entities
   */
  async vendorsVendorIdDataRoomEntitiesMovePatch(
    requestParameters: VendorsVendorIdDataRoomEntitiesMovePatchRequest
  ): Promise<Array<DataRoomEntityModel>> {
    const response =
      await this.vendorsVendorIdDataRoomEntitiesMovePatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Data Room entities using tree view
   */
  async vendorsVendorIdDataRoomEntitiesTreeGetRaw(
    requestParameters: VendorsVendorIdDataRoomEntitiesTreeGetRequest
  ): Promise<runtime.ApiResponse<DataRoomEntityModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdDataRoomEntitiesTreeGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.nodeId !== undefined) {
      queryParameters['node_id'] = requestParameters.nodeId;
    }

    if (requestParameters.depth !== undefined) {
      queryParameters['depth'] = requestParameters.depth;
    }

    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/data_room/entities/tree`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      DataRoomEntityModelFromJSON(jsonValue)
    );
  }

  /**
   * Get Data Room entities using tree view
   */
  async vendorsVendorIdDataRoomEntitiesTreeGet(
    requestParameters: VendorsVendorIdDataRoomEntitiesTreeGetRequest
  ): Promise<DataRoomEntityModel> {
    const response =
      await this.vendorsVendorIdDataRoomEntitiesTreeGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Data Room evidence accessible by current user
   */
  async vendorsVendorIdDataRoomEvidenceGetRaw(
    requestParameters: VendorsVendorIdDataRoomEvidenceGetRequest
  ): Promise<runtime.ApiResponse<Array<DataRoomEntityModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdDataRoomEvidenceGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/data_room/evidence`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(DataRoomEntityModelFromJSON)
    );
  }

  /**
   * Get Data Room evidence accessible by current user
   */
  async vendorsVendorIdDataRoomEvidenceGet(
    requestParameters: VendorsVendorIdDataRoomEvidenceGetRequest
  ): Promise<Array<DataRoomEntityModel>> {
    const response =
      await this.vendorsVendorIdDataRoomEvidenceGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create Data Room file
   */
  async vendorsVendorIdDataRoomFilesPostRaw(
    requestParameters: VendorsVendorIdDataRoomFilesPostRequest
  ): Promise<runtime.ApiResponse<Array<DataRoomEntityModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdDataRoomFilesPost.'
      );
    }

    if (
      requestParameters.parentFolderId === null ||
      requestParameters.parentFolderId === undefined
    ) {
      throw new runtime.RequiredError(
        'parentFolderId',
        'Required parameter requestParameters.parentFolderId was null or undefined when calling vendorsVendorIdDataRoomFilesPost.'
      );
    }

    if (
      requestParameters.files0Attachment === null ||
      requestParameters.files0Attachment === undefined
    ) {
      throw new runtime.RequiredError(
        'files0Attachment',
        'Required parameter requestParameters.files0Attachment was null or undefined when calling vendorsVendorIdDataRoomFilesPost.'
      );
    }

    if (
      requestParameters.files0Filename === null ||
      requestParameters.files0Filename === undefined
    ) {
      throw new runtime.RequiredError(
        'files0Filename',
        'Required parameter requestParameters.files0Filename was null or undefined when calling vendorsVendorIdDataRoomFilesPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.parentFolderId !== undefined) {
      formParams.append(
        'parent_folder_id',
        requestParameters.parentFolderId as any
      );
    }

    if (requestParameters.files0Attachment !== undefined) {
      formParams.append(
        'files[[0][attachment]',
        requestParameters.files0Attachment as any
      );
    }

    if (requestParameters.files0Filename !== undefined) {
      formParams.append(
        'files[[0][filename]',
        requestParameters.files0Filename as any
      );
    }

    if (requestParameters.files0Description !== undefined) {
      formParams.append(
        'files[[0][description]',
        requestParameters.files0Description as any
      );
    }

    const response = await this.request({
      path: `/vendors/{vendor_id}/data_room/files`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(DataRoomEntityModelFromJSON)
    );
  }

  /**
   * Create Data Room file
   */
  async vendorsVendorIdDataRoomFilesPost(
    requestParameters: VendorsVendorIdDataRoomFilesPostRequest
  ): Promise<Array<DataRoomEntityModel>> {
    const response =
      await this.vendorsVendorIdDataRoomFilesPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create Data Room folder
   */
  async vendorsVendorIdDataRoomFoldersPostRaw(
    requestParameters: VendorsVendorIdDataRoomFoldersPostRequest
  ): Promise<runtime.ApiResponse<DataRoomEntityModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdDataRoomFoldersPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdDataRoomFoldersPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/data_room/folders`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: DataRoomEntityFolderCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      DataRoomEntityModelFromJSON(jsonValue)
    );
  }

  /**
   * Create Data Room folder
   */
  async vendorsVendorIdDataRoomFoldersPost(
    requestParameters: VendorsVendorIdDataRoomFoldersPostRequest
  ): Promise<DataRoomEntityModel> {
    const response =
      await this.vendorsVendorIdDataRoomFoldersPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create Incident Event
   */
  async vendorsVendorIdIncidentEventsPostRaw(
    requestParameters: VendorsVendorIdIncidentEventsPostRequest
  ): Promise<runtime.ApiResponse<IncidentEventExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdIncidentEventsPost.'
      );
    }

    if (
      requestParameters.incidentEventName === null ||
      requestParameters.incidentEventName === undefined
    ) {
      throw new runtime.RequiredError(
        'incidentEventName',
        'Required parameter requestParameters.incidentEventName was null or undefined when calling vendorsVendorIdIncidentEventsPost.'
      );
    }

    if (
      requestParameters.incidentEventDescription === null ||
      requestParameters.incidentEventDescription === undefined
    ) {
      throw new runtime.RequiredError(
        'incidentEventDescription',
        'Required parameter requestParameters.incidentEventDescription was null or undefined when calling vendorsVendorIdIncidentEventsPost.'
      );
    }

    if (
      requestParameters.incidentEventDateIdentified === null ||
      requestParameters.incidentEventDateIdentified === undefined
    ) {
      throw new runtime.RequiredError(
        'incidentEventDateIdentified',
        'Required parameter requestParameters.incidentEventDateIdentified was null or undefined when calling vendorsVendorIdIncidentEventsPost.'
      );
    }

    if (
      requestParameters.incidentEventRaisedBy === null ||
      requestParameters.incidentEventRaisedBy === undefined
    ) {
      throw new runtime.RequiredError(
        'incidentEventRaisedBy',
        'Required parameter requestParameters.incidentEventRaisedBy was null or undefined when calling vendorsVendorIdIncidentEventsPost.'
      );
    }

    if (
      requestParameters.incidentEventRootCause === null ||
      requestParameters.incidentEventRootCause === undefined
    ) {
      throw new runtime.RequiredError(
        'incidentEventRootCause',
        'Required parameter requestParameters.incidentEventRootCause was null or undefined when calling vendorsVendorIdIncidentEventsPost.'
      );
    }

    if (
      requestParameters.incidentEventPreventativeActions === null ||
      requestParameters.incidentEventPreventativeActions === undefined
    ) {
      throw new runtime.RequiredError(
        'incidentEventPreventativeActions',
        'Required parameter requestParameters.incidentEventPreventativeActions was null or undefined when calling vendorsVendorIdIncidentEventsPost.'
      );
    }

    if (
      requestParameters.incidentEventActionsTaken === null ||
      requestParameters.incidentEventActionsTaken === undefined
    ) {
      throw new runtime.RequiredError(
        'incidentEventActionsTaken',
        'Required parameter requestParameters.incidentEventActionsTaken was null or undefined when calling vendorsVendorIdIncidentEventsPost.'
      );
    }

    if (
      requestParameters.incidentEventDataBreach === null ||
      requestParameters.incidentEventDataBreach === undefined
    ) {
      throw new runtime.RequiredError(
        'incidentEventDataBreach',
        'Required parameter requestParameters.incidentEventDataBreach was null or undefined when calling vendorsVendorIdIncidentEventsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.incidentEventName !== undefined) {
      formParams.append(
        'incident_event[name]',
        requestParameters.incidentEventName as any
      );
    }

    if (requestParameters.incidentEventDescription !== undefined) {
      formParams.append(
        'incident_event[description]',
        requestParameters.incidentEventDescription as any
      );
    }

    if (requestParameters.incidentEventIdentifier !== undefined) {
      formParams.append(
        'incident_event[identifier]',
        requestParameters.incidentEventIdentifier as any
      );
    }

    if (requestParameters.incidentEventDateIdentified !== undefined) {
      formParams.append(
        'incident_event[date_identified]',
        requestParameters.incidentEventDateIdentified as any
      );
    }

    if (requestParameters.incidentEventRaisedBy !== undefined) {
      formParams.append(
        'incident_event[raised_by]',
        requestParameters.incidentEventRaisedBy as any
      );
    }

    if (requestParameters.incidentEventRootCause !== undefined) {
      formParams.append(
        'incident_event[root_cause]',
        requestParameters.incidentEventRootCause as any
      );
    }

    if (requestParameters.incidentEventOwnerId !== undefined) {
      formParams.append(
        'incident_event[owner_id]',
        requestParameters.incidentEventOwnerId as any
      );
    }

    if (requestParameters.incidentEventUrl !== undefined) {
      formParams.append(
        'incident_event[url]',
        requestParameters.incidentEventUrl as any
      );
    }

    if (requestParameters.incidentEventAttachments) {
      formParams.append(
        'incident_event[attachments]',
        requestParameters.incidentEventAttachments.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    if (requestParameters.incidentEventPreventativeActions !== undefined) {
      formParams.append(
        'incident_event[preventative_actions]',
        requestParameters.incidentEventPreventativeActions as any
      );
    }

    if (requestParameters.incidentEventActionsTaken !== undefined) {
      formParams.append(
        'incident_event[actions_taken]',
        requestParameters.incidentEventActionsTaken as any
      );
    }

    if (requestParameters.incidentEventDataBreach !== undefined) {
      formParams.append(
        'incident_event[data_breach]',
        requestParameters.incidentEventDataBreach as any
      );
    }

    if (requestParameters.incidentEventDataBreachDetails !== undefined) {
      formParams.append(
        'incident_event[data_breach_details]',
        requestParameters.incidentEventDataBreachDetails as any
      );
    }

    const response = await this.request({
      path: `/vendors/{vendor_id}/incident_events`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      IncidentEventExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create Incident Event
   */
  async vendorsVendorIdIncidentEventsPost(
    requestParameters: VendorsVendorIdIncidentEventsPostRequest
  ): Promise<IncidentEventExtended> {
    const response =
      await this.vendorsVendorIdIncidentEventsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor Legal register
   */
  async vendorsVendorIdLegalRegisterGetRaw(
    requestParameters: VendorsVendorIdLegalRegisterGetRequest
  ): Promise<runtime.ApiResponse<LegalRegisterModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdLegalRegisterGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/legal_register`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      LegalRegisterModelFromJSON(jsonValue)
    );
  }

  /**
   * Get vendor Legal register
   */
  async vendorsVendorIdLegalRegisterGet(
    requestParameters: VendorsVendorIdLegalRegisterGetRequest
  ): Promise<LegalRegisterModel> {
    const response =
      await this.vendorsVendorIdLegalRegisterGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update Legal register
   */
  async vendorsVendorIdLegalRegisterPatchRaw(
    requestParameters: VendorsVendorIdLegalRegisterPatchRequest
  ): Promise<runtime.ApiResponse<LegalRegisterModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdLegalRegisterPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdLegalRegisterPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/legal_register`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: LegalRegisterUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      LegalRegisterModelFromJSON(jsonValue)
    );
  }

  /**
   * Update Legal register
   */
  async vendorsVendorIdLegalRegisterPatch(
    requestParameters: VendorsVendorIdLegalRegisterPatchRequest
  ): Promise<LegalRegisterModel> {
    const response =
      await this.vendorsVendorIdLegalRegisterPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Add vendor legislations from templates
   */
  async vendorsVendorIdLegislationsAddPostRaw(
    requestParameters: VendorsVendorIdLegislationsAddPostRequest
  ): Promise<runtime.ApiResponse<Array<LegislationExtended>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdLegislationsAddPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdLegislationsAddPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/legislations/add`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: LegislationAddPayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(LegislationExtendedFromJSON)
    );
  }

  /**
   * Add vendor legislations from templates
   */
  async vendorsVendorIdLegislationsAddPost(
    requestParameters: VendorsVendorIdLegislationsAddPostRequest
  ): Promise<Array<LegislationExtended>> {
    const response =
      await this.vendorsVendorIdLegislationsAddPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor legislation
   */
  async vendorsVendorIdLegislationsGetRaw(
    requestParameters: VendorsVendorIdLegislationsGetRequest
  ): Promise<runtime.ApiResponse<Array<LegislationModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdLegislationsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/legislations`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(LegislationModelFromJSON)
    );
  }

  /**
   * Get vendor legislation
   */
  async vendorsVendorIdLegislationsGet(
    requestParameters: VendorsVendorIdLegislationsGetRequest
  ): Promise<Array<LegislationModel>> {
    const response =
      await this.vendorsVendorIdLegislationsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor legislation
   */
  async vendorsVendorIdLegislationsPostRaw(
    requestParameters: VendorsVendorIdLegislationsPostRequest
  ): Promise<runtime.ApiResponse<LegislationExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdLegislationsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdLegislationsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/legislations`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: LegislationCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      LegislationExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor legislation
   */
  async vendorsVendorIdLegislationsPost(
    requestParameters: VendorsVendorIdLegislationsPostRequest
  ): Promise<LegislationExtended> {
    const response =
      await this.vendorsVendorIdLegislationsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor PESTEL items
   */
  async vendorsVendorIdPestelItemsGetRaw(
    requestParameters: VendorsVendorIdPestelItemsGetRequest
  ): Promise<runtime.ApiResponse<Array<PestelItemModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdPestelItemsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/pestel_items`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(PestelItemModelFromJSON)
    );
  }

  /**
   * Get vendor PESTEL items
   */
  async vendorsVendorIdPestelItemsGet(
    requestParameters: VendorsVendorIdPestelItemsGetRequest
  ): Promise<Array<PestelItemModel>> {
    const response =
      await this.vendorsVendorIdPestelItemsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor PESTEL items
   */
  async vendorsVendorIdPestelItemsPostRaw(
    requestParameters: VendorsVendorIdPestelItemsPostRequest
  ): Promise<runtime.ApiResponse<PestelItemModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdPestelItemsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdPestelItemsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/pestel_items`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: PestelItemCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      PestelItemModelFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor PESTEL items
   */
  async vendorsVendorIdPestelItemsPost(
    requestParameters: VendorsVendorIdPestelItemsPostRequest
  ): Promise<PestelItemModel> {
    const response =
      await this.vendorsVendorIdPestelItemsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor PESTEL register
   */
  async vendorsVendorIdPestelRegisterGetRaw(
    requestParameters: VendorsVendorIdPestelRegisterGetRequest
  ): Promise<runtime.ApiResponse<PestelRegisterModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdPestelRegisterGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/pestel_register`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      PestelRegisterModelFromJSON(jsonValue)
    );
  }

  /**
   * Get vendor PESTEL register
   */
  async vendorsVendorIdPestelRegisterGet(
    requestParameters: VendorsVendorIdPestelRegisterGetRequest
  ): Promise<PestelRegisterModel> {
    const response =
      await this.vendorsVendorIdPestelRegisterGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update PESTEL register
   */
  async vendorsVendorIdPestelRegisterPatchRaw(
    requestParameters: VendorsVendorIdPestelRegisterPatchRequest
  ): Promise<runtime.ApiResponse<PestelRegisterModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdPestelRegisterPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdPestelRegisterPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/pestel_register`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: PestelRegisterUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      PestelRegisterModelFromJSON(jsonValue)
    );
  }

  /**
   * Update PESTEL register
   */
  async vendorsVendorIdPestelRegisterPatch(
    requestParameters: VendorsVendorIdPestelRegisterPatchRequest
  ): Promise<PestelRegisterModel> {
    const response =
      await this.vendorsVendorIdPestelRegisterPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor reports
   */
  async vendorsVendorIdReportsGetRaw(
    requestParameters: VendorsVendorIdReportsGetRequest
  ): Promise<runtime.ApiResponse<Array<ReportModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdReportsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/reports`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ReportModelFromJSON)
    );
  }

  /**
   * Get vendor reports
   */
  async vendorsVendorIdReportsGet(
    requestParameters: VendorsVendorIdReportsGetRequest
  ): Promise<Array<ReportModel>> {
    const response = await this.vendorsVendorIdReportsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor report
   */
  async vendorsVendorIdReportsPostRaw(
    requestParameters: VendorsVendorIdReportsPostRequest
  ): Promise<runtime.ApiResponse<ReportModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdReportsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdReportsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/reports`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ReportCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReportModelFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor report
   */
  async vendorsVendorIdReportsPost(
    requestParameters: VendorsVendorIdReportsPostRequest
  ): Promise<ReportModel> {
    const response =
      await this.vendorsVendorIdReportsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Upload vendor report
   */
  async vendorsVendorIdReportsUploadPostRaw(
    requestParameters: VendorsVendorIdReportsUploadPostRequest
  ): Promise<runtime.ApiResponse<ReportModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdReportsUploadPost.'
      );
    }

    if (
      requestParameters.reportFile === null ||
      requestParameters.reportFile === undefined
    ) {
      throw new runtime.RequiredError(
        'reportFile',
        'Required parameter requestParameters.reportFile was null or undefined when calling vendorsVendorIdReportsUploadPost.'
      );
    }

    if (
      requestParameters.reportName === null ||
      requestParameters.reportName === undefined
    ) {
      throw new runtime.RequiredError(
        'reportName',
        'Required parameter requestParameters.reportName was null or undefined when calling vendorsVendorIdReportsUploadPost.'
      );
    }

    if (
      requestParameters.reportCompletedAt === null ||
      requestParameters.reportCompletedAt === undefined
    ) {
      throw new runtime.RequiredError(
        'reportCompletedAt',
        'Required parameter requestParameters.reportCompletedAt was null or undefined when calling vendorsVendorIdReportsUploadPost.'
      );
    }

    if (
      requestParameters.reportVersion === null ||
      requestParameters.reportVersion === undefined
    ) {
      throw new runtime.RequiredError(
        'reportVersion',
        'Required parameter requestParameters.reportVersion was null or undefined when calling vendorsVendorIdReportsUploadPost.'
      );
    }

    if (
      requestParameters.reportOwnerId === null ||
      requestParameters.reportOwnerId === undefined
    ) {
      throw new runtime.RequiredError(
        'reportOwnerId',
        'Required parameter requestParameters.reportOwnerId was null or undefined when calling vendorsVendorIdReportsUploadPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.reportFile !== undefined) {
      formParams.append('report[file]', requestParameters.reportFile as any);
    }

    if (requestParameters.reportName !== undefined) {
      formParams.append('report[name]', requestParameters.reportName as any);
    }

    if (requestParameters.reportCompletedAt !== undefined) {
      formParams.append(
        'report[completed_at]',
        requestParameters.reportCompletedAt as any
      );
    }

    if (requestParameters.reportVersion !== undefined) {
      formParams.append(
        'report[version]',
        requestParameters.reportVersion as any
      );
    }

    if (requestParameters.reportOwnerId !== undefined) {
      formParams.append(
        'report[owner_id]',
        requestParameters.reportOwnerId as any
      );
    }

    const response = await this.request({
      path: `/vendors/{vendor_id}/reports/upload`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReportModelFromJSON(jsonValue)
    );
  }

  /**
   * Upload vendor report
   */
  async vendorsVendorIdReportsUploadPost(
    requestParameters: VendorsVendorIdReportsUploadPostRequest
  ): Promise<ReportModel> {
    const response =
      await this.vendorsVendorIdReportsUploadPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * GET control review_history_items
   */
  async vendorsVendorIdReviewHistoryItemsGetRaw(
    requestParameters: VendorsVendorIdReviewHistoryItemsGetRequest
  ): Promise<runtime.ApiResponse<Array<ReviewHistoryItemModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdReviewHistoryItemsGet.'
      );
    }

    if (
      requestParameters.relatedToId === null ||
      requestParameters.relatedToId === undefined
    ) {
      throw new runtime.RequiredError(
        'relatedToId',
        'Required parameter requestParameters.relatedToId was null or undefined when calling vendorsVendorIdReviewHistoryItemsGet.'
      );
    }

    if (
      requestParameters.relatedToType === null ||
      requestParameters.relatedToType === undefined
    ) {
      throw new runtime.RequiredError(
        'relatedToType',
        'Required parameter requestParameters.relatedToType was null or undefined when calling vendorsVendorIdReviewHistoryItemsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.relatedToId !== undefined) {
      queryParameters['related_to_id'] = requestParameters.relatedToId;
    }

    if (requestParameters.relatedToType !== undefined) {
      queryParameters['related_to_type'] = requestParameters.relatedToType;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/review_history_items`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ReviewHistoryItemModelFromJSON)
    );
  }

  /**
   * GET control review_history_items
   */
  async vendorsVendorIdReviewHistoryItemsGet(
    requestParameters: VendorsVendorIdReviewHistoryItemsGetRequest
  ): Promise<Array<ReviewHistoryItemModel>> {
    const response =
      await this.vendorsVendorIdReviewHistoryItemsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create review history item
   */
  async vendorsVendorIdReviewHistoryItemsPostRaw(
    requestParameters: VendorsVendorIdReviewHistoryItemsPostRequest
  ): Promise<runtime.ApiResponse<ReviewHistoryItemModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdReviewHistoryItemsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdReviewHistoryItemsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/review_history_items`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ReviewHistoryItemCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReviewHistoryItemModelFromJSON(jsonValue)
    );
  }

  /**
   * Create review history item
   */
  async vendorsVendorIdReviewHistoryItemsPost(
    requestParameters: VendorsVendorIdReviewHistoryItemsPostRequest
  ): Promise<ReviewHistoryItemModel> {
    const response =
      await this.vendorsVendorIdReviewHistoryItemsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor risk reports
   */
  async vendorsVendorIdRiskReportsGetRaw(
    requestParameters: VendorsVendorIdRiskReportsGetRequest
  ): Promise<runtime.ApiResponse<Array<RiskReportModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdRiskReportsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/risk_reports`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(RiskReportModelFromJSON)
    );
  }

  /**
   * Get vendor risk reports
   */
  async vendorsVendorIdRiskReportsGet(
    requestParameters: VendorsVendorIdRiskReportsGetRequest
  ): Promise<Array<RiskReportModel>> {
    const response =
      await this.vendorsVendorIdRiskReportsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor risk report
   */
  async vendorsVendorIdRiskReportsPostRaw(
    requestParameters: VendorsVendorIdRiskReportsPostRequest
  ): Promise<runtime.ApiResponse<RiskReportModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdRiskReportsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdRiskReportsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/risk_reports`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: RiskReportCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RiskReportModelFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor risk report
   */
  async vendorsVendorIdRiskReportsPost(
    requestParameters: VendorsVendorIdRiskReportsPostRequest
  ): Promise<RiskReportModel> {
    const response =
      await this.vendorsVendorIdRiskReportsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor risks
   */
  async vendorsVendorIdRisksGetRaw(
    requestParameters: VendorsVendorIdRisksGetRequest
  ): Promise<runtime.ApiResponse<Array<RiskModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdRisksGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/risks`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(RiskModelFromJSON)
    );
  }

  /**
   * Get vendor risks
   */
  async vendorsVendorIdRisksGet(
    requestParameters: VendorsVendorIdRisksGetRequest
  ): Promise<Array<RiskModel>> {
    const response = await this.vendorsVendorIdRisksGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor risk
   */
  async vendorsVendorIdRisksPostRaw(
    requestParameters: VendorsVendorIdRisksPostRequest
  ): Promise<runtime.ApiResponse<RiskExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdRisksPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdRisksPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/risks`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: RiskCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RiskExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor risk
   */
  async vendorsVendorIdRisksPost(
    requestParameters: VendorsVendorIdRisksPostRequest
  ): Promise<RiskExtended> {
    const response = await this.vendorsVendorIdRisksPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get search result
   */
  async vendorsVendorIdSearchGetRaw(
    requestParameters: VendorsVendorIdSearchGetRequest
  ): Promise<runtime.ApiResponse<Array<GlobalSearchItem>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdSearchGet.'
      );
    }

    if (
      requestParameters.term === null ||
      requestParameters.term === undefined
    ) {
      throw new runtime.RequiredError(
        'term',
        'Required parameter requestParameters.term was null or undefined when calling vendorsVendorIdSearchGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.term !== undefined) {
      queryParameters['term'] = requestParameters.term;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/search`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(GlobalSearchItemFromJSON)
    );
  }

  /**
   * Get search result
   */
  async vendorsVendorIdSearchGet(
    requestParameters: VendorsVendorIdSearchGetRequest
  ): Promise<Array<GlobalSearchItem>> {
    const response = await this.vendorsVendorIdSearchGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor tasks
   */
  async vendorsVendorIdTasksGetRaw(
    requestParameters: VendorsVendorIdTasksGetRequest
  ): Promise<runtime.ApiResponse<TaskList>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdTasksGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.frameworkId !== undefined) {
      queryParameters['framework_id'] = requestParameters.frameworkId;
    }

    if (requestParameters.notUsedInFrameworksIds) {
      queryParameters['not_used_in_frameworks_ids[]'] =
        requestParameters.notUsedInFrameworksIds.join(
          runtime.COLLECTION_FORMATS['csv']
        );
    }

    if (requestParameters.applicable !== undefined) {
      queryParameters['applicable'] = requestParameters.applicable;
    }

    if (requestParameters.includeStats !== undefined) {
      queryParameters['include_stats'] = requestParameters.includeStats;
    }

    if (requestParameters.status) {
      queryParameters['status[]'] = requestParameters.status.join(
        runtime.COLLECTION_FORMATS['csv']
      );
    }

    if (requestParameters.name !== undefined) {
      queryParameters['name'] = requestParameters.name;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/tasks`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TaskListFromJSON(jsonValue)
    );
  }

  /**
   * Get vendor tasks
   */
  async vendorsVendorIdTasksGet(
    requestParameters: VendorsVendorIdTasksGetRequest
  ): Promise<TaskList> {
    const response = await this.vendorsVendorIdTasksGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor task
   */
  async vendorsVendorIdTasksPostRaw(
    requestParameters: VendorsVendorIdTasksPostRequest
  ): Promise<runtime.ApiResponse<TaskExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdTasksPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdTasksPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/tasks`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TaskCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TaskExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor task
   */
  async vendorsVendorIdTasksPost(
    requestParameters: VendorsVendorIdTasksPostRequest
  ): Promise<TaskExtended> {
    const response = await this.vendorsVendorIdTasksPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor actions
   */
  async vendorsVendorIdVendorActionsGetRaw(
    requestParameters: VendorsVendorIdVendorActionsGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorActionModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorActionsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.referenceableId !== undefined) {
      queryParameters['referenceable_id'] = requestParameters.referenceableId;
    }

    if (requestParameters.referenceableType !== undefined) {
      queryParameters['referenceable_type'] =
        requestParameters.referenceableType;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/vendor_actions`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorActionModelFromJSON)
    );
  }

  /**
   * Get vendor actions
   */
  async vendorsVendorIdVendorActionsGet(
    requestParameters: VendorsVendorIdVendorActionsGetRequest
  ): Promise<Array<VendorActionModel>> {
    const response =
      await this.vendorsVendorIdVendorActionsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor action
   */
  async vendorsVendorIdVendorActionsPostRaw(
    requestParameters: VendorsVendorIdVendorActionsPostRequest
  ): Promise<runtime.ApiResponse<VendorActionExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorActionsPost.'
      );
    }

    if (
      requestParameters.vendorActionName === null ||
      requestParameters.vendorActionName === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorActionName',
        'Required parameter requestParameters.vendorActionName was null or undefined when calling vendorsVendorIdVendorActionsPost.'
      );
    }

    if (
      requestParameters.vendorActionOwnerId === null ||
      requestParameters.vendorActionOwnerId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorActionOwnerId',
        'Required parameter requestParameters.vendorActionOwnerId was null or undefined when calling vendorsVendorIdVendorActionsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.vendorActionName !== undefined) {
      formParams.append(
        'vendor_action[name]',
        requestParameters.vendorActionName as any
      );
    }

    if (requestParameters.vendorActionDescription !== undefined) {
      formParams.append(
        'vendor_action[description]',
        requestParameters.vendorActionDescription as any
      );
    }

    if (requestParameters.vendorActionDueDate !== undefined) {
      formParams.append(
        'vendor_action[due_date]',
        requestParameters.vendorActionDueDate as any
      );
    }

    if (requestParameters.vendorActionOwnerId !== undefined) {
      formParams.append(
        'vendor_action[owner_id]',
        requestParameters.vendorActionOwnerId as any
      );
    }

    if (requestParameters.vendorActionUrl !== undefined) {
      formParams.append(
        'vendor_action[url]',
        requestParameters.vendorActionUrl as any
      );
    }

    if (requestParameters.vendorActionActionType !== undefined) {
      formParams.append(
        'vendor_action[action_type]',
        requestParameters.vendorActionActionType as any
      );
    }

    if (requestParameters.vendorActionAttachments) {
      formParams.append(
        'vendor_action[attachments]',
        requestParameters.vendorActionAttachments.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    const response = await this.request({
      path: `/vendors/{vendor_id}/vendor_actions`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorActionExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor action
   */
  async vendorsVendorIdVendorActionsPost(
    requestParameters: VendorsVendorIdVendorActionsPostRequest
  ): Promise<VendorActionExtended> {
    const response =
      await this.vendorsVendorIdVendorActionsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the attestations for particular user or document
   */
  async vendorsVendorIdVendorDocumentAttestationsGetRaw(
    requestParameters: VendorsVendorIdVendorDocumentAttestationsGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorDocumentAttestation>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorDocumentAttestationsGet.'
      );
    }

    if (
      requestParameters.targetId === null ||
      requestParameters.targetId === undefined
    ) {
      throw new runtime.RequiredError(
        'targetId',
        'Required parameter requestParameters.targetId was null or undefined when calling vendorsVendorIdVendorDocumentAttestationsGet.'
      );
    }

    if (
      requestParameters.targetType === null ||
      requestParameters.targetType === undefined
    ) {
      throw new runtime.RequiredError(
        'targetType',
        'Required parameter requestParameters.targetType was null or undefined when calling vendorsVendorIdVendorDocumentAttestationsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.targetId !== undefined) {
      queryParameters['target_id'] = requestParameters.targetId;
    }

    if (requestParameters.targetType !== undefined) {
      queryParameters['target_type'] = requestParameters.targetType;
    }

    if (requestParameters.includePossibleAttestations !== undefined) {
      queryParameters['include_possible_attestations'] =
        requestParameters.includePossibleAttestations;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/:vendor_id/vendor_document_attestations`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorDocumentAttestationFromJSON)
    );
  }

  /**
   * Get the attestations for particular user or document
   */
  async vendorsVendorIdVendorDocumentAttestationsGet(
    requestParameters: VendorsVendorIdVendorDocumentAttestationsGetRequest
  ): Promise<Array<VendorDocumentAttestation>> {
    const response =
      await this.vendorsVendorIdVendorDocumentAttestationsGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Create a new vendor document
   */
  async vendorsVendorIdVendorDocumentsPostRaw(
    requestParameters: VendorsVendorIdVendorDocumentsPostRequest
  ): Promise<runtime.ApiResponse<VendorDocumentExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorDocumentsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.vendorDocumentDocument !== undefined) {
      formParams.append(
        'vendor_document[document]',
        requestParameters.vendorDocumentDocument as any
      );
    }

    if (requestParameters.vendorDocumentName !== undefined) {
      formParams.append(
        'vendor_document[name]',
        requestParameters.vendorDocumentName as any
      );
    }

    if (requestParameters.vendorDocumentDocumentType !== undefined) {
      formParams.append(
        'vendor_document[document_type]',
        requestParameters.vendorDocumentDocumentType as any
      );
    }

    if (requestParameters.vendorDocumentDescription !== undefined) {
      formParams.append(
        'vendor_document[description]',
        requestParameters.vendorDocumentDescription as any
      );
    }

    if (requestParameters.vendorDocumentPolicyCategoryId !== undefined) {
      formParams.append(
        'vendor_document[policy_category_id]',
        requestParameters.vendorDocumentPolicyCategoryId as any
      );
    }

    if (requestParameters.vendorDocumentFileSizeInBytes !== undefined) {
      formParams.append(
        'vendor_document[file_size_in_bytes]',
        requestParameters.vendorDocumentFileSizeInBytes as any
      );
    }

    const response = await this.request({
      path: `/vendors/{vendor_id}/vendor_documents`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorDocumentExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create a new vendor document
   */
  async vendorsVendorIdVendorDocumentsPost(
    requestParameters: VendorsVendorIdVendorDocumentsPostRequest
  ): Promise<VendorDocumentExtended> {
    const response =
      await this.vendorsVendorIdVendorDocumentsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor events
   */
  async vendorsVendorIdVendorEventsGetRaw(
    requestParameters: VendorsVendorIdVendorEventsGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorEventModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorEventsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/vendor_events`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorEventModelFromJSON)
    );
  }

  /**
   * Get vendor events
   */
  async vendorsVendorIdVendorEventsGet(
    requestParameters: VendorsVendorIdVendorEventsGetRequest
  ): Promise<Array<VendorEventModel>> {
    const response =
      await this.vendorsVendorIdVendorEventsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor event
   */
  async vendorsVendorIdVendorEventsPostRaw(
    requestParameters: VendorsVendorIdVendorEventsPostRequest
  ): Promise<runtime.ApiResponse<VendorEventExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorEventsPost.'
      );
    }

    if (
      requestParameters.vendorEventName === null ||
      requestParameters.vendorEventName === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorEventName',
        'Required parameter requestParameters.vendorEventName was null or undefined when calling vendorsVendorIdVendorEventsPost.'
      );
    }

    if (
      requestParameters.vendorEventDate === null ||
      requestParameters.vendorEventDate === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorEventDate',
        'Required parameter requestParameters.vendorEventDate was null or undefined when calling vendorsVendorIdVendorEventsPost.'
      );
    }

    if (
      requestParameters.vendorEventEventType === null ||
      requestParameters.vendorEventEventType === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorEventEventType',
        'Required parameter requestParameters.vendorEventEventType was null or undefined when calling vendorsVendorIdVendorEventsPost.'
      );
    }

    if (
      requestParameters.vendorEventOwnerId === null ||
      requestParameters.vendorEventOwnerId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorEventOwnerId',
        'Required parameter requestParameters.vendorEventOwnerId was null or undefined when calling vendorsVendorIdVendorEventsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.vendorEventName !== undefined) {
      formParams.append(
        'vendor_event[name]',
        requestParameters.vendorEventName as any
      );
    }

    if (requestParameters.vendorEventDescription !== undefined) {
      formParams.append(
        'vendor_event[description]',
        requestParameters.vendorEventDescription as any
      );
    }

    if (requestParameters.vendorEventDate !== undefined) {
      formParams.append(
        'vendor_event[date]',
        requestParameters.vendorEventDate as any
      );
    }

    if (requestParameters.vendorEventEventType !== undefined) {
      formParams.append(
        'vendor_event[event_type]',
        requestParameters.vendorEventEventType as any
      );
    }

    if (requestParameters.vendorEventOwnerId !== undefined) {
      formParams.append(
        'vendor_event[owner_id]',
        requestParameters.vendorEventOwnerId as any
      );
    }

    if (requestParameters.vendorEventAttachments) {
      formParams.append(
        'vendor_event[attachments]',
        requestParameters.vendorEventAttachments.join(
          runtime.COLLECTION_FORMATS['csv']
        )
      );
    }

    if (requestParameters.vendorEventUrl !== undefined) {
      formParams.append(
        'vendor_event[url]',
        requestParameters.vendorEventUrl as any
      );
    }

    const response = await this.request({
      path: `/vendors/{vendor_id}/vendor_events`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorEventExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor event
   */
  async vendorsVendorIdVendorEventsPost(
    requestParameters: VendorsVendorIdVendorEventsPostRequest
  ): Promise<VendorEventExtended> {
    const response =
      await this.vendorsVendorIdVendorEventsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Upsert vendor framework requirement
   */
  async vendorsVendorIdVendorFrameworkRequirementsPostRaw(
    requestParameters: VendorsVendorIdVendorFrameworkRequirementsPostRequest
  ): Promise<runtime.ApiResponse<VendorFrameworkRequirementModel>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorFrameworkRequirementsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdVendorFrameworkRequirementsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/vendor_framework_requirements`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VendorFrameworkRequirementCreatePayloadToJSON(
        requestParameters.body
      ),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorFrameworkRequirementModelFromJSON(jsonValue)
    );
  }

  /**
   * Upsert vendor framework requirement
   */
  async vendorsVendorIdVendorFrameworkRequirementsPost(
    requestParameters: VendorsVendorIdVendorFrameworkRequirementsPostRequest
  ): Promise<VendorFrameworkRequirementModel> {
    const response =
      await this.vendorsVendorIdVendorFrameworkRequirementsPostRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get vendor integrations
   */
  async vendorsVendorIdVendorIntegrationsGetRaw(
    requestParameters: VendorsVendorIdVendorIntegrationsGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorIntegrationModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorIntegrationsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/vendor_integrations`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorIntegrationModelFromJSON)
    );
  }

  /**
   * Get vendor integrations
   */
  async vendorsVendorIdVendorIntegrationsGet(
    requestParameters: VendorsVendorIdVendorIntegrationsGetRequest
  ): Promise<Array<VendorIntegrationModel>> {
    const response =
      await this.vendorsVendorIdVendorIntegrationsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create vendor integration
   */
  async vendorsVendorIdVendorIntegrationsPostRaw(
    requestParameters: VendorsVendorIdVendorIntegrationsPostRequest
  ): Promise<runtime.ApiResponse<VendorIntegrationExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorIntegrationsPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdVendorIntegrationsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/vendor_integrations`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VendorIntegrationCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorIntegrationExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create vendor integration
   */
  async vendorsVendorIdVendorIntegrationsPost(
    requestParameters: VendorsVendorIdVendorIntegrationsPostRequest
  ): Promise<VendorIntegrationExtended> {
    const response =
      await this.vendorsVendorIdVendorIntegrationsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vendor policies
   */
  async vendorsVendorIdVendorPoliciesGetRaw(
    requestParameters: VendorsVendorIdVendorPoliciesGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorPolicy>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorPoliciesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.queryStatus) {
      queryParameters['query[status][]'] = requestParameters.queryStatus;
    }

    if (requestParameters.queryType) {
      queryParameters['query[type][]'] = requestParameters.queryType;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/vendor_policies`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorPolicyFromJSON)
    );
  }

  /**
   * Get vendor policies
   */
  async vendorsVendorIdVendorPoliciesGet(
    requestParameters: VendorsVendorIdVendorPoliciesGetRequest
  ): Promise<Array<VendorPolicy>> {
    const response =
      await this.vendorsVendorIdVendorPoliciesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get available vendor supplier categories
   */
  async vendorsVendorIdVendorSuppliersCategoriesGetRaw(
    requestParameters: VendorsVendorIdVendorSuppliersCategoriesGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorSupplierCategory>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorSuppliersCategoriesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/vendor_suppliers/categories`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorSupplierCategoryFromJSON)
    );
  }

  /**
   * Get available vendor supplier categories
   */
  async vendorsVendorIdVendorSuppliersCategoriesGet(
    requestParameters: VendorsVendorIdVendorSuppliersCategoriesGetRequest
  ): Promise<Array<VendorSupplierCategory>> {
    const response =
      await this.vendorsVendorIdVendorSuppliersCategoriesGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get vendor suppliers
   */
  async vendorsVendorIdVendorSuppliersGetRaw(
    requestParameters: VendorsVendorIdVendorSuppliersGetRequest
  ): Promise<runtime.ApiResponse<Array<VendorSupplierModel>>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorSuppliersGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendors/{vendor_id}/vendor_suppliers`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(VendorSupplierModelFromJSON)
    );
  }

  /**
   * Get vendor suppliers
   */
  async vendorsVendorIdVendorSuppliersGet(
    requestParameters: VendorsVendorIdVendorSuppliersGetRequest
  ): Promise<Array<VendorSupplierModel>> {
    const response =
      await this.vendorsVendorIdVendorSuppliersGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create a new vendor supplier
   */
  async vendorsVendorIdVendorSuppliersPostRaw(
    requestParameters: VendorsVendorIdVendorSuppliersPostRequest
  ): Promise<runtime.ApiResponse<VendorSupplierExtended>> {
    if (
      requestParameters.vendorId === null ||
      requestParameters.vendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorId',
        'Required parameter requestParameters.vendorId was null or undefined when calling vendorsVendorIdVendorSuppliersPost.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling vendorsVendorIdVendorSuppliersPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendors/{vendor_id}/vendor_suppliers`.replace(
        `{${'vendor_id'}}`,
        encodeURIComponent(String(requestParameters.vendorId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VendorSupplierPayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      VendorSupplierExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create a new vendor supplier
   */
  async vendorsVendorIdVendorSuppliersPost(
    requestParameters: VendorsVendorIdVendorSuppliersPostRequest
  ): Promise<VendorSupplierExtended> {
    const response =
      await this.vendorsVendorIdVendorSuppliersPostRaw(requestParameters);
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum ConfigsConfigIdGetConfigIdEnum {
  Currencies = 'currencies',
  Countries = 'countries',
  FinancialEntityTypes = 'financial_entity_types',
  CompetentAuthorities = 'competent_authorities',
}
/**
 * @export
 * @enum {string}
 */
export enum CorrectiveActionsCorrectiveActionIdPatchCorrectiveActionActionTypeEnum {
  NonConformance = 'non_conformance',
  OpportunityForImprovement = 'opportunity_for_improvement',
}
/**
 * @export
 * @enum {string}
 */
export enum CreateRecurringPaymentGetPaymentPeriodEnum {
  Monthly = 'monthly',
  Annually = 'annually',
}
/**
 * @export
 * @enum {string}
 */
export enum PaymentPlansPricingGetPaymentPeriodEnum {
  Annually = 'annually',
  Monthly = 'monthly',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorsIdSharesDeleteShareableTypeEnum {
  Agreement = 'Agreement',
  Assessment = 'Assessment',
  AssessmentResponse = 'AssessmentResponse',
  Audit = 'Audit',
  Checklist = 'Checklist',
  DataRoomEntity = 'DataRoomEntity',
  VendorDocument = 'VendorDocument',
  VendorPolicy = 'VendorPolicy',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorsIdSharesGetShareableTypeEnum {
  Agreement = 'Agreement',
  Assessment = 'Assessment',
  AssessmentResponse = 'AssessmentResponse',
  Audit = 'Audit',
  Checklist = 'Checklist',
  DataRoomEntity = 'DataRoomEntity',
  VendorDocument = 'VendorDocument',
  VendorPolicy = 'VendorPolicy',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorsIdSharesPostShareableTypeEnum {
  Agreement = 'Agreement',
  Assessment = 'Assessment',
  AssessmentResponse = 'AssessmentResponse',
  Audit = 'Audit',
  Checklist = 'Checklist',
  DataRoomEntity = 'DataRoomEntity',
  VendorDocument = 'VendorDocument',
  VendorPolicy = 'VendorPolicy',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorsIdSharesSuggestionsGetShareableTypeEnum {
  Agreement = 'Agreement',
  Assessment = 'Assessment',
  AssessmentResponse = 'AssessmentResponse',
  Audit = 'Audit',
  Checklist = 'Checklist',
  DataRoomEntity = 'DataRoomEntity',
  VendorDocument = 'VendorDocument',
  VendorPolicy = 'VendorPolicy',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorsVendorIdAuditsUploadPostAuditOriginEnum {
  Internal = 'internal',
  Vendor = 'vendor',
  Supplier = 'supplier',
  Surveillance = 'surveillance',
  External = 'external',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorsVendorIdAuditsUploadPostAuditRatingEnum {
  Compliant = 'Compliant',
  ImprovementNeeded = 'Improvement Needed',
  NotCompliant = 'Not Compliant',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorsVendorIdCalendarGetViewModeEnum {
  ActionsAndEvents = 'actions_and_events',
  AuditSchedule = 'audit_schedule',
  IncidentLog = 'incident_log',
  CorrectiveActions = 'corrective_actions',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorsVendorIdCalendarGetStatusesEnum {
  Pending = 'pending',
  Completed = 'completed',
  Overdue = 'overdue',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorsVendorIdCorrectiveActionsPostCorrectiveActionActionTypeEnum {
  NonConformance = 'non_conformance',
  OpportunityForImprovement = 'opportunity_for_improvement',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorsVendorIdReviewHistoryItemsGetRelatedToTypeEnum {
  Control = 'Control',
  LegalRegister = 'LegalRegister',
  PestelRegister = 'PestelRegister',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorsVendorIdVendorDocumentAttestationsGetTargetTypeEnum {
  VendorDocument = 'VendorDocument',
  VendorUser = 'VendorUser',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorsVendorIdVendorPoliciesGetQueryStatusEnum {
  InProgress = 'in_progress',
  Completed = 'completed',
}
