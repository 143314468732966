import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDetails } from '../../../../src/swagger/models';
import { AuthenticationState } from './authenticationState';

const authenticationSlice = createSlice({
  initialState: {
    forgottenPasswordFlow: false,
    confirmationSent: false,
  } as unknown as AuthenticationState,
  name: 'authenticationSlice',
  reducers: {
    fetchEmailRequest: state => {
      state.fetchingEmailError = undefined;
      state.isFetchingEmail = true;
    },
    fetchEmailSuccess: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
      state.isFetchingEmail = false;
    },
    fetchEmailFailure: (state, action: PayloadAction<string>) => {
      state.fetchingEmailError = action.payload;
      state.isFetchingEmail = false;
    },
    loginRequest: state => {
      state.isLoggingIn = true;
      state.loginError = undefined;
    },

    loginSuccess: state => {
      state.isAuthenticated = true;
      state.isLoggingIn = false;
      state.email = undefined;
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.isLoggingIn = false;
      state.loginError = action.payload;
    },
    registerRequest: state => {
      state.isRegistering = true;
      state.registerError = undefined;
    },
    registerSuccess: state => {
      state.isRegistering = false;
    },
    registerFailure: (state, action: PayloadAction<string>) => {
      state.isRegistering = false;
      state.registerError = action.payload;
    },
    resetPasswordRequest: state => {
      state.isResettingPassword = true;
      state.resetPasswordError = undefined;
    },
    resetPasswordSuccess: state => {
      state.hasResetPassword = true;
      state.isResettingPassword = false;
    },
    resetPasswordFailure: (state, action: PayloadAction<string>) => {
      state.isResettingPassword = false;
      state.resetPasswordError = action.payload;
    },
    sendPasswordResetRequest: state => {
      state.isSendingPasswordReset = true;
      state.sendPasswordResetError = undefined;
    },
    sendPasswordResetSuccess: state => {
      state.isSendingPasswordReset = false;
      state.hasSentPasswordReset = true;
    },
    sendPasswordResetFailure: (state, action: PayloadAction<string>) => {
      state.isSendingPasswordReset = false;
      state.sendPasswordResetError = action.payload;
    },
    signUpRequest: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
      state.isSigningUp = true;
      state.signUpError = undefined;
    },
    clearSignUpError: state => {
      state.signUpError = undefined;
    },
    signUpSuccess: state => {
      state.isSigningUp = false;
    },
    signUpFailure: (state, action: PayloadAction<string>) => {
      state.isSigningUp = false;
      state.signUpError = action.payload;
    },
    toggleForgottenPasswordFlow: state => {
      state.forgottenPasswordFlow = !state.forgottenPasswordFlow;
    },
    confirmationSent: state => {
      state.confirmationSent = true;
      state.loginError = undefined;
    },
    twoFactorAuthBypassOpen: (state, action: PayloadAction<string>) => {
      state.isLoggingIn = false;
      state.is2FAVerifyingOTP = true;
      state.qrCode = action.payload;
    },
    twoFactorAuthVerifyOpen: (state, user: PayloadAction<UserDetails>) => {
      state.isLoggingIn = false;
      state.is2FAVerifying = true;
      state.email = undefined;
    },
    authyTokenRequest: state => {
      state.tokenVerifying = true;
      state.authyTokenError = undefined;
      state.authySmsRequestSuccess = undefined;
      state.authySmsRequestWarning = undefined;
    },
    authyTokenSuccess: state => {
      state.tokenVerifying = false;
      state.isAuthenticated = true;
      state.isLoggingIn = false;
    },
    authyTokenFailure: (state, action: PayloadAction<string>) => {
      state.tokenVerifying = false;
      state.authyTokenError = action.payload;
    },
    otpAttemptRequest: state => {
      state.tokenVerifying = true;
      state.otpAttemptError = undefined;
    },
    otpAttemptSuccess: state => {
      state.tokenVerifying = false;
      state.isAuthenticated = true;
      state.isLoggingIn = false;
    },
    otpAttemptFailure: (state, action: PayloadAction<string>) => {
      state.tokenVerifying = false;
      state.otpAttemptError = action.payload;
    },
    authySmsRequest: state => {
      state.smsRequesting = true;
      state.authySmsRequestSuccess = undefined;
      state.authyTokenError = undefined;
      state.authySmsRequestWarning = undefined;
    },
    authySmsRequestMessage: (state, action: PayloadAction<string>) => {
      state.smsRequesting = false;
      state.authySmsRequestSuccess = action.payload;
    },
    authySmsRequestMessageWarning: (state, action: PayloadAction<string>) => {
      state.smsRequesting = false;
      state.authySmsRequestWarning = action.payload;
    },
  },
});

export const {
  fetchEmailRequest,
  fetchEmailSuccess,
  fetchEmailFailure,
  loginRequest,
  loginSuccess,
  loginFailure,
  registerRequest,
  registerSuccess,
  registerFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  sendPasswordResetRequest,
  sendPasswordResetSuccess,
  sendPasswordResetFailure,
  signUpRequest,
  signUpSuccess,
  signUpFailure,
  toggleForgottenPasswordFlow,
  confirmationSent,
  twoFactorAuthBypassOpen,
  twoFactorAuthVerifyOpen,
  authyTokenRequest,
  authyTokenFailure,
  authySmsRequest,
  authySmsRequestMessage,
  authySmsRequestMessageWarning,
  authyTokenSuccess,
  otpAttemptRequest,
  otpAttemptSuccess,
  otpAttemptFailure,
  clearSignUpError,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
