import { push } from 'connected-react-router';
import { api } from '../../../../src/api/api';
import { getApiErrorMessage } from '../../../../src/functions/getApiErrorMessage';
import { getMetaContent } from '../../../../src/functions/getMetaContent';
import { UNKNOWN_ERROR } from '../../../../src/types/constants';
import { VoidThunk } from '../../../../src/types/voidThunk';
import {
  authySmsRequest,
  authySmsRequestMessage,
  authySmsRequestMessageWarning,
  authyTokenFailure,
  authyTokenRequest,
  authyTokenSuccess,
  otpAttemptFailure,
  otpAttemptRequest,
  otpAttemptSuccess,
  confirmationSent,
  fetchEmailFailure,
  fetchEmailRequest,
  fetchEmailSuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
  registerFailure,
  registerRequest,
  registerSuccess,
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  sendPasswordResetFailure,
  sendPasswordResetRequest,
  sendPasswordResetSuccess,
  signUpFailure,
  signUpRequest,
  signUpSuccess,
  twoFactorAuthBypassOpen,
  twoFactorAuthVerifyOpen,
} from './authenticationSlice';
import { forward } from '../signup/signupSlice';
import { welcomeRoute } from '../../../../src/components/Routes/Routes';
import { isArray } from 'util';

export const fetchEmail = (): VoidThunk => async (dispatch, getState) => {
  try {
    const token = getState().router.location.query['invitation_token'];
    dispatch(fetchEmailRequest());
    const response = await api().usersInvitationsTokenGet({ token });
    dispatch(fetchEmailSuccess(response.email));
  } catch (err) {
    if (err instanceof Response) {
      console.log(
        'API error',
        `Status: ${err.status} Message: ${err.statusText}`
      );
      const apiErrorMessage = await getApiErrorMessage(err);
      if (apiErrorMessage) {
        dispatch(fetchEmailFailure(apiErrorMessage));
      } else {
        dispatch(
          fetchEmailFailure(
            'An error occurred fetching invitation details. Please try again or contact support.'
          )
        );
      }
    } else {
      dispatch(fetchEmailFailure(UNKNOWN_ERROR));
    }
    setTimeout(() => dispatch(push('/r/welcome')), 2000);
  }
};

export const login =
  (email: string, password: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(loginRequest());
      const payload = JSON.stringify({ user: { email, password } });
      const response = await fetch('/users/sign_in', {
        body: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaContent('csrf-token'),
        },
        method: 'POST',
      });

      if (response.status === 200) {
        const qrCode = (await response.json()).qr_code;
        dispatch(twoFactorAuthBypassOpen(qrCode));
      } else if (response.status === 201) {
        dispatch(loginSuccess());
      } else if (response.status === 204) {
        /** Redirect to 2FA step */
        dispatch(twoFactorAuthVerifyOpen(email));
      } else {
        const message = await getApiErrorMessage(response);

        dispatch(loginFailure(message));
      }
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(loginFailure(apiErrorMessage));
        } else {
          dispatch(
            loginFailure(
              'An error occurred logging in. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(loginFailure(UNKNOWN_ERROR));
      }
    }
  };

export const register =
  (password: string, token: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(registerRequest());
      const payload = JSON.stringify({
        user: {
          invitation_token: token,
          password,
          password_confirmation: password,
        },
      });
      const response = await fetch('/users/invitation', {
        body: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaContent('csrf-token'),
        },
        method: 'PUT',
      });
      if (response.status === 204) {
        dispatch(registerSuccess());
        const redirectLocation = response.headers.get('Location');
        window.location.href =
          // For "assessment response invite link" vendor is required
          redirectLocation && redirectLocation.includes('assessment')
            ? welcomeRoute
            : redirectLocation || process.env.REACT_APP_DEFAULT_ROUTE;
      } else {
        const message =
          response.status === 401
            ? await getApiErrorMessage(response)
            : 'We were unable to accept the invitation. Please try again or contact support.';

        console.log(response);
        dispatch(registerFailure(message));
      }
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(registerFailure(apiErrorMessage));
        } else {
          dispatch(
            registerFailure(
              'An error occurred accepting the invitation. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(registerFailure(UNKNOWN_ERROR));
      }
    }
  };

export const resetPassword =
  (password: string, token: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(resetPasswordRequest());
      const payload = JSON.stringify({
        user: {
          reset_password_token: token,
          password,
          password_confirmation: password,
        },
      });
      const response = await fetch('/users/password', {
        body: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaContent('csrf-token'),
        },
        method: 'PUT',
      });
      if (response.status === 204) {
        dispatch(resetPasswordSuccess());
        dispatch(push(process.env.REACT_APP_DEFAULT_ROUTE));
      } else {
        console.log(response);
        dispatch(
          resetPasswordFailure(
            'We were unable to reset the password. Please try again or contact support.'
          )
        );
      }
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(resetPasswordFailure(apiErrorMessage));
        } else {
          dispatch(
            resetPasswordFailure(
              'An error occurred resetting the password. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(resetPasswordFailure(UNKNOWN_ERROR));
      }
    }
  };

export const sendPasswordReset =
  (email: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(sendPasswordResetRequest());
      const payload = JSON.stringify({
        user: { email },
      });
      const response = await fetch('/users/password', {
        body: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaContent('csrf-token'),
        },
        method: 'POST',
      });
      console.log(response);
      if (response.statusText == 'Not Found' || response.status == 404) {
        dispatch(sendPasswordResetFailure('Email not found'));
      } else if (response.status === 401) {
        const message = await getApiErrorMessage(response);
        dispatch(sendPasswordResetFailure(message));
      } else {
        dispatch(sendPasswordResetSuccess());
      }
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(sendPasswordResetFailure(apiErrorMessage));
        } else {
          dispatch(
            sendPasswordResetFailure(
              'An error occurred sending the password reset instructions. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(sendPasswordResetFailure(UNKNOWN_ERROR));
      }
    }
  };

export const signUp =
  (
    email: string,
    password: string,
    passwordConfirmation: string,
    recaptcha: string
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(signUpRequest(email));
      const payload = JSON.stringify({
        'g-recaptcha-response': recaptcha,
        user: {
          email,
          password,
          password_confirmation: passwordConfirmation,
        },
      });
      const response = await fetch('/api/v1/users', {
        body: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaContent('csrf-token'),
        },
        method: 'POST',
      });

      if (response.status === 201) {
        dispatch(signUpSuccess());
        dispatch(forward());
      } else {
        const json = await response.json();
        if (json?.errors?.email?.[0] === 'has already been taken') {
          dispatch(
            signUpFailure(
              'This email address has already been taken. Please try again.'
            )
          );
        } else {
          dispatch(
            signUpFailure(
              'There was a problem registering your details. Please try again.'
            )
          );
        }
      }
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(signUpFailure(apiErrorMessage));
        } else {
          dispatch(
            signUpFailure(
              'An error occurred during sign up. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(signUpFailure(UNKNOWN_ERROR));
      }
    }
  };

export const sendConfirmationEmail =
  (email: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(registerRequest());
      const payload = JSON.stringify({
        email,
      });
      const response = await fetch('/users/confirmation', {
        body: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaContent('csrf-token'),
        },
        method: 'PUT',
      });
      if (response.status === 200) {
        dispatch(confirmationSent());
      } else {
        console.log(response);
        dispatch(
          registerFailure(
            'We were unable to accept the invitation. Please try again or contact support.'
          )
        );
      }
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(registerFailure(apiErrorMessage));
        } else {
          dispatch(
            registerFailure(
              'An error occurred accepting the invitation. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(registerFailure(UNKNOWN_ERROR));
      }
    }
  };

export const sendToken =
  (obj: any): VoidThunk =>
  async dispatch => {
    try {
      dispatch(authyTokenRequest());
      await api().usersVerifyTokenPost({ payload: { obj } });
      dispatch(authyTokenSuccess());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(authyTokenFailure(apiErrorMessage));
        } else {
          dispatch(
            authyTokenFailure(
              'An error sending the request. Please try again or contact support.'
            )
          );
        }
      } else {
        dispatch(authyTokenFailure(UNKNOWN_ERROR));
      }
    }
  };

export const sendOTP =
  (mfa: any): VoidThunk =>
  async dispatch => {
    try {
      dispatch(otpAttemptRequest());
      await api().usersVerifyOtpPost({ payload: { mfa } });
      dispatch(otpAttemptSuccess());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(otpAttemptFailure(apiErrorMessage));
        } else {
          dispatch(
            otpAttemptFailure(
              'An error sending the request. Please try again or contact support.'
            )
          );
        }
      } else {
        dispatch(authyTokenFailure(UNKNOWN_ERROR));
      }
    }
  };

export const sendSms =
  (email: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(authySmsRequest());
      await api().usersSmsRequestPost({ payload: { email } });
      dispatch(authySmsRequestMessage('SMS successfully sent'));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(authySmsRequestMessageWarning(apiErrorMessage));
        } else {
          dispatch(
            authySmsRequestMessageWarning(
              'An error sending the request. Please try again or contact support.'
            )
          );
        }
      } else {
        dispatch(authySmsRequestMessageWarning(UNKNOWN_ERROR));
      }
    }
  };
