/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TwoFactorVerifyOtpPayloadMfa
 */
export interface TwoFactorVerifyOtpPayloadMfa {
  /**
   *
   * @type {string}
   * @memberof TwoFactorVerifyOtpPayloadMfa
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof TwoFactorVerifyOtpPayloadMfa
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof TwoFactorVerifyOtpPayloadMfa
   */
  otpAttempt: string;
}

export function TwoFactorVerifyOtpPayloadMfaFromJSON(
  json: any
): TwoFactorVerifyOtpPayloadMfa {
  return TwoFactorVerifyOtpPayloadMfaFromJSONTyped(json, false);
}

export function TwoFactorVerifyOtpPayloadMfaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TwoFactorVerifyOtpPayloadMfa {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    password: json['password'],
    otpAttempt: json['otp_attempt'],
  };
}

export function TwoFactorVerifyOtpPayloadMfaToJSON(
  value?: TwoFactorVerifyOtpPayloadMfa | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    password: value.password,
    otp_attempt: value.otpAttempt,
  };
}
