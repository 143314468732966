import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentType } from '../../constants/DocumentType';
import {
  mapShareFeaturesToAccessLevels,
  useHasFeature,
} from '../../functions/access';
import { isVendorPolicyFilled } from '../../functions/isVendorPolicyFilled';
import { openPdfPreview } from '../../store/global/globalSlice';
import {
  showPolicyDrawer,
  updatePolicyToEdit,
} from '../../store/policies/policiesSlice';
import { completePolicy } from '../../store/policies/policiesThunks';
import { showShareModal } from '../../store/vendors/vendorsSlice';
import { ShareableType } from '../../swagger';
import { AccessObject } from '../../types/accessObject';
import { ApplicationState } from '../../types/applicationState';
import { PdfOpenMode } from '../../types/pdfOpenMode';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { GenericModal } from '../GenericModal/GenericModal';
import { MeatballMenu } from '../MeatballMenu/MeatballMenu';
import { Panel } from '../Panel/Panel';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import './PolicySummaryTile.scss';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { PolicyGetLinkInfo } from '../InProgressPoliciesGrid/InProgressPolicyRow';
import { draftPoliciesPageRoute } from '../Routes/Routes';

interface PolicySummaryTileProps {
  vendorPolicyId: string;
  progress: number;
}

export const PolicySummaryTile: React.FC<PolicySummaryTileProps> = props => {
  const vendorPolicy = useSelector((state: ApplicationState) =>
    state.policies.vendorPolicies.find(vp => vp.id === props.vendorPolicyId)
  );

  const isCompletingPolicy = useSelector(
    (state: ApplicationState) => state.policies.isCompletingPolicy
  );

  const isFilledIn = isVendorPolicyFilled(vendorPolicy);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const canShare = useHasFeature(AccessObject.share, vendorPolicy?.access);

  if (!vendorPolicy) {
    return null;
  }

  const handleComplete = () => {
    setShowModal(true);
  };

  const handlePreview = () =>
    dispatch(
      openPdfPreview({
        mode: PdfOpenMode.Preview,
        documentType: DocumentType.Policy,
        title: vendorPolicy.name,
        url: `/api/v1/vendor_policies/${vendorPolicy.id}/pdf`,
        objectId: vendorPolicy.id,
      })
    );

  const handleShare = () =>
    dispatch(
      showShareModal({
        shareableId: vendorPolicy.id,
        shareableType: ShareableType.VendorPolicy,
        accessLevels: mapShareFeaturesToAccessLevels(
          vendorPolicy.access.feature
        ),
        showOnlyGetLink: true,
        getLinkInfo: PolicyGetLinkInfo,
        relativePath: vendorPolicy.relativePath,
      })
    );

  const handleEditOwnerClick = () => openDrawer();
  const handleSetReviewDate = () => openDrawer();

  const openDrawer = () => {
    dispatch(showPolicyDrawer());
    dispatch(updatePolicyToEdit(vendorPolicy));
  };

  const handleBackClick = () => {
    dispatch(push(draftPoliciesPageRoute));
  };

  return (
    <>
      <Panel>
        <GenericModal
          closeButtonLabel="Confirm"
          onCancel={() => setShowModal(false)}
          onClose={() => {
            dispatch(completePolicy(props.vendorPolicyId));
            setShowModal(false);
          }}
          show={showModal}
          showCancelButton
          title="Progress to approval"
        >
          Click confirm to manage approvals and move this document from In
          Progress to Approvals.
        </GenericModal>
        <div className="policySummaryTile">
          <div className="policySummaryTile--progressBar">
            <ProgressBar progress={props.progress * 100} />
          </div>
          <div className="policySummaryTile--mainContent">
            <div className="policyEditor--back" onClick={handleBackClick}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </div>
            <div className="policySummaryTile--info">
              <div className="policySummaryTile--column">
                <div className="policySummaryTile--columnHeader">Policy</div>
                <div className="policySummaryTile--columnData">
                  {vendorPolicy.name}
                </div>
              </div>
              <div className="policySummaryTile--column">
                <div className="policySummaryTile--columnHeader">Progress</div>
                <div className="policySummaryTile--columnData">
                  {(props.progress * 100).toFixed(0)}%
                </div>
              </div>
              <div className="policySummaryTile--column">
                <div className="policySummaryTile--columnHeader">Version</div>
                <div className="policySummaryTile--columnData">
                  {vendorPolicy.version}
                </div>
              </div>
              <div className="policySummaryTile--column policySummaryTile--column-grow">
                <div className="policySummaryTile--column-verticallyCentered">
                  {isFilledIn && (
                    <>
                      <AdoptechButton onClick={handlePreview}>
                        <FontAwesomeIcon
                          className="policySummaryTile--previewIcon"
                          icon={faEye}
                        />
                        Preview
                      </AdoptechButton>
                      <AdoptechButton
                        busy={isCompletingPolicy}
                        onClick={handleComplete}
                        variant={AdoptechButtonVariant.SuccessTransparent}
                      >
                        <FontAwesomeIcon
                          className="policySummaryTile--completeIcon"
                          icon={faFileContract}
                        />
                        Complete
                      </AdoptechButton>
                    </>
                  )}
                </div>
              </div>
              <div className="policySummaryTile--column">
                <MeatballMenu>
                  <Dropdown.Item onClick={handleSetReviewDate}>
                    <FontAwesomeIcon icon={faCalendar} />
                    Set review date
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleEditOwnerClick}>
                    <FontAwesomeIcon icon={faFileContract} />
                    Edit owner
                  </Dropdown.Item>
                  <Dropdown.Item disabled={!canShare} onClick={handleShare}>
                    <FontAwesomeIcon icon={faLink} />
                    Get link
                  </Dropdown.Item>
                </MeatballMenu>
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};
