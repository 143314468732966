import { createSelector } from '@reduxjs/toolkit';
import { selectRouteName } from './selectRouteName';
import { mainMenuConfig } from '../components/MainMenuItems/mainMenuConfig';
import { flattenMainMenuConfig } from '../functions/flattenMainMenuConfig';
import { selectCustomPageLabel } from './selectCustomPageLabel';
import {
  complianceControlsRoute,
  complianceFrameworkTasksRoute,
  legalRegisterPageRoute,
  manageSubscriptionRoute,
  paymentLandingPageRoute,
  policyRoutes,
} from '../components/Routes/Routes';
import { selectActiveSubscription } from './selectActiveSubscription';

export const selectPageLabel = createSelector(
  selectRouteName,
  selectCustomPageLabel,
  selectActiveSubscription,
  (routeName, customLabel, subscription) => {
    const flattenedMainMenuConfigItems = flattenMainMenuConfig(
      mainMenuConfig(subscription)
    );
    let currentRouteName = routeName;
    if (currentRouteName?.startsWith('/r/calendar'))
      currentRouteName = '/r/calendar';

    const configItem = flattenedMainMenuConfigItems.find(
      item => item.route === currentRouteName
    );

    if (
      [
        complianceControlsRoute,
        complianceFrameworkTasksRoute,
        manageSubscriptionRoute,
        paymentLandingPageRoute,
        legalRegisterPageRoute,
        ...policyRoutes,
      ].includes(routeName) &&
      customLabel
    )
      return customLabel;

    return configItem ? configItem.label : undefined;
  }
);
