import classNames from 'classnames';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../src/components/AdoptechButton/AdoptechButton';
import { MessageToast } from '../../../../src/components/MessageToast/MessageToast';
import { toggleForgottenPasswordFlow } from '../../store/authentication/authenticationSlice';
import {
  login,
  sendConfirmationEmail,
  sendPasswordReset,
} from '../../store/authentication/authenticationThunks';
import { ApplicationState } from '../../types/applicationState';
import DividerWithText from '../DividerWithText/DividerWithText';
import { InputField } from '../InputField/InputField';
import { TwoFactorAuth } from '../TwoFactorAuth/TwoFactorAuth';
import { TwoFactorAuthOTP } from '../TwoFactorAuthOTP/TwoFactorAuthOTP';
import './Login.scss';
import { StartLayout } from '../../../../src/components/Start/StartLayout/StartLayout';
import GetStarted from 'images/get-started.svg';
import {
  externalProvidersRoutes,
  signupRoute,
} from '../../../../src/components/Routes/Routes';
import { formSubmit } from '../../../../src/functions/formSubmit';

const loginRedirectCallback = (path: string) => () => {
  formSubmit('post', path, {
    prompt: 'select_account',
  });
};

export const Login: React.FC = () => {
  const [showAlreadyConfirmed, setShowAlreadyConfirmed] = useState(false);

  const authenticationState = useSelector(
    (state: ApplicationState) => state.authentication
  );

  const location = useSelector(
    (state: ApplicationState) => state.router.location
  );

  useEffect(() => {
    if (location.query.already_confirmed) {
      setShowAlreadyConfirmed(true);
    }
  }, []);

  let prompt = authenticationState.forgottenPasswordFlow
    ? 'Enter your email below and we will send you password reset instructions'
    : 'Please log in below';

  if (authenticationState.confirmationSent) {
    prompt = 'Email successfully sent';
  }

  let confirmLink = false;
  if (authenticationState.loginError) {
    prompt = authenticationState.loginError;
    if (
      prompt === 'You have to confirm your email address before continuing.'
    ) {
      confirmLink = true;
    }
  }

  const emailFieldClasses = classNames({
    'login--emailField': true,
    'login--emailField-red': authenticationState.loginError !== undefined,
    'login--emailField-flex': authenticationState.forgottenPasswordFlow,
  });

  const passwordFieldClasses = classNames({
    'login--passwordField': true,
    'login--passwordField-hidden': authenticationState.forgottenPasswordFlow,
    'login--passwordField-red': authenticationState.loginError !== undefined,
  });

  const resetPasswordClasses = classNames({
    'login--resetPasswordMessage': true,
    'login--resetPasswordMessage-hidden':
      !authenticationState.hasSentPasswordReset,
  });

  const subheaderClasses = classNames({
    'login--subheader': true,
    'login--subheader-red': authenticationState.loginError !== undefined,
  });

  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.authentication.isAuthenticated
  );

  const is2FAVerifying = useSelector(
    (state: ApplicationState) => state.authentication.is2FAVerifying
  );

  const is2FAVerifyingOTP = useSelector(
    (state: ApplicationState) => state.authentication.is2FAVerifyingOTP
  );

  const qrCode = useSelector(
    (state: ApplicationState) => state.authentication.qrCode
  );

  if (isAuthenticated) {
    window.location.href = location.pathname + location.search;
  }

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = (e: any) => {
    if (e !== null && e.target.id === 'forgottenPassword') {
      dispatch(toggleForgottenPasswordFlow());
      return;
    }

    if (e !== null && e.target.id === 'createAccount') {
      dispatch(push(signupRoute));
      return;
    }

    if (authenticationState.forgottenPasswordFlow) {
      dispatch(sendPasswordReset(email));
    } else {
      dispatch(login(email, password));
    }
  };

  if (is2FAVerifying) {
    return <TwoFactorAuth email={email} />;
  }

  if (is2FAVerifyingOTP) {
    return (
      <TwoFactorAuthOTP email={email} password={password} qrCode={qrCode} />
    );
  }

  return (
    <StartLayout
      rightHandPanelImage={GetStarted}
      showLogin={false}
      showProgress={false}
    >
      <MessageToast
        onClose={() => setShowAlreadyConfirmed(false)}
        show={showAlreadyConfirmed}
      >
        Your account email has already been confirmed. Please login after
        closing this message.
      </MessageToast>
      {!showAlreadyConfirmed && (
        <div
          className="login"
          onKeyPress={e => {
            if (e.charCode === 13) {
              handleSubmit(e);
            }
          }}
        >
          <div className="login--panel">
            <div className="login--content">
              <div className="login--header">
                {authenticationState.forgottenPasswordFlow
                  ? 'Forgot your password?'
                  : 'Login'}
              </div>
              {!authenticationState.forgottenPasswordFlow && (
                <>
                  <div className="login--signUpRow">
                    <div>Don't have an account?</div>
                    <div
                      className="login--signUp"
                      id="signUp"
                      onClick={() => dispatch(push(signupRoute))}
                      tabIndex={0}
                    >
                      Get started
                    </div>
                  </div>

                  <div className="login--fullSizeButton">
                    <AdoptechButton
                      busy={false}
                      variant={AdoptechButtonVariant.Google}
                      onClick={loginRedirectCallback(
                        externalProvidersRoutes.google
                      )}
                    >
                      Continue with Google
                    </AdoptechButton>
                  </div>
                  <div className="login--fullSizeButton">
                    <AdoptechButton
                      busy={false}
                      variant={AdoptechButtonVariant.Microsoft}
                      onClick={loginRedirectCallback(
                        externalProvidersRoutes.microsoft
                      )}
                    >
                      Continue with Microsoft
                    </AdoptechButton>
                  </div>
                  <DividerWithText text="or" />
                </>
              )}
              {(authenticationState.forgottenPasswordFlow ||
                authenticationState.loginError) && (
                <div className={subheaderClasses}>
                  {prompt}
                  {confirmLink && (
                    <div>
                      {" Didn't get the email? Click "}

                      <div
                        className="login--resendPassword d-inline"
                        id="sendConfirmation"
                        onClick={() => {
                          dispatch(sendConfirmationEmail(email));
                        }}
                        tabIndex={0}
                      >
                        here
                      </div>
                      {' to resend it'}
                    </div>
                  )}
                </div>
              )}
              <div className={emailFieldClasses}>
                <InputField
                  autoFocus
                  disabled={authenticationState.hasSentPasswordReset}
                  onChange={value => setEmail(value)}
                  placeholder="Enter your email address"
                  type="email"
                />
              </div>
              <div className={passwordFieldClasses}>
                <InputField
                  isPassword
                  onChange={value => setPassword(value)}
                  placeholder="Enter your password"
                  type="password"
                />
              </div>
              <div className={resetPasswordClasses}>
                We have sent you an email with instructions on how to reset your
                password. Please follow the instructions there to continue.
              </div>
              {!authenticationState.hasSentPasswordReset && (
                <>
                  <div className="login--fullSizeButton login">
                    <AdoptechButton
                      onClick={() => {
                        handleSubmit(null);
                      }}
                      busy={authenticationState.isLoggingIn}
                      variant={AdoptechButtonVariant.Primary}
                      extraClass="login--button"
                    >
                      <span>
                        {authenticationState.forgottenPasswordFlow
                          ? 'SEND'
                          : 'LOGIN'}
                      </span>
                    </AdoptechButton>
                  </div>
                  <div
                    className="login--forgottenPassword"
                    id="forgottenPassword"
                    onClick={() => {
                      dispatch(toggleForgottenPasswordFlow());
                    }}
                    tabIndex={0}
                  >
                    {authenticationState.forgottenPasswordFlow
                      ? 'Return to Login'
                      : 'Forgotten your password?'}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </StartLayout>
  );
};
