import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { dateSort } from './dateSort';
import { sort } from './sort';
import { VendorDocumentWithCategoryName } from '../selectors/selectLivePolicies';
import { sortByOwner } from './sortByOwner';

export const vendorDocumentWithCategoryNameSort = (
  a: VendorDocumentWithCategoryName,
  b: VendorDocumentWithCategoryName,
  columnSort: ColumnSort
): number => {
  let direction: SortDirection = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof VendorDocumentWithCategoryName =
    columnSort.name as keyof VendorDocumentWithCategoryName;

  switch (typedColumn) {
    case 'attestationDueAt':
      return dateSort(a.attestationDueAt, b.attestationDueAt, direction);
    case 'nextReviewDate':
      return dateSort(a.nextReviewDate, b.nextReviewDate, direction);
    case 'name':
      return sort(a.name.toLowerCase(), b.name.toLowerCase(), direction);
    case 'version':
      return sort(a.version, b.version, direction);
    case 'categoryName':
      return sort(a.categoryName, b.categoryName, direction);
    case 'owner':
      return sortByOwner(a.owner, b.owner, direction);
  }
};
