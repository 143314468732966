import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../src/components/AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../../../../src/components/LoadingSpinner/LoadingSpinner';
import { Panel } from '../../../../src/components/Panel/Panel';
import { sendOTP } from '../../store/authentication/authenticationThunks';
import { ApplicationState } from '../../types/applicationState';
import { InputField } from '../InputField/InputField';
import './TwoFactorAuthOTP.scss';

interface TwoFactorAuthOTPProps {
  email: string;
  password: string;
  qrCode: string;
}

export const TwoFactorAuthOTP: React.FC<TwoFactorAuthOTPProps> = props => {
  const [otpAttempt, setOtpAttempt] = useState('');

  const dispatch = useDispatch();

  const authenticationState = useSelector(
    (state: ApplicationState) => state.authentication
  );

  const {
    tokenVerifying,
    isAuthenticated,
    otpAttemptError: errorMessage,
  } = authenticationState;

  const baseCss = 'twoFactorAuthOTP';

  const errorMessageClasses = classNames({
    [`${baseCss}--infoMessage`]: true,
    [`${baseCss}--infoMessage--error`]: !!errorMessage,
    [`${baseCss}--infoMessage--hidden`]: !errorMessage,
  });

  if (isAuthenticated) return <LoadingSpinner />; // If all form requests finished, and redirecting to dashboard
  return (
    <div className={baseCss}>
      <div className={baseCss + '--panel'}>
        <Panel placedOnDarkBackground>
          <div className={baseCss + '--content'}>
            <div className={baseCss + '--header'}>
              Two Factor Authentication
            </div>

            {props.qrCode ? (
              <>
                <div className={baseCss + '--subheader'}>
                  Scan the QR code below using any authenticator application,
                  such as Google or Microsoft Authenticator.
                </div>

                <div className="totp-qr-code" aria-label="TOTP QR Code">
                  <img
                    src={props.qrCode}
                    alt="TOTP QR Code for Two-Factor Authentication"
                  />
                </div>

                <div className={baseCss + '--subheader'}>
                  After scanning, enter the 6-digit code generated by your
                  authenticator app to complete the authentication process.
                </div>
              </>
            ) : (
              <div className={baseCss + '--subheader'}>
                Enter the 6-digit code from your authenticator app to complete
                the authentication process.
              </div>
            )}

            <div className={baseCss + '--tokenLabel'}>One-Time Code</div>

            <div className={baseCss + '--tokenField'}>
              <InputField
                autoFocus
                disableAutoComplete
                onChange={value => setOtpAttempt(value)}
                placeholder="Enter one-time code"
                type="text"
              />
            </div>
            <div className={errorMessageClasses}>{errorMessage}</div>
            <div className={baseCss + '--actions'}>
              <AdoptechButton
                disabled={!otpAttempt || otpAttempt.trim().length !== 6}
                onClick={() => {
                  const credentials = {
                    email: props.email,
                    password: props.password,
                    otpAttempt,
                  };
                  dispatch(sendOTP(credentials));
                }}
                busy={tokenVerifying}
                variant={AdoptechButtonVariant.Primary}
              >
                Verify
              </AdoptechButton>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};
